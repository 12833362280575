import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './Contact.css';
import { useTheme } from '../ThemeContext/ThemeContext';

const productOptions = [
    { value: "1", label: "توتی فروتی" },
    { value: "2", label: "آدامس دارچین" },
    { value: "3", label: "شیرموز" },
    { value: "4", label: "شیر کره" },
    { value: "5", label: "نارنج" },
    { value: "6", label: "تمشک" },
    { value: "7", label: "تروپیکال (استوایی)" },
    { value: "8", label: "شیرکارامل" },
    { value: "9", label: "وانیل" },
    { value: "10", label: "توت فرنگی" },
    { value: "11", label: "پرتقال" },
    { value: "12", label: "هندوانه" },
    { value: "13", label: "آلبالو" },
    { value: "14", label: "طالبی" },
    { value: "15", label: "آناناس" },
    { value: "16", label: "خیار" },
    { value: "17", label: "لیمو ترش" },
    { value: "18", label: "نارگیل" },
    { value: "19", label: "کاکائو" },
    { value: "20", label: "کارامل" },
    { value: "21", label: "زنجبیل" },
    { value: "22", label: "قهوه" },
    { value: "23", label: "شکلات تلخ" },
    { value: "24", label: "فندق" },
    { value: "25", label: "پسته" },
    { value: "26", label: "نعناع" },
    { value: "27", label: "دارچین" },
    { value: "28", label: "سیب سبز" },
    { value: "29", label: "انگور" },
    { value: "30", label: "زیتون" },
    { value: "31", label: "ریحان" },
    { value: "32", label: "توت سیاه" },
    { value: "33", label: "سیب" },
    { value: "34", label: "هلو" },
    { value: "35", label: "آلوئه ورا" },
    { value: "36", label: "گل محمدی" },
    { value: "37", label: "میخک" },
    { value: "38", label: "زردآلو" },
    { value: "39", label: "آویشن" },
    { value: "40", label: "پونه" },
    { value: "41", label: "خرما" },
    { value: "42", label: "شاه توت" },
    { value: "43", label: "شکوفه پرتقال" },
    { value: "44", label: "بادام" },
    { value: "45", label: "آناناس نارگیل" },
    { value: "46", label: "زعفران" },
    { value: "47", label: "توت سفید" },
    { value: "48", label: "گلابی" },
    { value: "49", label: "بیدمشک" },
    { value: "50", label: "چای سبز" }
];

 export function LuxuryForm  ()  {
   
  const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
  }} ,[isDarkMode]);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        city: '',
        factoryName: '',
        phoneNumber: '',
        acquaintance: '',
        request: '',
        isFirstTime: false,
        selectedProducts: []
    });
    const [result, setResult] = useState('');
    const [darkMode, setDarkMode] = useState(false);  // وضعیت دارک مود

    // استفاده از useEffect برای تشخیص وضعیت دارک مود سیستم
    useEffect(() => {
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setDarkMode(prefersDarkMode);
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            isFirstTime: e.target.checked,
        });
    };

    const handleProductChange = (selectedOptions) => {
        setFormData(prevState => ({
            ...prevState,
            selectedProducts: selectedOptions ? selectedOptions.map(option => option.value) : []
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResult('در حال ارسال...');

        const formDataToSend = new FormData();
        formDataToSend.append('access_key', '336f06bc-5f57-4332-bddd-1cb824b5199d');
        formDataToSend.append('firstName', formData.firstName);
        formDataToSend.append('lastName', formData.lastName);
        formDataToSend.append('companyName', formData.companyName);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('factoryName', formData.factoryName);
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('request', formData.request);
        formDataToSend.append('isFirstTime', formData.isFirstTime ? 'بله' : 'خیر');
        formDataToSend.append('selectedProducts', formData.selectedProducts.join(', '));

        const response = await fetch('https://api.web3forms.com/submit', {
            method: 'POST',
            body: formDataToSend,
        });

        const data = await response.json();

        if (data.success) {
            setResult(' منتظر تماس همکاران ما باشید فرم با موفقیت ارسال شد!');
            setFormData({
                firstName: '',
                lastName: '',
                companyName: '',
                city: '',
                factoryName: '',
                phoneNumber: '',
                request: '',
                isFirstTime: false,
                selectedProducts: []
            });
        } else {
            setResult('خطایی رخ داده است: ' + data.message);
        }
    };

    return (
        <div className={`container1 ${darkMode ? 'dark' : ''}`}>
            <div className="map-and-form">
                <div className="google-map">
                    <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d404.8086583032554!2d51.433193745276824!3d35.73926909057423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e03fe62c4bcf9%3A0xac14a3c6d276c235!2sAryan%20essance%20office!5e0!3m2!1sen!2s!4v1712057808219!5m2!1sen!2s"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen="true"
                        loading="lazy"
                    ></iframe>
                </div>

                <div className="form-section">
                    <h2>فرم درخواست نمونه</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstName">نام</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastName">نام خانوادگی</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                      
                        <div className="form-group">
                            <label htmlFor="companyName">نام شرکت</label>
                            <input
                                type="text"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                            />
                        </div>
  
                        <div className="form-group">
                            <label htmlFor="city">شهر</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="factoryName">اسم کارخانه</label>
                            <input
                                type="text"
                                id="factoryName"
                                name="factoryName"
                                value={formData.factoryName}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phoneNumber">شماره تماس</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                             <div className="form-group">
                            <label htmlFor="products">محصولات(برای ارسال نمونه)</label>
                            <Select
                                isMulti
                                options={productOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleProductChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="acquaintance">نحوه آشنایی</label>
                            <textarea
                                id="acquaintance"
                                name="acquaintance"
                                value={formData.acquaintance}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="request">درخواست</label>
                            <textarea
                                id="request"
                                name="request"
                                value={formData.request}
                                onChange={handleChange}
                                required
                            />
                        </div>

                       <div className="form-group">
                            <label htmlFor="isFirstTime">آیا اولین بار است که تماس می‌گیرید؟</label>
                            <input
                                type="checkbox"
                                id="isFirstTime"
                                name="isFirstTime"
                                checked={formData.isFirstTime}
                                onChange={handleCheckboxChange}
                            />
                        </div>


                        <button className="submit-btn" type="submit">ارسال</button>
                    </form>
                    {result && <p>{result}</p>}
                </div>
            </div>
        </div>
    );
};

export default LuxuryForm;
