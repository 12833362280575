import React ,{useEffect,useState}from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Snowfall from '../Snow/FakeSnow';
import p1 from "../../assets/porteghal.jpg";
import p2 from "../../assets/hendune.png";
import p3 from "../../assets/albaloo.png";
import p4 from "../../assets/talebi.png";
import p5 from "../../assets/ananas.png";
import p6 from "../../assets/but.png";
import p7 from "../../assets/shir3.png";

import p8 from "../../assets/cofee.png";
import p9 from "../../assets/choco.png";
import p10 from "../../assets/vanil.png";
import p11 from "../../assets/coco.png";
import p12 from "../../assets/stra.png";
import p13 from "../../assets/shah.png";
import p14 from "../../assets/but.png";
import p15 from "../../assets/mango.png";
import p16 from "../../assets/fan.png";
import p17 from "../../assets/shir3.png";
import p18 from "../../assets/greentea.png";
import p19 from "../../assets/alovera.png";
import p20 from "../../assets/olive.png";
import p21 from "../../assets/benanamilk.png";
import p22 from "../../assets/lemon.png";
import p23 from "../../assets/zardalu.jpg";
import p24 from "../../assets/khorma.png";
import p25 from "../../assets/reyhan.png";
import p27 from "../../assets/naena.png";
import p28 from "../../assets/peste.png";
import p29 from "../../assets/milkcar.png";
import p30 from "../../assets/tropical.png";
import p31 from "../../assets/narenj.png";
import p32 from "../../assets/zanjebil.png";
import p33 from "../../assets/rose.png";
import p34 from "../../assets/avish.png";
import p36 from "../../assets/badam.png";
import p35 from "../../assets/mikhak.png";
import p37 from "../../assets/tut.png";
import p38 from "../../assets/zaferan.png";
import p39 from "../../assets/holo.png";
import p40 from "../../assets/greenapple.png";
import p41 from "../../assets/golabi.png";
import p42 from "../../assets/drachin.png";
import p43 from "../../assets/gilas.png";
import p44 from "../../assets/shokolat.png";
import p45 from "../../assets/veg.png";
import p46 from "../../assets/zer.png";

import { useTheme } from '../ThemeContext/ThemeContext';
import './Mahulat.css';

const products = [
  
    { id: 1, img: p1, title: 'اسانس پرتقال', description: 'اسانس پرتقال از جمله اسانس های با پایه طبیعی محسوب می شود. این اسانس ها معمولا از روغن های طبیعی تشکیل شده و علاوهبر عطر و رایحه خواص طبیعی را نیز انتقال می دهند.',metaDescription:"اسانس پرتقال دارای کاربرد های مختلف است. این اسانس در انواع کیک و کلوچه، ویفر و بیسکوییت، تافی و آبنبات و انواعنوشیدنی کاربرد دارد. البته از دیگر استفاده های آن می توان به استفاده در عنوان تنباکو نیز اشاره کرد." ,
        body:(
            <div class="description-box">
                <h3>معرفی</h3>
                <p>اسانس پرتقال از جمله اسانس های با پایه طبیعی محسوب می شود. این اسانس ها معمولا از روغن های طبیعی تشکیل شده و علاوه
بر عطر و رایحه خواص طبیعی را نیز انتقال می دهند.
اسانس پرتقال بر اساس نوع روغن استفاده شده دارای طعم های و پروفایل های مختلفی است. این پروفایل ها شامل اسانس پرتقال
والنسیا، پرتقال تلخ و پوستی، پرتقال گوشتی و پرتقال شیرین می باشد.
اسانس پرتقال دارای کاربرد های مختلف است. این اسانس در انواع کیک و کلوچه، ویفر و بیسکوییت، تافی و آبنبات و انواع
نوشیدنی کاربرد دارد. البته از دیگر استفاده های آن می توان به استفاده در عنوان تنباکو نیز اشاره کرد.</p>
                <h3>محصولات شیرینی و آردی</h3>
                <p>اسانس استفاده شده در این محصولات معمولا دارای مقاومت بالایی دارد. از نظر پروفایلی نیز پروفایل والنسیا و شیرین برای این
محصول مناسب است. اما هر چه محصول نهایی دارای آب کمتری باشد مقاومت اسانس به کار رفته باید بیشتر باشد. بطور مثال
اسانس پرتقال در بیکوییت به مراتب مقاومت بیشتری نسبت به اسانس استفاده شده در کیک و کلوچه دارد. البته از نظر فنی مانعی
برای استفاده مشترک در همه محصولات وجود ندارد. اما توجه به چنین نکاتی قیمت تمام شده را کاهش می دهد.</p>
                <h3>محصولات تافی و آبنبات</h3>
                <p>با توجه به نوع محصولات استفاده از نوع اسانس پرتقال متفاوت است. برای محصولاتی با پایه روغنی استفاده از اسانس روغنه
پیشنهاد می شود. اما برای اسانس هایی با پایه آب همیشه نیاز به اسانس هایی با حلالیت مناسب در آب نیاز دارید.</p>
            </div>
        )},
    { id: 2, img: p2, title: 'اسانس هندوانه', description: "اسانس هندوانه خوراکی یکی از محصولات محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم دلپذیرش، مورد توجه زیادی نیز داردر" , metaDescription:"اسانس هندوانه از میوه‌ی خوشمزه و آبدار هندوانه استخراج می‌شود و دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این اسانس",
        body:(
            <div class="description-box">
                <h2>اسانس هندوانه خوراکی: طعم و عطر تابستان در هر قطره</h2>
                <h3>مقدمه</h3>
                <p>اسانس هندوانه خوراکی یکی از محصولات محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم دلپذیرش، مورد توجه
قرار گرفته است. این اسانس نه تنها طعمی شگفت‌انگیز به غذاها و نوشیدنی‌ها می‌بخشد، بلکه خواص سلامتی زیادی نیز دارد. در
این مقاله، به بررسی خواص، کاربردها و مزایای اسانس هندوانه می‌پردازیم.</p>
                <h3>خواص اسانس هندوانه</h3>
                <p>اسانس هندوانه از میوه‌ی خوشمزه و آبدار هندوانه استخراج می‌شود و دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این اسانس
غنی از ویتامین C و ویتامین A بوده و می‌تواند به تقویت سیستم ایمنی بدن کمک کند. همچنین، هندوانه حاوی مقدار زیادی آب است
که به هیدراته نگه داشتن بدن کمک می‌کند.</p>
<h3>کاربردهای اسانس هندوانه</h3>
<p>اسانس هندوانه در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها کاربرد دارد. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیه
می‌شوند، می‌توان به اسموتی‌ها، آبمیوه‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس به
عنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها استفاده می‌شود.</p>
<h3>مزایای اسانس هندوانه</h3>
<p>
1. طعم و عطر مطبوع: اسانس هندوانه عطر و طعمی شبیه به میوه‌ی تازه آن دارد و می‌تواند حس و حال تابستان را به هر
غذایی اضافه کند.
2. خواص سلامتی: مصرف اسانس هندوانه می‌تواند به بهبود هیدراتاسیون و سلامت پوست کمک کند. همچنین،
آنتی‌اکسیدان‌های موجود در آن به مبارزه با رادیکال‌های آزاد کمک می‌کنند.
3. تنوع در استفاده: این اسانس به راحتی می‌تواند در انواع غذاها و نوشیدنی‌ها گنجانده شود، از سالادها گرفته تا دسرها و
اسموتی‌ها.</p>
<h3>نتیجه‌گیری</h3>
<p>اسانس هندوانه خوراکی یک گزینه‌ی عالی برای کسانی است که به دنبال افزودن طعمی تازه و خوشمزه به غذاها و نوشیدنی‌های
خود هستند. با خواص متعدد سلامتی و عطر و طعم دلپذیر، این اسانس می‌تواند به یک انتخاب محبوب در آشپزخانه تبدیل شود. اگر
به دنبال یک تجربه‌ی جدید در طعم‌دهی به غذاهای خود هستید، اسانس هندوانه</p>
            </div>
        )
    },
    { id: 3, img: p3, title: 'اسانس آلبالو', description: "آلبالو خوراکی یکی از طعم‌دهنده‌های محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم خاص خود،خریداسانس البالو  " , metaDescription:"اسانس آلبالو در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها استفاده می‌شود. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیهمی‌شوند، می‌توان به آبمیوه‌ها، اسموتی‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس بهعنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها مورد استفاده قرار می‌گیرد.",
        body:(
            <div class="description-box">

                <h2>اسانس آلبالو خوراکی: طعمی دلپذیر و خواص بی‌نظیر</h2>
                <h3>مقدمه</h3>
                <p>اسانس آلبالو خوراکی یکی از طعم‌دهنده‌های محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم خاص خود، مورد
توجه بسیاری قرار گرفته است. این اسانس نه تنها به غذاها و دسرها طعمی شگفت‌انگیز می‌بخشد، بلکه خواص سلامتی بسیاری نیز
دارد. در این مقاله، به بررسی ویژگی‌ها، کاربردها و مزایای اسانس آلبالو می‌پردازیم.</p>
<h3>خواص اسانس آلبالو</h3>
<p>اسانس آلبالو از میوه‌ی خوشمزه و آبدار آلبالو استخراج می‌شود و سرشار از ویتامین‌ها و آنتی‌اکسیدان‌هاست. این اسانس حاوی
ویتامین C و ترکیبات ضد التهابی است که می‌تواند به تقویت سیستم ایمنی بدن و کاهش التهابات کمک کند. همچنین، آلبالو به عنوان
یک منبع خوب فیبر شناخته می‌شود که می‌تواند به بهبود عملکرد گوارش کمک کند.</p>
<h2>کاربردهای اسانس آلبالو</h2>
<p>اسانس آلبالو در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها استفاده می‌شود. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیه
می‌شوند، می‌توان به آبمیوه‌ها، اسموتی‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس به
عنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها مورد استفاده قرار می‌گیرد.</p>
<h2>مزایای اسانس آلبالو</h2>
<p>1. عطر و طعم منحصر به فرد: اسانس آلبالو عطر و طعمی مشابه میوه‌ی تازه آلبالو دارد و می‌تواند به راحتی حس و حال
تازگی را به هر غذایی اضافه کند.
2. خواص سلامتی: مصرف اسانس آلبالو می‌تواند به بهبود خواب و کاهش استرس کمک کند. این اسانس به دلیل دارا بودن
ملاتونین، می‌تواند به خواب بهتر و بهبود کیفیت خواب کمک کند.
3. تنوع در استفاده: اسانس آلبالو به راحتی در انواع غذاها و نوشیدنی‌ها گنجانده می‌شود. می‌توانید آن را به سالادها،دسرها و حتی سس‌ها اضافه کنید.</p>
         <h2>نکات مهم در خرید اسانس آلبالو</h2>
         <p>هنگام خرید اسانس آلبالو، به کیفیت محصول توجه کنید. بهتر است از برندهای معتبر و شناخته‌شده خرید کنید تا از خلوص و کیفیت
اسانس اطمینان حاصل کنید. همچنین، حتماً به برچسب محصول توجه کنید و از محتوای شیمیایی آن مطلع شوید.</p>
<h2>نتیجه‌گیری</h2>
<p>اسانس آلبالو خوراکی یک انتخاب عالی برای افزودن طعمی تازه و لذت‌بخش به غذاها و نوشیدنی‌های شماست. با خواص متعددسلامتی و عطر و طعم دلپذیر، این اسانس می‌تواند به یک گزینه محبوب در آشپزخانه تبدیل شود. اگر به دنبال تجربه‌ای جدید در
طعم‌دهی به غذاهای خود هستید، اسانس آلبالو را امتحان کنید و از لذت آن بهره‌مند شوید!</p>
            </div>
        )},
   {
  id: 4,
  img: p4, // مسیر تصویر محصول، مثلا '/images/p6.jpg'
  title: 'اسانس طالبی',
  description: 'توضیحات کامل محصول طالبی',
  metaDescription: "اسانس طالبی با عطر و طعم طبیعی مناسب برای صنایع غذایی و محصولات آرایشی، افزاینده جذابیت محصولات با رایحه گرمسیری.",
  body: (
    <div class="description-box">
      <h3>معرفی محصول اسانس طالبی</h3>
      <p>اسانس «طالبی» با عطر و طعم طبیعی و خاص خود، گزینه‌ای عالی برای استفاده در صنایع غذایی و بهداشتی است. این اسانس در تولید محصولات متنوعی مانند نوشیدنی‌ها، دسرها، شیرینی‌جات و محصولات آرایشی و بهداشتی کاربرد دارد و رایحه‌ای طبیعی و گرمسیری به این محصولات اضافه می‌کند.</p>

      <h3>ویژگی‌های اسانس طالبی</h3>
      <p><strong>پروفایل طعم و عطر متمایز:</strong> اسانس طالبی با طعم شیرین و خاص خود، حس طراوت و تازگی را به محصولات اضافه کرده و تجربه‌ای خوشایند برای مصرف‌کننده فراهم می‌آورد.</p>
      <p><strong>پایداری حرارتی:</strong> این اسانس در برابر دما مقاوم بوده و در فرآیندهای حرارتی مانند پخت و پز یا تهیه نوشیدنی‌های گرم، کیفیت و رایحه‌ی خود را حفظ می‌کند.</p>
      <p><strong>کاربردهای گسترده:</strong> اسانس طالبی در محصولات غذایی مانند نوشیدنی‌ها، دسرها، و شیرینی‌جات و همچنین در محصولات مراقبتی و آرایشی مورد استفاده قرار می‌گیرد که به آن‌ها عطر و طعم گرمسیری و دلپذیر می‌بخشد.</p>

      <h3>مزایای استفاده از اسانس طالبی</h3>
      <p><strong>تقویت عطر و طعم طبیعی:</strong> این اسانس به‌طور طبیعی عطر و طعم طالبی را به محصولات افزوده و جذابیت آن‌ها را برای مصرف‌کنندگان بیشتر می‌کند.</p>
      <p><strong>سازگاری با محیط زیست:</strong> اسانس طالبی با رعایت استانداردهای طبیعی و ارگانیک تولید می‌شود و بدون مواد شیمیایی مضر است.</p>
      <p><strong>جذابیت برای مصرف‌کنندگان:</strong> اسانس طالبی به دلیل طعم و رایحه خوش‌بو و محبوب خود، انتخابی عالی برای تولیدکنندگانی است که به دنبال ارائه محصولات جذاب و خاص به مصرف‌کنندگان هستند.</p>

      <h3>نحوه خرید و استفاده از اسانس طالبی</h3>
      <p>برای خرید اسانس طالبی با کیفیت عالی و کسب اطلاعات بیشتر در مورد کاربردهای آن، می‌توانید با فروشگاه ما تماس بگیرید و از مشاوره کارشناسان ما بهره‌مند شوید. برای دریافت اطلاعات بیشتر، با شماره تماس ما در ارتباط باشید تا راهنمایی‌های لازم را دریافت کنید.</p>

      <p><strong>برچسب‌های مرتبط:</strong> اسانس طالبی، اسانس میوه‌ای، اسانس طبیعی، اسانس صنایع غذایی، اسانس دسر، اسانس آرایشی</p>
    </div>
  )
}
,
    {
  id: 5,
  img: p5, // مسیر تصویر محصول، مثلا '/images/p5.jpg'
  title: 'اسانس اناناس',
  description: 'توضیحات کامل محصول اناناس',
  metaDescription: "اسانس اناناس با طعم و رایحه گرمسیری، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها، و محصولات آرایشی بهداشتی.",
  body: (
    <div class="description-box">
      <h3>معرفی محصول اسانس اناناس</h3>
      <p>محصول «اناناس» یکی از انواع اسانس‌های میوه‌ای است که به دلیل رایحه و طعم خاص، کاربردهای گسترده‌ای در صنایع غذایی و بهداشتی دارد. اسانس اناناس برای تولید محصولات متنوعی مانند نوشیدنی‌ها، شیرینی‌جات، دسرها، و محصولات آرایشی بهداشتی مورد استفاده قرار می‌گیرد و به این محصولات، رایحه‌ای گرمسیری و طبیعی می‌بخشد.</p>

      <h3>ویژگی‌های اسانس اناناس</h3><strong>پروفایل طعم و عطر متمایز:</strong> اسانس اناناس با طعم شیرین و دلپذیر خود، حس تازگی و شادابی را به محصولات اضافه می‌کند و تجربه لذت‌بخشی برای مصرف‌کننده به ارمغان می‌آورد.<strong>پایداری حرارتی:</strong> این اسانس مقاوم در برابر دما است و در فرآیندهای حرارتی مانند پخت و پز و تهیه نوشیدنی‌های گرم، کیفیت خود را حفظ می‌کند.<strong>کاربرد گسترده:</strong> از اسانس اناناس در انواع محصولات غذایی، نوشیدنی‌ها، شیرینی‌ها، دسرها، و همچنین محصولات مراقبتی و آرایشی استفاده می‌شود که به آن‌ها عطر و طعم گرمسیری و طبیعی می‌دهد.

      <h3>مزایای استفاده از اسانس اناناس</h3>
      
        <strong>تقویت عطر و طعم طبیعی:</strong> این اسانس به طور طبیعی، رایحه‌ی اناناس را در محصولاتی که ممکن است عطر و طعم کمتری داشته باشند، تقویت می‌کند.
        <strong>سازگاری با محیط زیست:</strong> اسانس اناناس با توجه به استانداردهای طبیعی و ارگانیک تولید می‌شود و بدون مواد شیمیایی مضر است.
        <strong>جذابیت برای مصرف‌کنندگان:</strong> اسانس اناناس به دلیل عطر و طعم محبوبش در بین مصرف‌کنندگان بسیار مورد استقبال قرار می‌گیرد و به تولیدکنندگان کمک می‌کند تا محصولات جذاب‌تری ارائه دهند.
      

      <h3>نحوه خرید و استفاده از اسانس اناناس</h3>
      <p>برای خرید اسانس اناناس باکیفیت و دریافت مشاوره درباره کاربردهای آن، می‌توانید به فروشگاه ما مراجعه کرده و با کارشناسان ما در ارتباط باشید. همچنین، در صورت نیاز به اطلاعات بیشتر، لطفاً با شماره تماس ما تماس حاصل کنید تا راهنمایی‌های لازم را دریافت نمایید.</p>

      <p><strong>برچسب‌های مرتبط:</strong> اسانس اناناس، اسانس میوه‌ای، اسانس طبیعی، اسانس صنایع غذایی، اسانس شیرینی‌جات، اسانس آرایشی</p>
    </div>
  )
}
,
    { 
        id: 6, 
        img: p6, 
        title: "اسانس کره", 
        description: 'خرید اسانس کره با بهترین کیفیت، مناسب برای صنایع لبنی، کیک و شیرینی‌پزی.',
        metaDescription: "اسانس کره با رایحه و طعم طبیعی، مناسب برای صنایع غذایی، لبنیات، کیک و شیرینی. خرید اسانس کره با کیفیت بالا و مقاوم در برابر حرارت.",
        body: (
            <div class="description-box">
                <h2>اسانس کره</h2>
                <p>

اسانس کره از جمله اسانس های پرمصرف در صنایع غذایی می باشد. این اسانس به طور عمده در صنایع مختلف مانند لبنی، کیک و
شیرینی و انواع تافی کاربرد گسترده دارد.                </p>
                
                <h2>کاربرد در محصولات لبنی</h2>
                <p>

اسانس کره در صنایع لبنی در انواع کره، بستنی و پنیر پیتزا کاربرد دارد. البته پروفایل رایحه این اسنس در زیرمجموعه های
مختلف تفاوت زیادی دارد.
رایحه این اسانس در انواع کره گیاهی و حیوانی پروفایلی گرم و شیرین می باشد. این زایحه تداعی کننده پروفایل طبیعی این اسانس
می باشد.
اما در انواع پنیر پیتزا این پروفایل متفاوت می باشد. این پروفایل در پنیر پیتزا ترش و تیز می باشد که جایگیزینی مناسب می باشد.
در انواع بسیتنی اسانس کره بیشتر دارای پروفایل شیرین و گرم می باشد اما قدرت آن نسبت به انواع کره کمتر می باشد.                </p>
                
                <h2>کاربرد در کیک و شیرینی</h2>
                <p>

در صنعت شیرینی و کیک پروفایل این اسانس باز هم تغییر می کند. این پروفایل دارای گرمی و شیرینی بیشتری می باشد. اما تفاوت
اصلی در ساختار طراحی آن می باشد. با توجه به محصولات مختلف در این صنعت، مقاومت حرارتی نیز اهمیت پیدا می کند.
به این شکل که به ترتیب برای بیسکویت، کیک، شیرینی و کلوچه مقاومت حرارتی به نسبت کم می شود. به طور کلی هر چه
محصولات آردی نرم تر باشند یعنی آب بیشتری خواهند داشت و به همین دلیل درجه حرارت پخت آن نیز کمتر می شود.                </p>
    
                <h2>اهمیت اسانس کره در صنایع غذایی</h2>
                <p>
در دیگر بخش تولیدات اسنسن کره در تولید انواع تافی کاربرد دارد. در این بخش با توجه به بالا بودن حرارت، اسانس با مقاومت
حرارتی مطلوب است. این اسانس دارای پروفایل گرم و شیرین است.
به این ترتیب با توجه به توضیحات بالا، اسانس کره در صنعت بسیار پر کاربد است و تنوع رایحه و پروفایل بالایی دارد. که این امر
نیاز به دانش مورد یاز برای طراحی مناسب برای تمامی محصولات می باشد.
صنایع کیمیاگر آریان اسانس با به کار گیری دانش روز و بهرمندی از تجربه طعم سازان مطرح دنیا، توانسته نیاز های صنایع غذایی
تنا حدود زیادی برطرف کند. در این راه با همکاری با بزرگترین شرکت های تولید کننده مواد اولیه، در جهت بالا بردن کیفیت
محصولات توانسته جایگاه ارزشمندی در بین مشتریان ایجاد کند.                </p>
            </div>
        )
    },
    { 
        id: 7, 
        img: p7, 
        title: 'اسانس شیر کندانسه', 
        description: 'خرید اسانس شیر کندانسه با رایحه و طعم طبیعی، مناسب برای صنایع غذایی و شیرینی‌پزی.',
        metaDescription: "اسانس شیر کندانسه مناسب برای صنایع غذایی، شیرینی‌پزی و شکلات‌سازی. خرید اسانس شیر کندانسه با کیفیت بالا و رایحه دلپذیر و مقاوم در برابر حرارت.",
        body: (
            <div class="description-box">
            
                <p>

اسانس شیرکندانسه یکی از اسانس های خاص و پرمصرف در صنایع غذایی شناخته می شود. این اسانس با توجه به مصارف مختلف
و استانداردهای متفاوت، دارای تنوع زیادی در بین مشتریان می باشد.
اسانس شیرکندانسه در انواع مختلف صنایع غذایی از لبنی و شیرینی و شکلات تا انواع تافی و کلوچه ها کاربرد فراوانی دارد.
این اسانس با توجه به نوع پروفایل تولیدی، شامل اسانس شیر تازه و شیر پخته نیز می باشد.
به طور کلی اسانس های شیر دارای پروفایل شیرین و گرم می باشد و از همین روی در صنایع مختلف اسانس پرطرف دارای
محسوب می شود.                </p>
                
                <h2>کاربرد در صنایع شیرینی و شکلات</h2>
                <p>
اسانس شیرکندانسه در صنایع شیرینی و شکلات مصرف گسترده تری دارد. این اسانس به طور عمدعه در تولید کیک تی تاپ و
انواع فیلر کاربرد گسترده تری دارد.
اما با توجه به نوع حلالیت آن کاربرد بالایی در انواع شکلات و تافی نیز دارد. البته برای انواع تافی شیرپخته طعم محبوب تری
محسوب می شود و کاربرد بیشتری دارد.                </p>
                
                <h2>کاربرد در صنایع لبنی</h2>
                <p>

در صنایع لبنی، اسانس شیر کندانسه به طور عمده به عنوان طعم دهنده مکمل در انواع شیر پاستوریزه کاربرد دارد. این اسانس
شیرینی و گرمی مطبوعی بع محصول می دهد.                </p>
                
                <h2>ویژگی‌ها و مزایا</h2>
                <p>

استفاده از شیر تازه و شیر پخته در صنایع مختلف بستگی به ذائقه مصرف کننده دارد. اساسن شیر تازه از شیرینی و گرمی کمتری
برخوردار بوده و معمولا در صنایع لبنی و کیک و کلوچه مورد مصرف قرار می گیرد. اما اسانس شیر پخته دارای عطر و طعم
بیشتری بوده و معمولا در انواع تافی و شکلات مورد استفاده دارد تا باعث هر چه بیشتر شده عطر و طعم شود.
مقاومت حراراتی اسانس شیر نسبتا بالا بوده اما با استفاده از حلال های مختلف می توان این میزان از مقاومت را بسته به نوع
محصول تنظیم کرد. 
               </p>
               ,
               
            </div>
    )}
  ,  {
  id: 8,
  img: p8,
  title: 'اسانس قهوه',
  description: 'خرید اسانس قهوه با عطر و طعم قوی، مناسب برای صنایع غذایی، نوشیدنی و دسر.',
  metaDescription:
    'اسانس قهوه با رایحه دلپذیر و طبیعی، مناسب برای تولید شکلات، نوشیدنی‌ها و دسرهای متنوع. خرید اسانس قهوه با کیفیت بالا و مقاومت حرارتی عالی.',
  body: (
    <div class="description-box">
      <p>
        اسانس قهوه یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی است که با عطر و طعم طبیعی خود، انتخابی مناسب برای
        تولید انواع نوشیدنی‌ها، دسرها و محصولات پختنی می‌باشد. این اسانس قابلیت افزودن طعمی متمایز و جذاب را
        به محصولات دارد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        اسانس قهوه در تولید انواع نوشیدنی‌های گرم و سرد، مانند قهوه فوری و شیرقهوه کاربرد دارد. عطر ماندگار و
        قوی این اسانس، کیفیت محصولات را بهبود می‌بخشد و تجربه‌ای بی‌نظیر برای مصرف‌کنندگان فراهم می‌کند.
      </p>
      <h2>کاربرد در صنایع شیرینی و شکلات</h2>
      <p>
        این اسانس به‌طور گسترده‌ای در تهیه شکلات‌های تلخ و شیرین، انواع کیک، ترافل و بستنی استفاده می‌شود.
        ترکیب آن با طعم‌های دیگر مانند وانیل یا کارامل، محصولات را غنی‌تر می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس قهوه با رایحه طبیعی و مقاومت حرارتی بالا، برای انواع فرایندهای پخت مناسب است. این اسانس
        به دلیل کیفیت بالا، در محصولات متنوع غذایی و نوشیدنی استفاده می‌شود و به طور کامل با ذائقه مشتریان
        همخوانی دارد.
      </p>
    </div>
  ),
},
{
  id: 9,
  img: p9,
  title: 'اسانس شکلات',
  description: 'خرید اسانس شکلات با طعم طبیعی و ماندگار، مناسب برای صنایع غذایی و شیرینی‌پزی.',
  metaDescription:
    'اسانس شکلات با طعمی غنی و شیرین، مناسب برای تولید انواع دسرها، شکلات و کیک. خرید اسانس شکلات با کیفیت بالا و رایحه‌ای ماندگار.',
  body: (
    <div class="description-box">
      <p>
        اسانس شکلات یکی از طعم‌دهنده‌های اصلی و پراستفاده در صنایع غذایی است. این اسانس با رایحه‌ای غنی و
        دلپذیر، مناسب برای تهیه انواع دسرها، نوشیدنی‌ها و محصولات پختنی می‌باشد.
      </p>
      <h2>کاربرد در صنایع شیرینی و شکلات</h2>
      <p>
        اسانس شکلات برای تولید شکلات‌های تخته‌ای، انواع شیرینی، کیک، کوکی و ترافل استفاده می‌شود. این اسانس
        با کیفیت بالا، به محصولات طعمی ماندگار و بی‌نظیر می‌بخشد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        این اسانس در نوشیدنی‌های شکلاتی داغ، شیک‌ها و بستنی‌ها بسیار محبوب است. ترکیب آن با سایر اسانس‌ها
        مانند وانیل یا قهوه، تنوع بیشتری به محصولات اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس شکلات با مقاومت حرارتی بالا و عطر و طعم طبیعی، به‌راحتی با انواع محصولات ترکیب می‌شود.
        این اسانس انتخابی ایده‌آل برای محصولات غذایی متنوع است.
      </p>
    </div>
  ),
},
{
  id: 10,
  img: p10,
  title: 'اسانس وانیل',
  description: 'خرید اسانس وانیل با رایحه‌ای دلپذیر و شیرین، مناسب برای صنایع غذایی، آرایشی و بهداشتی.',
  metaDescription:
    'اسانس وانیل با رایحه‌ای دلپذیر و شیرین، مناسب برای تهیه انواع دسرها، کیک‌ها، محصولات آرایشی و بهداشتی. خرید اسانس وانیل با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس وانیل یکی از محبوب‌ترین اسانس‌ها در سراسر جهان است که با رایحه‌ای شیرین و دلپذیر شناخته
        می‌شود. این اسانس در صنایع غذایی، آرایشی و بهداشتی کاربرد فراوانی دارد و می‌تواند به طور طبیعی
        طعمی دلپذیر و عطر خوشایند به محصولات مختلف ببخشد.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس وانیل در تولید انواع دسرها، کیک‌ها، شیرینی‌ها و شکلات‌ها به کار می‌رود. این اسانس باعث
        طعمی بی‌نظیر و رایحه‌ای گرم و شیرین در این محصولات می‌شود که آنها را محبوب و جذاب می‌کند.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        در صنایع آرایشی و بهداشتی، اسانس وانیل به عنوان رایحه‌ای ملایم و دلپذیر برای تولید کرم‌ها،
        لوسیون‌ها، شامپوها و محصولات مراقبت از پوست استفاده می‌شود. این اسانس به دلیل خواص نرم‌کنندگی و
        تسکین‌دهندگی خود، در بسیاری از محصولات مراقبتی به کار می‌رود.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس وانیل دارای خواص ضد استرس و آرامش‌بخش است. این اسانس به کاهش اضطراب و افزایش حس راحتی
        کمک می‌کند و به همین دلیل در بسیاری از محصولات مراقبت از پوست و ماساژ به کار می‌رود.
      </p>
      <h2>خواص درمانی</h2>
      <p>
        اسانس وانیل علاوه بر رایحه خوشایند، دارای خواص درمانی نیز می‌باشد. این اسانس می‌تواند به کاهش
        دردهای عضلانی، بهبود کیفیت خواب و تسکین دردهای عصبی کمک کند. به علاوه، وانیل خواص آنتی‌اکسیدانی
        نیز دارد که به حفظ سلامت پوست و مقابله با پیری زودرس کمک می‌کند.
      </p>
    </div>
  ),
}
,

  {
  id: 11,
  img: p11,
  title: 'اسانس نارگیل',
  description: 'خرید اسانس نارگیل با رایحه‌ای گرم و طعم مطبوع، مناسب برای صنایع غذایی و آرایشی.',
  metaDescription:
    'اسانس نارگیل با عطر طبیعی و ماندگار، مناسب برای تولید دسرها، کیک‌ها، بستنی‌ها و محصولات آرایشی. خرید اسانس نارگیل با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس نارگیل یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی و آرایشی است. این اسانس با رایحه‌ای گرم و طعمی
        شیرین و دلنشین، برای تولید انواع محصولات مصرفی مناسب است.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        این اسانس در تهیه انواع کیک‌ها، بستنی‌ها، تافی‌ها و نوشیدنی‌ها استفاده می‌شود. ترکیب اسانس نارگیل با
        طعم‌های دیگر مانند شکلات یا وانیل، تجربه‌ای جدید برای مصرف‌کنندگان فراهم می‌کند.
      </p>
      <h2>کاربرد در صنایع آرایشی</h2>
      <p>
        اسانس نارگیل به دلیل رایحه مطبوع خود، در تولید محصولات آرایشی و بهداشتی مانند کرم‌ها، لوسیون‌ها و
        شامپوها کاربرد گسترده‌ای دارد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس نارگیل دارای ماندگاری بالا، عطر طبیعی و مقاومت حرارتی مناسب است. این ویژگی‌ها آن را به یک
        انتخاب ایده‌آل برای صنایع مختلف تبدیل کرده است.
      </p>
    </div>
  ),
},
{
  id: 12,
  img: p12,
  title: 'اسانس توت فرنگی',
  description: 'خرید اسانس توت فرنگی با طعمی شیرین و عطر جذاب، مناسب برای دسرها و نوشیدنی‌ها.',
  metaDescription:
    'اسانس توت فرنگی با رایحه‌ای طبیعی و طعمی شیرین، مناسب برای تولید دسرها، بستنی‌ها و نوشیدنی‌ها. خرید اسانس توت فرنگی با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس توت فرنگی یکی از اسانس‌های پرطرفدار در صنایع غذایی است. این اسانس با طعمی شیرین و عطر جذاب خود،
        انتخابی مناسب برای تولید انواع محصولات دسر و نوشیدنی است.
      </p>
      <h2>کاربرد در صنایع دسر و بستنی</h2>
      <p>
        این اسانس در تولید کیک‌ها، ژله‌ها، بستنی‌ها و انواع دسرها استفاده می‌شود. عطر و طعم توت فرنگی به
        محصولات شیرینی طعمی متفاوت و تازه می‌بخشد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        اسانس توت فرنگی در تولید نوشیدنی‌های سرد و گرم، شربت‌ها و میلک‌شیک‌ها کاربرد دارد و طعمی دلنشین به
        آن‌ها اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس توت فرنگی دارای مقاومت حرارتی بالا و ماندگاری طولانی است. این اسانس به راحتی با سایر طعم‌دهنده‌ها
        ترکیب شده و محصولی با کیفیت عالی ارائه می‌دهد.
      </p>
    </div>
  ),
},
{
  id: 13,
  img: p13,
  title: 'اسانس شاه توت',
  description: 'خرید اسانس شاه‌توت با طعم شیرین و ترش، مناسب برای صنایع نوشیدنی و دسر.',
  metaDescription:
    'اسانس شاه‌توت با عطر طبیعی و طعمی منحصر به فرد، مناسب برای تولید دسرها، نوشیدنی‌ها و محصولات لبنی. خرید اسانس شاه‌توت با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس شاه‌توت یکی از اسانس‌های جذاب در صنایع غذایی است که به دلیل طعم متمایز و عطر طبیعی خود، در
        تولید محصولات متنوع استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        این اسانس در تولید نوشیدنی‌های طبیعی و شربت‌ها استفاده می‌شود و طعمی تازه و دلچسب به محصولات اضافه
        می‌کند.
      </p>
      <h2>کاربرد در صنایع دسر و لبنی</h2>
      <p>
        اسانس شاه‌توت در تهیه دسرهای ژله‌ای، بستنی‌ها و محصولات لبنی مانند ماست میوه‌ای کاربرد دارد و طعمی
        منحصربه‌فرد به آن‌ها می‌بخشد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        این اسانس دارای رایحه‌ای طبیعی، طعمی ماندگار و مقاومت حرارتی بالا است. همچنین به راحتی با سایر
        مواد ترکیب شده و کیفیت محصول نهایی را افزایش می‌دهد.
      </p>
    </div>
  ),
},
{
  id: 14,
  img: p14,
  title: 'اسانس کره',
  description: 'خرید اسانس کره با رایحه گرم و طعمی دلنشین، مناسب برای صنایع غذایی و پخت.',
  metaDescription:
    'اسانس کره با عطر طبیعی و طعمی لذیذ، مناسب برای تولید محصولات غذایی مانند کیک‌ها، کلوچه‌ها و تافی. خرید اسانس کره با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس کره یکی از اسانس‌های پرکاربرد در صنایع غذایی است که عطر گرم و طعمی لذیذ به محصولات می‌بخشد.
        این اسانس به دلیل عطر طبیعی خود، در محصولات متنوعی مورد استفاده قرار می‌گیرد.
      </p>
      <h2>کاربرد در صنایع شیرینی و کیک</h2>
      <p>
        این اسانس در تولید کیک‌ها، کلوچه‌ها و تافی‌ها استفاده می‌شود. عطر و طعم کره طبیعی به محصولات، حس
        تازگی و کیفیت بالایی اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس کره با مقاومت حرارتی بالا و ماندگاری طولانی، انتخابی مناسب برای تولید محصولات پختنی است. این
        اسانس به دلیل طعم طبیعی خود، در بین مشتریان بسیار محبوب است.
      </p>
    </div>
  ),
},
{
  id: 15,
  img: p15,
  title: 'اسانس انبه',
  description: 'اسانس انبه با رایحه‌ای شیرین و طعمی گرم و استوایی، مناسب برای صنایع غذایی، نوشیدنی‌ها و محصولات آرایشی و بهداشتی است.',
  metaDescription:
    'اسانس انبه با طعمی استوایی و خوشمزه، مناسب برای محصولات آرایشی، بهداشتی و صنایع غذایی. خرید اسانس انبه با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس انبه یکی از محبوب‌ترین اسانس‌ها در صنایع مختلف است. این اسانس با رایحه‌ای گرم و شیرین،
        به‌ویژه در محصولات غذایی، نوشیدنی‌ها، و محصولات آرایشی و بهداشتی کاربرد فراوان دارد. طعم و بوی
        انبه به‌طور طبیعی یادآور فصول گرم و استوایی است و می‌تواند به محصولات طعمی دلپذیر و متفاوت
        ببخشد. اسانس انبه به دلیل خاصیت‌های طبیعی خود، در بسیاری از صنایع به‌ویژه برای تولید محصولات
        بهداشتی و آرایشی استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس انبه به دلیل خواص نرم‌کننده و مرطوب‌کننده‌ای که دارد، در بسیاری از محصولات مراقبت از پوست
        مانند کرم‌ها، لوسیون‌ها، ژل‌ها و ماسک‌های صورت استفاده می‌شود. این اسانس برای پوست‌های خشک
        مناسب است و به حفظ رطوبت پوست کمک می‌کند. رایحه طبیعی و دلپذیر انبه باعث ایجاد احساس آرامش
        و شادابی در پوست می‌شود.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس انبه در صنایع غذایی و نوشیدنی به‌ویژه در تولید دسرها، آبمیوه‌ها، بستنی‌ها و نوشیدنی‌های
        انرژی‌زا استفاده می‌شود. این اسانس طعمی استوایی و شیرین به محصولات می‌بخشد و برای کسانی که
        به دنبال طعم‌های جدید و متفاوت هستند، انتخابی عالی است. همچنین، اسانس انبه در تهیه شربت‌ها و
        کوکتل‌ها نیز کاربرد دارد.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس انبه</h2>
      <p>
        اسانس انبه علاوه بر رایحه دلپذیر خود، دارای خواص ضدالتهابی است که می‌تواند به تسکین پوست‌های
        تحریک‌شده و ملتهب کمک کند. همچنین این اسانس به دلیل وجود ویتامین‌ها و مواد مغذی، می‌تواند در
        تقویت و جوان‌سازی پوست مؤثر باشد. استفاده از اسانس انبه در محصولات مراقبت از پوست باعث نرم‌شدن
        و جوانی پوست می‌شود.
      </p>
      <h2>خواص سلامتی اسانس انبه</h2>
      <p>
        اسانس انبه همچنین دارای خواص آنتی‌اکسیدانی است که می‌تواند در کاهش آسیب‌های ناشی از رادیکال‌های
        آزاد مفید باشد. این خواص به کاهش علائم پیری زودرس و حفظ سلامت پوست کمک می‌کند. علاوه بر آن،
        اسانس انبه به‌عنوان یک عامل آرامش‌بخش و ضد استرس نیز شناخته شده است. با افزودن این اسانس به
        محصولات بهداشتی و مراقبتی، می‌توان از خواص آن برای تقویت سلامت جسم و روان بهره‌برد.
      </p>
      <h2>کاربرد در محصولات تغذیه‌ای</h2>
      <p>
        در صنعت تغذیه، اسانس انبه به‌عنوان یک ماده طبیعی برای بهبود طعم و رایحه محصولات مختلفی مانند
        کیک‌ها، شکلات‌ها، مرباها و سس‌ها استفاده می‌شود. همچنین این اسانس در تولید انواع نوشیدنی‌ها،
        از جمله اسموتی‌ها و آبمیوه‌ها، کاربرد دارد و طعمی شیرین و استوایی به این نوشیدنی‌ها می‌بخشد.
      </p>
      <h2>مزایای استفاده از اسانس انبه</h2>
      <p>
        اسانس انبه به‌عنوان یک ماده طبیعی در بسیاری از محصولات صنعتی، علاوه بر طعم و عطر دلپذیر خود،
        ویژگی‌های منحصر به‌فردی دارد که باعث محبوبیت آن در صنایع مختلف می‌شود. این اسانس باعث می‌شود که
        محصولات نه‌تنها از نظر طعم و عطر متفاوت باشند، بلکه خواص درمانی و زیبایی نیز به آن‌ها افزوده
        شود.
      </p>
    </div>
  ),
}
,
{
  id: 16,
  img: p16,
  title: 'اسانس فندق',
  description: 'خرید اسانس فندق با طعمی دلپذیر و رایحه‌ای گرم و شیرین، مناسب برای صنایع غذایی، نوشیدنی‌ها، شکلات‌ها و محصولات آرایشی.',
  metaDescription:
    'اسانس فندق با طعمی ملایم و رایحه‌ای خوشمزه، مناسب برای محصولات شکلاتی، دسرها، نوشیدنی‌ها و حتی محصولات مراقبت از پوست. خرید اسانس فندق با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس فندق با رایحه‌ای گرم و شیرین و طعمی دلپذیر یکی از اسانس‌های محبوب در صنایع مختلف است. این اسانس به
        دلیل رایحه طبیعی و خاص خود، در تهیه محصولات غذایی و بهداشتی به کار می‌رود. اسانس فندق می‌تواند به راحتی
        در تولید انواع شکلات‌ها، دسرها، نوشیدنی‌ها و همچنین در محصولات آرایشی و بهداشتی استفاده شود.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس فندق در صنایع غذایی کاربرد گسترده‌ای دارد. این اسانس به ویژه در تولید شکلات‌ها، کیک‌ها، دسرها،
        شیرینی‌ها و انواع نوشیدنی‌ها استفاده می‌شود. رایحه فندق می‌تواند به این محصولات طعمی غنی، طبیعی و
        خوشایند بدهد و آن‌ها را به تجربه‌ای دلپذیر تبدیل کند. همچنین در محصولات شیرینی‌پزی و انواع نوشیدنی‌ها
        مانند قهوه و شربت‌ها می‌توان از این اسانس برای ایجاد طعم و بویی گرم و دلچسب بهره برد.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        در صنایع آرایشی و بهداشتی، اسانس فندق به عنوان یک ماده معطر و تغذیه‌کننده به کار می‌رود. این اسانس در
        تولید کرم‌ها، لوسیون‌ها، شامپوها و سایر محصولات مراقبت از پوست و مو به علت خواص نرم‌کنندگی و آنتی‌اکسیدانی
        خود استفاده می‌شود. اسانس فندق به پوست نرمی می‌بخشد و به درمان پوست‌های خشک و آسیب‌دیده کمک می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس فندق دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این خواص به پوست کمک می‌کند تا از آسیب‌های ناشی از
        رادیکال‌های آزاد محافظت شود و روند پیری را کند کند. اسانس فندق همچنین به پوست‌های حساس کمک می‌کند تا
        نرم و مرطوب باقی بمانند. رایحه این اسانس به طور طبیعی احساس آرامش و شادی را افزایش می‌دهد و می‌تواند
        در بهبود روحیه و کاهش استرس موثر باشد.
      </p>
      <h2>خواص درمانی</h2>
      <p>
        اسانس فندق علاوه بر خواص زیبایی، دارای خواص درمانی نیز است. این اسانس می‌تواند به تسکین دردهای عضلانی
        و مفصلی کمک کند. به علاوه، استفاده از این اسانس در محصولات آرایشی به بهبود وضعیت پوست‌های آسیب‌دیده
        و ملتهب کمک می‌کند. از آنجا که اسانس فندق دارای خواص ضد التهابی است، برای کاهش التهاب و قرمزی پوست نیز
        مفید است.
      </p>
      <h2>نکات احتیاطی</h2>
      <p>
        اسانس فندق به دلیل غلظت بالا باید با دقت استفاده شود. در هنگام استفاده در محصولات آرایشی، پیشنهاد می‌شود
        که از آن در مقدار کم استفاده کنید و همیشه قبل از استفاده گسترده، تست حساسیت انجام دهید. همچنین، این
        اسانس باید دور از دسترس کودکان نگهداری شود.
      </p>
    </div>
  ),
}
,
{
  id: 17,
  img: p17,
  title: 'اسانس شیر خشک',
  description: 'خرید اسانس شیر خشک با طعمی طبیعی و عطر ملایم، مناسب برای صنایع لبنی و شیرینی‌پزی.',
  metaDescription:
    'اسانس شیر خشک با طعمی طبیعی و ملایم، مناسب برای تولید محصولات لبنی، کیک‌ها و شیرینی‌ها. خرید اسانس شیر خشک با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس شیر خشک یکی از اسانس‌های پرکاربرد در صنایع غذایی است که به دلیل طعم ملایم و رایحه طبیعی خود،
        در بسیاری از محصولات استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع لبنی</h2>
      <p>
        اسانس شیر خشک در تولید محصولات لبنی مانند شیر پاستوریزه، ماست و پنیر به کار می‌رود. این اسانس
        طعمی شیرین و طبیعی به محصولات می‌بخشد.
      </p>
      <h2>کاربرد در صنایع شیرینی‌پزی</h2>
      <p>
        اسانس شیر خشک در شیرینی‌پزی به طور عمده در تولید کیک‌ها، کلوچه‌ها و تافی‌ها استفاده می‌شود. طعمی
        طبیعی و ملایم به این محصولات اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس شیر خشک مقاومت حرارتی بالایی دارد و می‌تواند در دماهای مختلف به خوبی عملکرد داشته باشد. این
        ویژگی آن را به یک انتخاب عالی برای تولیدات پختنی تبدیل کرده است.
      </p>
    </div>
  ),
},
{
  id: 18,
  img: p18,
  title: 'اسانس چای سبز',
  description: 'اسانس چای سبز با طعمی ملایم و خاصیت ضدoxidant، مناسب برای صنایع آرایشی، بهداشتی و نوشیدنی‌ها است.',
  metaDescription:
    'اسانس چای سبز با طعمی طبیعی و خواص ضداکسیدانی، مناسب برای محصولات آرایشی، بهداشتی و نوشیدنی‌ها. خرید اسانس چای سبز با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس چای سبز از برگ‌های تازه چای سبز استخراج می‌شود و به دلیل خواص آنتی‌اکسیدانی و آرامش‌بخش خود
        در صنایع مختلف به‌ویژه در زمینه‌های آرایشی، بهداشتی و نوشیدنی‌ها به‌طور گسترده‌ای استفاده می‌شود.
        چای سبز به‌عنوان یک نوشیدنی محبوب و سالم شناخته شده است و اسانس آن ویژگی‌های مشابه چای سبز را
        در خود دارد. این اسانس برای کسانی که به دنبال ترکیبات طبیعی و فواید سلامتی هستند، گزینه‌ای
        ایده‌آل است.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس چای سبز به دلیل خواص آنتی‌اکسیدانی و ضد التهابی خود در بسیاری از محصولات آرایشی و بهداشتی
        استفاده می‌شود. این اسانس در تولید کرم‌ها، لوسیون‌ها، شوینده‌ها و ماسک‌های صورت کاربرد دارد و
        به‌عنوان یک عامل ضد پیری و ضدچروک شناخته می‌شود. خواص ضدالتهابی اسانس چای سبز به تسکین پوست
        تحریک‌شده کمک کرده و به تقویت و حفظ سلامتی پوست کمک می‌کند.
      </p>
      <h2>کاربرد در صنایع دارویی</h2>
      <p>
        اسانس چای سبز در داروسازی نیز کاربرد فراوان دارد. این اسانس به‌عنوان یک عامل ضدآنتی‌اکسیدانی
        در ترکیب با سایر داروها برای مقابله با مشکلات پوستی مانند آکنه و پیری زودرس استفاده می‌شود.
        علاوه بر این، اسانس چای سبز به‌عنوان یک عامل تقویت‌کننده سیستم ایمنی بدن شناخته شده است و برای
        درمان برخی از بیماری‌های التهابی نیز مفید است.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس چای سبز در صنایع غذایی به‌ویژه در تولید نوشیدنی‌ها، چای‌های طعم‌دار و دسرها استفاده می‌شود.
        طعم ملایم و طبیعی این اسانس باعث می‌شود که در نوشیدنی‌هایی مانند چای سبز، آبمیوه‌ها و حتی
        نوشیدنی‌های انرژی‌زا به‌کار رود. این اسانس به محصولات غذایی طعمی خاص و تازه می‌بخشد و به‌ویژه
        برای افرادی که به سلامتی خود اهمیت می‌دهند، انتخابی مناسب است.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس چای سبز</h2>
      <p>
        اسانس چای سبز حاوی ترکیبات آنتی‌اکسیدانی است که به محافظت از پوست در برابر آسیب‌های ناشی از
        رادیکال‌های آزاد کمک می‌کند. این اسانس همچنین خواص ضد پیری دارد و می‌تواند به کاهش خطوط ریز
        و چین و چروک‌ها کمک کند. علاوه بر آن، اسانس چای سبز به‌عنوان یک عامل آرامش‌بخش در کاهش استرس
        و اضطراب شناخته می‌شود و استفاده از آن در محصولات مراقبت از پوست به آرامش پوست کمک می‌کند.
      </p>
      <p>
        این اسانس همچنین به سلامت پوست کمک کرده و در کاهش التهاب‌های پوستی مانند آکنه و قرمزی موثر است.
        استفاده از اسانس چای سبز در محصولات مراقبت از پوست باعث کاهش چربی اضافی پوست و حفظ رطوبت آن
        می‌شود. این اسانس با بهبود بافت پوست، آن را نرم‌تر و لطیف‌تر می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس چای سبز در صنعت</h2>
      <p>
        اسانس چای سبز یک ترکیب طبیعی است که در مقایسه با مواد شیمیایی و مصنوعی مزایای زیادی دارد. این
        اسانس در محصولات مراقبت از پوست، نوشیدنی‌ها و حتی دسرها به‌عنوان یک ماده طبیعی و سالم
        استفاده می‌شود. همچنین، این اسانس به‌دلیل خواص آنتی‌اکسیدانی و ضدالتهابی که دارد، در بهبود
        کیفیت محصولات بهداشتی و غذایی موثر است.
      </p>
    </div>
  ),
}
,
{
  id: 19,
  img: p19,
  title:'اسانس آلوئه ورا',
  description: 'اسانس آلوئه‌ورا با طعمی خنک و ملایم، مناسب برای صنایع آرایشی، بهداشتی، دارویی و محصولات خوراکی است.',
  metaDescription:
    'اسانس آلوئه‌ورا با طعمی ملایم و خنک، مناسب برای محصولات آرایشی، بهداشتی، دارویی و خوراکی. خرید اسانس آلوئه‌ورا با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس آلوئه‌ورا یکی از اسانس‌های طبیعی و پرکاربرد است که به دلیل خواص درمانی و طعمی ملایم، در صنایع
        مختلف از جمله آرایشی، بهداشتی، دارویی و خوراکی به‌طور گسترده استفاده می‌شود. آلوئه‌ورا یک گیاه با خواص
        تسکین‌دهنده و مرطوب‌کننده است که در اسانس آن این ویژگی‌ها به‌خوبی حفظ می‌شود. این اسانس برای افرادی
        که به‌دنبال ترکیبات طبیعی برای مراقبت از پوست و بهبود کیفیت زندگی خود هستند، گزینه‌ای ایده‌آل است.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس آلوئه‌ورا به دلیل خواص تسکین‌دهنده و مرطوب‌کننده خود در تولید انواع کرم‌ها، لوسیون‌ها و
        محصولات مراقبت از پوست کاربرد دارد. این اسانس به‌ویژه برای افرادی که پوست خشک یا حساس دارند،
        مفید است. علاوه بر این، اسانس آلوئه‌ورا در محصولات ضدآفتاب و ضدجوش نیز استفاده می‌شود و به
        کاهش التهاب و قرمزی پوست کمک می‌کند.
      </p>
      <h2>کاربرد در صنایع دارویی</h2>
      <p>
        اسانس آلوئه‌ورا به دلیل خواص ضدالتهابی و درمانی خود در تولید محصولات دارویی مانند پمادها و
        لوسیون‌های ترمیم‌کننده استفاده می‌شود. این اسانس در درمان سوختگی‌ها، بریدگی‌ها و زخم‌های سطحی به
        کار می‌رود و به‌عنوان یک درمان طبیعی برای تحریک روند بهبودی پوست شناخته شده است.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس آلوئه‌ورا در صنایع غذایی و نوشیدنی به‌ویژه در تولید آبمیوه‌ها، شربت‌ها و نوشیدنی‌های انرژی‌زا
        مورد استفاده قرار می‌گیرد. طعم ملایم و خنک این اسانس، آن را به یک انتخاب عالی برای طعم‌دهی به نوشیدنی‌ها
        و دسرها تبدیل کرده است. همچنین این اسانس در برخی محصولات خوراکی مانند بستنی، ماست و ژله نیز به‌کار
        می‌رود و طعمی طبیعی و دلچسب به آن‌ها می‌بخشد.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس آلوئه‌ورا</h2>
      <p>
        اسانس آلوئه‌ورا دارای خواص ضدالتهابی، مرطوب‌کننده و ترمیم‌کننده است که به سلامت پوست کمک می‌کند.
        این اسانس همچنین حاوی ترکیبات آنتی‌اکسیدانی است که از پوست در برابر آسیب‌های ناشی از رادیکال‌های
        آزاد محافظت می‌کند. استفاده از اسانس آلوئه‌ورا باعث افزایش نرمی و لطافت پوست می‌شود و از خشکی
        پوست جلوگیری می‌کند.
      </p>
      <p>
        علاوه بر آن، اسانس آلوئه‌ورا به‌عنوان یک عامل تسکین‌دهنده در برابر سوختگی‌ها، آفتاب‌سوختگی‌ها و
        التهاب‌های پوستی عمل می‌کند. این اسانس همچنین به بازسازی و تقویت بافت‌های آسیب‌دیده پوست کمک
        کرده و فرآیند بهبودی را تسریع می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس آلوئه‌ورا در صنعت</h2>
      <p>
        اسانس آلوئه‌ورا به‌طور مؤثر خواص درمانی خود را در طی تولید و استفاده از محصولات مختلف حفظ می‌کند.
        این اسانس به‌عنوان یک ماده طبیعی، جایگزین مناسبی برای مواد شیمیایی و مصنوعی در محصولات آرایشی و
        بهداشتی است. علاوه بر این، این اسانس در تولید محصولات غذایی و نوشیدنی‌ها نیز به‌کار می‌رود تا
        طعمی تازه و طبیعی به آن‌ها بدهد. اسانس آلوئه‌ورا همچنین در دمنوش‌ها و نوشیدنی‌های خنک برای افرادی
        که به‌دنبال طعم‌های طبیعی هستند، مفید است.
      </p>
    </div>
  ),
}

,{
  id: 20,
  img: p20,
  title: 'اسانس زیتون',
  description: 'اسانس زیتون با عطر دلپذیر و خواص طبیعی، یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی و بهداشتی است.',
  metaDescription:
    'اسانس زیتون با رایحه‌ای خوشایند و خواص آنتی‌اکسیدانی، مناسب برای صنایع غذایی، بهداشتی و دارویی. خرید اسانس زیتون با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس زیتون یکی از اسانس‌های پرکاربرد در صنایع مختلف است. این اسانس با طعم و عطر منحصر به‌فرد خود، در
        صنایع غذایی، آرایشی و بهداشتی و دارویی به‌عنوان یک ترکیب طبیعی و مفید استفاده می‌شود. اسانس زیتون با
        خواص آنتی‌اکسیدانی و ضد التهابی خود، نه تنها به عنوان یک ماده افزودنی در غذاها بلکه به عنوان یک محصول
        درمانی و مراقبتی برای پوست و مو نیز به کار می‌رود.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس زیتون در صنایع غذایی به‌ویژه در تولید روغن‌های طعم‌دهنده، سس‌ها و غذاهای آماده کاربرد فراوانی
        دارد. این اسانس با طعمی ملایم و خوشایند، به‌طور ویژه در تهیه سس‌ها و پخت غذاهای مختلف مانند پاستا و
        سالادها استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس زیتون در تولید محصولات آرایشی و بهداشتی مانند صابون‌ها، کرم‌ها و لوسیون‌ها بسیار پرکاربرد است.
        این اسانس به دلیل خواص مرطوب‌کنندگی، ضد التهابی و ترمیم‌کننده، برای مراقبت از پوست‌های حساس و خشک
        مناسب است. همچنین اسانس زیتون به تقویت و سلامت موها نیز کمک می‌کند.
      </p>
      <h2>کاربرد در داروسازی</h2>
      <p>
        اسانس زیتون در داروسازی برای تولید محصولات درمانی ضد التهاب و مسکن‌های طبیعی استفاده می‌شود. خواص
        ضد التهابی آن موجب کاهش درد و التهابات مختلف می‌شود و به همین دلیل در تهیه کرم‌ها و پمادهای
        درمانی استفاده می‌گردد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس زیتون به‌عنوان یک آنتی‌اکسیدان طبیعی، از پوست و بدن در برابر آسیب‌های محیطی محافظت می‌کند. این
        اسانس دارای خواص ضد التهابی است که می‌تواند به تسکین دردهای عضلانی و التهابات پوستی کمک کند. علاوه
        بر این، اسانس زیتون به تقویت موها، کاهش خشکی پوست و جلوگیری از پیری زودرس کمک می‌کند.
      </p>
    </div>
  ),
}
 ,{
  id: 21,
  img: p21,
  title: 'اسانس شیر موز',
  description: 'اسانس شیر موز با طعم خوشمزه و منحصر به‌فرد، یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی و نوشیدنی است.',
  metaDescription:
    'اسانس شیر موز با طعم شیرین و ملایم، مناسب برای نوشیدنی‌ها، بستنی‌ها، دسرها و محصولات لبنی. خرید اسانس شیر موز با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس شیر موز یکی از اسانس‌های پرطرفدار در صنعت غذایی است که طعمی خوشمزه و طبیعی به محصولات مختلف
        می‌بخشد. این اسانس با ترکیب شیر و موز، طعمی ملایم و شیرین دارد که برای استفاده در نوشیدنی‌ها، دسرها،
        بستنی‌ها و انواع شیرینی‌ها بسیار مناسب است. اسانس شیر موز به دلیل خواص تغذیه‌ای موز و طعم شیرین و
        دلپذیرش، در بسیاری از محصولات غذایی و نوشیدنی‌ها مورد استفاده قرار می‌گیرد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        اسانس شیر موز به‌ویژه در صنعت تولید نوشیدنی‌ها کاربرد دارد. این اسانس به نوشیدنی‌هایی مانند اسموتی‌ها،
        شیرهای طعم‌دار، شربت‌ها و کوکتل‌ها طعمی شیرین و طبیعی می‌بخشد. با استفاده از اسانس شیر موز، می‌توان
        نوشیدنی‌های خوشمزه و مغذی تولید کرد که طعم موز و شیر در آن به‌خوبی حس شود.
      </p>
      <h2>کاربرد در صنایع دسر و بستنی</h2>
      <p>
        این اسانس در تولید دسرها و بستنی‌ها نیز کاربرد فراوانی دارد. اسانس شیر موز به‌ویژه در بستنی‌های
        طعم‌دار، کیک‌ها، پای‌ها و دیگر دسرها استفاده می‌شود و طعمی شیرین و طبیعی به این محصولات می‌بخشد.
        این اسانس برای کسانی که علاقه به طعم موز دارند، گزینه‌ای عالی است.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس شیر موز در صنایع لبنی نیز به‌ویژه در تولید شیرهای طعم‌دار، ماست‌ها و نوشیدنی‌های لبنی
        کاربرد دارد. این اسانس با ترکیب طعم شیر و موز، محصولات لبنی را به طعمی لذیذ و خوشمزه تبدیل می‌کند.
        استفاده از این اسانس می‌تواند تجربه‌ای جدید و خوشایند را برای مصرف‌کنندگان به ارمغان آورد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس شیر موز نه‌تنها طعمی شیرین و دلپذیر به محصولات می‌بخشد، بلکه خواص تغذیه‌ای موز نیز در این
        اسانس نهفته است. موز منبع خوبی از پتاسیم، ویتامین B6 و ویتامین C است که به تقویت سیستم ایمنی بدن،
        بهبود عملکرد قلب و عروق و حفظ سلامت پوست کمک می‌کند. همچنین، اسانس شیر موز به‌عنوان یک طعم‌دهنده
        طبیعی، به جای استفاده از مواد شیمیایی و افزودنی‌های مصنوعی، انتخاب مناسبی برای استفاده در
        صنایع غذایی و نوشیدنی است.
      </p>
      <h2>مزایای استفاده از اسانس شیر موز در صنعت</h2>
      <p>
        استفاده از اسانس شیر موز در محصولات غذایی و نوشیدنی‌ها علاوه بر ایجاد طعم عالی، مزایای متعددی
        از جمله افزایش جذابیت و تنوع در محصولات نهایی را به همراه دارد. این اسانس می‌تواند به‌طور مؤثری
        طعم و عطر طبیعی شیر موز را در محصول نهایی حفظ کند و محصولاتی خوشمزه و طبیعی به مشتریان ارائه دهد.
      </p>
    </div>
  ),
}
  , {
  id: 22,
  img: p22,
  title: 'اسانس لیمو ترش',
  description: 'اسانس لیمو ترش با طعم تازه و ترش، مناسب برای صنایع غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی است.',
  metaDescription:
    'اسانس لیمو ترش با طعمی ترش و تازه، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی. خرید اسانس لیمو ترش با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس لیمو ترش یکی از اسانس‌های پرطرفدار در صنایع مختلف است که به دلیل طعم تازه، ترش و نشاط‌آور خود،
        کاربرد گسترده‌ای در تولید محصولات غذایی، نوشیدنی‌ها، دسرها و حتی محصولات آرایشی دارد. لیمو ترش به‌طور
        طبیعی منبع غنی از ویتامین C، آنتی‌اکسیدان‌ها و مواد مغذی است که برای سلامت پوست و بدن مفید است. اسانس
        لیمو ترش در بسیاری از محصولات، طعمی تازه و دلپذیر ایجاد می‌کند که مصرف‌کنندگان از آن لذت می‌برند.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس لیمو ترش در صنایع غذایی به‌ویژه در تولید نوشیدنی‌های خنک، شربت‌ها، اسموتی‌ها و کوکتل‌ها بسیار
        پرطرفدار است. این اسانس به نوشیدنی‌ها طعمی ترش و طبیعی می‌بخشد و برای تولید انواع نوشیدنی‌های تابستانی
        یا در فصل‌های گرم سال، گزینه‌ای ایده‌آل است. همچنین این اسانس در طعم‌دهی به انواع دسرها، کیک‌ها و
        شیرینی‌ها نیز به‌کار می‌رود.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس لیمو ترش همچنین در صنایع لبنی به‌ویژه در تولید شیرهای طعم‌دار، ماست‌ها و بستنی‌ها استفاده می‌شود.
        طعم ترش و تازه این اسانس می‌تواند به محصولات لبنی طعمی دلپذیر و متفاوت بدهد. به‌ویژه در تولید بستنی
        لیمویی یا ماست لیمو، این اسانس نقش برجسته‌ای دارد.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس لیمو ترش به‌عنوان یک ماده طبیعی در تولید محصولات آرایشی و بهداشتی نیز کاربرد دارد. این اسانس به
        دلیل خواص آنتی‌اکسیدانی و ضدباکتریایی‌اش، در کرم‌ها و لوسیون‌های صورت و بدن استفاده می‌شود و به‌طور
        مؤثری به جوانسازی پوست و جلوگیری از آسیب‌های ناشی از رادیکال‌های آزاد کمک می‌کند. همچنین این اسانس
        در محصولات ضدعفونی‌کننده مانند ژل‌های ضدعفونی کننده دست و مواد شوینده نیز به‌کار می‌رود.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس لیمو ترش</h2>
      <p>
        اسانس لیمو ترش دارای خواص بی‌شماری است که علاوه بر طعم‌دهی طبیعی و خوشمزه، مزایای بسیاری برای
        سلامت بدن و پوست دارد. این اسانس غنی از ویتامین C است که به تقویت سیستم ایمنی بدن کمک کرده و
        آنتی‌اکسیدان‌های موجود در آن به کاهش التهاب و آسیب‌های ناشی از استرس اکسیداتیو کمک می‌کند.
      </p>
      <p>
        اسانس لیمو ترش همچنین دارای خواص ضدباکتریایی و ضدویروسی است که در مقابله با عفونت‌های پوستی و
        درمان مشکلات پوستی مانند آکنه موثر است. این اسانس به‌طور طبیعی می‌تواند به روشن‌تر شدن پوست کمک
        کرده و لکه‌های تیره را کاهش دهد.
      </p>
      <h2>مزایای استفاده از اسانس لیمو ترش در صنعت</h2>
      <p>
        استفاده از اسانس لیمو ترش در محصولات مختلف علاوه بر ایجاد طعم و عطر بی‌نظیر، مزایای زیادی دارد.
        این اسانس می‌تواند به‌طور مؤثری طعم ترش و تازه لیمو را در محصولات حفظ کند و به‌عنوان یک ماده طبیعی
        و سالم، جایگزین مناسبی برای طعم‌دهنده‌های مصنوعی باشد. همچنین، اسانس لیمو ترش به تولید محصولات
        با خواص درمانی و بهداشتی کمک کرده و در عین حال، تجربه‌ای لذت‌بخش برای مصرف‌کنندگان فراهم می‌کند.
      </p>
    </div>
  ),
}
,{
  id: 23,
  img: p23,
  title: 'اسانس زردآلو',
  description: 'اسانس زردآلو با طعم شیرین و دلپذیر، مناسب برای صنایع غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی است.',
  metaDescription:
    'اسانس زردآلو با طعمی شیرین و خوشمزه، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی. خرید اسانس زردآلو با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس زردآلو یکی از اسانس‌های محبوب در صنایع مختلف است که به دلیل طعم شیرین و خوشمزه‌اش، در تولید
        محصولات غذایی، نوشیدنی‌ها، دسرها و حتی محصولات آرایشی کاربرد دارد. این اسانس با رایحه طبیعی و ملایم
        خود، طعمی لذت‌بخش و دلپذیر به محصولات می‌بخشد و در بسیاری از صنایع از آن استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس زردآلو در صنایع غذایی به‌ویژه در تولید نوشیدنی‌ها، شربت‌ها و اسموتی‌ها استفاده می‌شود. این اسانس
        به نوشیدنی‌ها طعمی شیرین و طبیعی می‌بخشد و برای تولید انواع نوشیدنی‌های تابستانی یا در فصل‌های گرم
        سال، گزینه‌ای عالی است. همچنین در طعم‌دهی به دسرها، کیک‌ها و شیرینی‌ها نیز کاربرد دارد.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس زردآلو در صنایع لبنی به‌ویژه در تولید بستنی‌ها، ماست‌ها و شیرهای طعم‌دار استفاده می‌شود. طعم
        شیرین و خوشمزه این اسانس می‌تواند به محصولات لبنی طعمی تازه و متفاوت بدهد. در تولید بستنی زردآلو یا
        ماست زردآلو، این اسانس نقش برجسته‌ای دارد.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس زردآلو به‌عنوان یک ماده طبیعی در تولید محصولات آرایشی و بهداشتی نیز کاربرد دارد. این اسانس به
        دلیل خواص آنتی‌اکسیدانی و مرطوب‌کنندگی‌اش، در کرم‌ها و لوسیون‌های صورت و بدن استفاده می‌شود و به
        جوانسازی پوست و حفظ رطوبت آن کمک می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس زردآلو</h2>
      <p>
        اسانس زردآلو دارای خواص بی‌شماری است که علاوه بر طعم‌دهی طبیعی و خوشمزه، برای سلامت بدن و پوست نیز
        مفید است. این اسانس غنی از ویتامین‌ها و مواد مغذی است که به تقویت سیستم ایمنی بدن کمک کرده و
        آنتی‌اکسیدان‌های موجود در آن به کاهش التهاب و بهبود سلامت پوست کمک می‌کنند.
      </p>
      <p>
        اسانس زردآلو همچنین خواص ضدالتهابی دارد که می‌تواند به کاهش التهابات پوستی کمک کند و پوست را نرم و
        لطیف نگه دارد.
      </p>
      <h2>مزایای استفاده از اسانس زردآلو در صنعت</h2>
      <p>
        استفاده از اسانس زردآلو در محصولات مختلف علاوه بر ایجاد طعم و عطر بی‌نظیر، مزایای زیادی دارد.
        این اسانس می‌تواند طعمی شیرین و تازه زردآلو را در محصولات حفظ کرده و به‌عنوان یک ماده طبیعی و سالم
        جایگزین مناسبی برای طعم‌دهنده‌های مصنوعی باشد. همچنین، اسانس زردآلو به تولید محصولات با خواص درمانی
        و بهداشتی کمک کرده و تجربه‌ای لذت‌بخش برای مصرف‌کنندگان فراهم می‌کند.
      </p>
    </div>
  ),
}

,{
  id: 24,
  img: p24,
  title: 'اسانس خرما',
  description: 'اسانس خرما با طعمی شیرین و غنی، یکی از اسانس‌های محبوب در صنایع غذایی، نوشیدنی‌ها، دسرها، محصولات آرایشی و بهداشتی است.',
  metaDescription:
    'اسانس خرما با طعمی شیرین و خوشمزه، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها، و محصولات آرایشی. خرید اسانس خرما با کیفیت بالا، با خواص آنتی‌اکسیدانی و مرطوب‌کنندگی.',
  body: (
    <div class="description-box">
      <p>
        اسانس خرما به عنوان یکی از اسانس‌های پرطرفدار در صنایع مختلف شناخته می‌شود. این اسانس با طعمی شیرین، غنی و خوشمزه، برای انواع محصولات از جمله نوشیدنی‌ها، دسرها، شیرینی‌ها، بستنی‌ها و محصولات آرایشی کاربرد دارد. خرما به عنوان یکی از میوه‌های مغذی و طبیعی با خواص بسیار زیاد در بسیاری از فرهنگ‌ها مورد توجه است و اسانس آن به طور ویژه برای تقویت طعم محصولات غذایی و بهبود خواص درمانی استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس خرما به دلیل طعم شیرین و طبیعی خود، در تولید انواع نوشیدنی‌ها، شربت‌ها، اسموتی‌ها و دسرها کاربرد زیادی دارد. این اسانس طعمی دلپذیر و طبیعی به نوشیدنی‌ها می‌دهد و می‌تواند به عنوان طعم‌دهنده اصلی در نوشیدنی‌های تابستانی، اسموتی‌ها یا شربت‌ها استفاده شود. همچنین در تولید دسرهایی مانند پودینگ‌ها، کیک‌ها و شیرینی‌ها نیز این اسانس محبوب است و به محصولات طعمی گرم و شیرین می‌بخشد.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس خرما در صنایع لبنی به ویژه در تولید بستنی‌ها، ماست‌ها، شیرهای طعم‌دار و پنیرها استفاده می‌شود. طعم شیرین و غنی این اسانس می‌تواند به محصولات لبنی طعمی تازه و متفاوت بدهد. در تولید بستنی خرما یا ماست خرما، این اسانس به‌طور ویژه مورد استفاده قرار می‌گیرد تا طعم دلپذیری به این محصولات داده شود. از آنجا که خرما به طور طبیعی شیرین است، این اسانس می‌تواند جایگزین شکر یا شیرین‌کننده‌های مصنوعی در محصولات لبنی شود.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس خرما به دلیل خواص طبیعی و مرطوب‌کنندگی خود در صنعت آرایشی و بهداشتی نیز کاربرد دارد. این اسانس می‌تواند به جوانسازی پوست و بهبود وضعیت آن کمک کند. در کرم‌ها و لوسیون‌های صورت و بدن، اسانس خرما به عنوان یک عامل مرطوب‌کننده طبیعی عمل می‌کند و پوست را نرم و لطیف نگه می‌دارد. همچنین، خرما دارای خواص آنتی‌اکسیدانی است که به مقابله با اثرات مضر رادیکال‌های آزاد در پوست کمک می‌کند و می‌تواند به کاهش چین و چروک و بهبود سلامت پوست کمک کند.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس خرما</h2>
      <p>
        اسانس خرما علاوه بر طعم‌دهی طبیعی و خوشمزه، خواص بی‌شماری دارد که برای سلامتی بدن و پوست مفید است. خرما غنی از ویتامین‌ها و مواد مغذی مانند ویتامین‌های A، C و E، پتاسیم، کلسیم و آهن است که می‌توانند به تقویت سیستم ایمنی بدن، کاهش التهابات و تقویت انرژی کمک کنند. علاوه بر این، اسانس خرما دارای خواص آنتی‌اکسیدانی است که به مقابله با پیری زودرس و بیماری‌های ناشی از استرس اکسیداتیو کمک می‌کند. این خواص آنتی‌اکسیدانی به محافظت از پوست در برابر آسیب‌های محیطی و افزایش شادابی پوست کمک می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس خرما در صنعت</h2>
      <p>
        استفاده از اسانس خرما در صنایع مختلف علاوه بر طعم‌دهی بی‌نظیر، مزایای زیادی برای سلامت دارد. این اسانس می‌تواند به عنوان یک طعم‌دهنده طبیعی و سالم در بسیاری از محصولات مورد استفاده قرار گیرد و به جای استفاده از طعم‌دهنده‌های مصنوعی، طعم و عطر طبیعی خرما را به آن‌ها اضافه کند. علاوه بر این، اسانس خرما می‌تواند خواص درمانی بسیاری مانند تقویت سیستم ایمنی، بهبود وضعیت پوست، ضدالتهاب و بهبود هضم را به محصولات غذایی و آرایشی منتقل کند.
      </p>
      <h2>مزایای استفاده از اسانس خرما در تولید محصولات آرایشی</h2>
      <p>
        اسانس خرما در تولید محصولات آرایشی و بهداشتی به عنوان یک عامل مرطوب‌کننده، آنتی‌اکسیدان و ضدپیری شناخته می‌شود. این اسانس می‌تواند به ترمیم و بازسازی پوست کمک کند، خشکی و التهاب پوست را کاهش دهد و به شادابی و نرمی پوست کمک کند. استفاده از اسانس خرما در محصولات ضدچروک و ضدپیری می‌تواند به کاهش خطوط ریز و چین و چروک‌های صورت کمک کند.
      </p>
    </div>
  ),
}
,
{
  id: 25,
  img: p25,
  title: 'اسانس ریحان',
  description: 'اسانس ریحان با طعمی تازه و معطر، مناسب برای صنایع غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی است. دارای خواص آنتی‌اکسیدانی و ضدباکتریایی.',
  metaDescription:
    'اسانس ریحان با طعمی تازه و خوشبو، مناسب برای طعم‌دهی به نوشیدنی‌ها، دسرها و محصولات آرایشی. خرید اسانس ریحان با کیفیت بالا، با خواص آنتی‌اکسیدانی و ضدباکتریایی.',
  body: (
    <div class="description-box">
      <p>
        اسانس ریحان یکی از اسانس‌های طبیعی و محبوب در صنایع مختلف است. این اسانس از برگ‌های تازه ریحان استخراج می‌شود و دارای طعمی تازه، معطر و با طراوت است که به محصولات مختلف طعم و عطر منحصر به فردی می‌بخشد. ریحان نه تنها در آشپزی و صنایع غذایی کاربرد دارد، بلکه در محصولات آرایشی و بهداشتی نیز به دلیل خواص بی‌نظیر خود مورد استفاده قرار می‌گیرد.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس ریحان در صنایع غذایی و نوشیدنی به عنوان یک طعم‌دهنده طبیعی و معطر استفاده می‌شود. این اسانس می‌تواند طعمی تازه و معطر به انواع دسرها، سالادها، سس‌ها، نوشیدنی‌ها و اسموتی‌ها اضافه کند. در تولید نوشیدنی‌های سرد مانند لیمونادها و اسموتی‌ها، اسانس ریحان به‌عنوان یک طعم‌دهنده طبیعی و تازه شناخته می‌شود. همچنین در برخی از دسرها و غذاهای دریایی نیز از این اسانس برای طعم‌دهی استفاده می‌شود.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس ریحان در صنایع لبنی، به ویژه در تولید محصولات خاص مانند ماست‌های طعم‌دار، بستنی‌ها و شیرهای طعم‌دار، کاربرد دارد. این اسانس می‌تواند به محصولات لبنی طعمی تازه و منحصر به فرد بدهد. در تولید بستنی ریحان یا ماست طعم‌دار، این اسانس به‌ویژه به‌کار می‌رود تا طعمی جدید و خوشمزه به این محصولات اضافه کند.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس ریحان در صنعت آرایشی و بهداشتی به دلیل خواص آنتی‌اکسیدانی، ضدباکتریایی و ضدالتهابی‌اش مورد توجه قرار گرفته است. این اسانس می‌تواند در تولید کرم‌ها، لوسیون‌ها، شامپوها و محصولات مراقبت از پوست استفاده شود. ریحان به‌عنوان یک ماده طبیعی، به بهبود وضعیت پوست و مو کمک می‌کند و به عنوان یک ضدعفونی‌کننده و ضدباکتریایی در محصولات بهداشتی و آرایشی کاربرد دارد.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس ریحان</h2>
      <p>
        اسانس ریحان دارای خواص بی‌شماری است که علاوه بر طعم‌دهی طبیعی، برای سلامت بدن و پوست نیز مفید است. این اسانس غنی از آنتی‌اکسیدان‌ها است که به محافظت از بدن در برابر آسیب‌های محیطی کمک می‌کند و به کاهش التهاب و بهبود وضعیت پوست و مو می‌انجامد. اسانس ریحان دارای خواص ضدباکتریایی و ضدقارچی است که می‌تواند به مبارزه با میکروب‌ها و آلودگی‌ها کمک کند.
      </p>
      <h2>مزایای استفاده از اسانس ریحان در صنعت</h2>
      <p>
        استفاده از اسانس ریحان در محصولات مختلف علاوه بر ایجاد طعم و عطر دلپذیر، مزایای بسیاری دارد. این اسانس می‌تواند جایگزینی طبیعی و سالم برای طعم‌دهنده‌های مصنوعی باشد و به ایجاد تجربه‌ای منحصر به فرد در مصرف‌کنندگان کمک کند. همچنین، با توجه به خواص آنتی‌اکسیدانی و ضدباکتریایی‌اش، استفاده از اسانس ریحان می‌تواند به تولید محصولات بهداشتی و آرایشی با خواص درمانی و مراقبتی کمک کند.
      </p>
      <h2>مزایای استفاده از اسانس ریحان در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس ریحان به دلیل خواص ضدباکتریایی و ضدالتهابی‌اش در محصولات آرایشی و بهداشتی کاربرد دارد. این اسانس می‌تواند به مبارزه با آکنه، جوش‌ها و التهاب‌های پوستی کمک کند و به افزایش شادابی و سلامت پوست و مو کمک نماید. اسانس ریحان همچنین به عنوان یک ضدعفونی‌کننده طبیعی برای پوست شناخته می‌شود و به درمان و جلوگیری از عفونت‌های پوستی کمک می‌کند.
      </p>
      <h2>نحوه استفاده از اسانس ریحان</h2>
      <p>
        اسانس ریحان به راحتی می‌تواند در آشپزی، تولید نوشیدنی‌ها، دسرها و محصولات آرایشی استفاده شود. برای استفاده در آشپزی، کافیست چند قطره از اسانس ریحان را به سس‌ها، سالادها یا نوشیدنی‌های خود اضافه کنید. در محصولات آرایشی، این اسانس را می‌توان به کرم‌ها، لوسیون‌ها و شامپوها اضافه کرد. همچنین در برخی از روغن‌های ماساژ، اسانس ریحان می‌تواند به تسکین دردهای عضلانی و تنش‌های بدنی کمک کند.
      </p>
    </div>
  ),
}
,
{
  id: 27,
  img: p27,
  title: 'اسانس نعناع',
  description: 'اسانس نعناع با طعمی خنک و معطر، مناسب برای صنایع غذایی، نوشیدنی‌ها، دسرها، محصولات آرایشی و بهداشتی است. دارای خواص تسکین‌دهنده و آرامش‌بخش.',
  metaDescription:
    'اسانس نعناع با طعمی خنک و خوشبو، مناسب برای طعم‌دهی به نوشیدنی‌ها، دسرها و محصولات آرایشی. خرید اسانس نعناع با خواص تسکین‌دهنده و آرامش‌بخش.',
  body: (
    <div class="description-box">
      <p>
        اسانس نعناع یکی از اسانس‌های پرطرفدار و پرکاربرد در صنایع مختلف است. این اسانس از برگ‌های تازه نعناع استخراج می‌شود و دارای طعمی خنک، معطر و تازه است. اسانس نعناع نه تنها در صنایع غذایی و نوشیدنی مورد استفاده قرار می‌گیرد، بلکه به دلیل خواص آرامش‌بخش و تسکین‌دهنده‌اش در محصولات آرایشی و بهداشتی نیز کاربرد دارد. این اسانس باعث افزایش شادابی و طراوت در محصولات مختلف می‌شود.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس نعناع به عنوان یک طعم‌دهنده طبیعی در صنایع غذایی و نوشیدنی بسیار محبوب است. این اسانس به‌ویژه در تهیه نوشیدنی‌های خنک و تابستانی مانند لیموناد، اسموتی‌ها و نوشیدنی‌های گازدار کاربرد دارد. همچنین، اسانس نعناع در دسرها، شکلات‌ها، بستنی‌ها و شیرینی‌ها نیز برای افزودن طعمی تازه و معطر استفاده می‌شود. طعم خنک و تند نعناع به این محصولات طعمی منحصر به فرد و جذاب می‌بخشد.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس نعناع در صنایع لبنی نیز کاربرد دارد. در تولید محصولات لبنی مانند بستنی‌ها، ماست‌ها و نوشیدنی‌های لبنی طعم‌دهنده‌ای برای افزایش طعم و عطر استفاده می‌شود. اسانس نعناع می‌تواند به بستنی‌های نعناع یا نوشیدنی‌های لبنی طعمی خوشمزه و خنک بدهد که برای مصرف‌کنندگان لذت‌بخش باشد.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس نعناع به دلیل خواص آرامش‌بخش و تسکین‌دهنده‌ای که دارد، در محصولات آرایشی و بهداشتی استفاده می‌شود. این اسانس در کرم‌ها، لوسیون‌ها، شامپوها و محصولات مراقبت از پوست کاربرد دارد. اسانس نعناع به تسکین پوست‌های حساس و تحریک‌شده کمک کرده و باعث ایجاد احساس خنکی و طراوت می‌شود. همچنین، این اسانس برای درمان خستگی و بهبود جریان خون در پوست مفید است.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس نعناع</h2>
      <p>
        اسانس نعناع دارای خواص بسیاری است که آن را به یک ماده محبوب در صنایع مختلف تبدیل کرده است. این اسانس به دلیل خواص آنتی‌اکسیدانی، ضدباکتریایی و ضدالتهابی‌اش شناخته می‌شود. اسانس نعناع به تسکین دردهای عضلانی و سردردها کمک کرده و برای درمان مشکلات گوارشی مانند نفخ و سوء هاضمه مفید است. این اسانس همچنین باعث تحریک گردش خون و افزایش انرژی می‌شود.
      </p>
      <h2>مزایای استفاده از اسانس نعناع در صنعت</h2>
      <p>
        استفاده از اسانس نعناع در محصولات مختلف، مزایای زیادی دارد. این اسانس می‌تواند به‌عنوان یک طعم‌دهنده طبیعی جایگزین مناسبی برای طعم‌دهنده‌های مصنوعی باشد و طعمی خنک و تازه به محصولات اضافه کند. در صنایع آرایشی و بهداشتی، اسانس نعناع به دلیل خواص تسکین‌دهنده‌اش در محصولات مراقبت از پوست و مو به‌ویژه برای افرادی که پوست‌های حساس دارند، مفید است. این اسانس همچنین می‌تواند به درمان مشکلات گوارشی کمک کند و احساس طراوت و شادابی را ایجاد نماید.
      </p>
      <h2>مزایای استفاده از اسانس نعناع در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس نعناع به دلیل خواص ضدباکتریایی و ضدالتهابی‌اش در محصولات آرایشی و بهداشتی بسیار مفید است. این اسانس به بهبود وضعیت پوست کمک کرده و به‌ویژه برای پوست‌های چرب و حساس مفید است. اسانس نعناع همچنین به‌عنوان یک ضدعفونی‌کننده طبیعی در کرم‌ها، لوسیون‌ها و شامپوها استفاده می‌شود و به رفع التهاب و سوزش پوست کمک می‌کند. این اسانس می‌تواند پوست را تازه و شاداب نگه دارد.
      </p>
      <h2>نحوه استفاده از اسانس نعناع</h2>
      <p>
        اسانس نعناع به‌راحتی در انواع محصولات غذایی و نوشیدنی‌ها و همچنین در محصولات آرایشی و بهداشتی مورد استفاده قرار می‌گیرد. برای استفاده در آشپزی، کافیست چند قطره از اسانس نعناع را به سس‌ها، نوشیدنی‌ها یا دسرها اضافه کنید. در محصولات آرایشی، اسانس نعناع را می‌توان به کرم‌ها، لوسیون‌ها یا شامپوها اضافه کرد تا احساس خنکی و تازگی را به پوست و مو منتقل کند. همچنین، اسانس نعناع می‌تواند به عنوان یک روغن ماساژ برای تسکین دردهای عضلانی و بهبود گردش خون استفاده شود.
      </p>
    </div>
  ),
}
,
{
  id: 28,
  img: p28,
  title: 'اسانس پسته',
  description: 'اسانس پسته با طعمی منحصر به فرد و خوشمزه، مناسب برای صنایع غذایی، شیرینی‌پزی، دسرها و محصولات آرایشی است. دارای خواص مغذی و درمانی.',
  metaDescription:
    'اسانس پسته با طعمی خوشمزه و مغذی، مناسب برای طعم‌دهی به محصولات غذایی، دسرها و محصولات آرایشی. خرید اسانس پسته با خواص درمانی و مغذی.',
  body: (
    <div class="description-box">
      <p>
        اسانس پسته یکی از اسانس‌های پرطرفدار و خوشمزه در صنایع مختلف است. این اسانس از مغز پسته تازه استخراج می‌شود و طعمی منحصر به فرد و دلچسب به محصولات می‌بخشد. اسانس پسته نه تنها در صنایع غذایی برای طعم‌دهی به محصولات مختلف استفاده می‌شود، بلکه در صنایع آرایشی و بهداشتی نیز به دلیل خواص مغذی و درمانی که دارد، کاربرد دارد. این اسانس به‌ویژه در دسرها، شیرینی‌پزی‌ها و نوشیدنی‌ها برای افزودن طعمی تازه و خوشمزه به‌کار می‌رود.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس پسته در صنایع غذایی و نوشیدنی به‌ویژه در تولید دسرها، کیک‌ها، شیرینی‌ها و نوشیدنی‌ها استفاده می‌شود. طعم خوشمزه و خاص پسته، به این محصولات طعمی دلپذیر و منحصر به فرد می‌بخشد. این اسانس می‌تواند به نوشیدنی‌های گرم و سرد، به ویژه در تولید نوشیدنی‌های پسته‌ای یا شیر پسته، طعمی غنی و لذت‌بخش اضافه کند. همچنین، در کیک‌ها و شیرینی‌های مختلف از جمله کیک پسته، باسلوق پسته و سایر محصولات شیرینی‌پزی، برای افزودن طعم طبیعی و خوشمزه به کار می‌رود.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس پسته در صنایع لبنی نیز کاربرد دارد. این اسانس به‌ویژه در تولید بستنی‌ها، ماست‌ها و نوشیدنی‌های لبنی طعمی منحصر به فرد و خوشمزه به‌وجود می‌آورد. در بستنی پسته، این اسانس باعث ایجاد طعم غنی و خوشمزه‌ای می‌شود که طرفداران بسیاری دارد. همچنین، در تولید شیرهای طعم‌دار پسته‌ای، از این اسانس برای افزودن طعم طبیعی و تازه پسته استفاده می‌شود.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس پسته به‌عنوان یک ماده مغذی و مرطوب‌کننده در تولید محصولات آرایشی و بهداشتی نیز کاربرد دارد. این اسانس به دلیل خواص آنتی‌اکسیدانی و مرطوب‌کنندگی‌اش در کرم‌ها و لوسیون‌های صورت و بدن استفاده می‌شود. اسانس پسته به حفظ رطوبت پوست کمک کرده و آن را نرم و لطیف نگه می‌دارد. همچنین، این اسانس برای درمان مشکلات پوستی مانند خشکی و التهاب مفید است و به جوانسازی پوست کمک می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس پسته</h2>
      <p>
        اسانس پسته دارای خواص مغذی و درمانی فراوانی است. این اسانس سرشار از ویتامین‌ها و مواد مغذی است که به تقویت سیستم ایمنی بدن کمک کرده و برای سلامت پوست و مو مفید است. پسته به‌طور طبیعی دارای آنتی‌اکسیدان‌هایی است که می‌توانند به کاهش التهاب و آسیب‌های ناشی از رادیکال‌های آزاد کمک کنند. همچنین، اسانس پسته به دلیل خواص مرطوب‌کنندگی‌اش به حفظ رطوبت پوست و نرم نگه داشتن آن کمک می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس پسته در صنعت</h2>
      <p>
        استفاده از اسانس پسته در صنعت، مزایای زیادی دارد. این اسانس به‌عنوان یک طعم‌دهنده طبیعی و مغذی، جایگزین مناسبی برای طعم‌دهنده‌های مصنوعی است و طعمی طبیعی و خوشمزه به محصولات می‌بخشد. در صنایع آرایشی و بهداشتی، اسانس پسته به دلیل خواص آنتی‌اکسیدانی و مرطوب‌کنندگی‌اش در محصولات مراقبت از پوست و مو به کار می‌رود و به حفظ رطوبت و بهبود وضعیت پوست کمک می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس پسته در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس پسته به دلیل خواص آنتی‌اکسیدانی و ضدالتهابی‌اش در محصولات آرایشی و بهداشتی کاربرد فراوانی دارد. این اسانس می‌تواند به ترمیم و جوانسازی پوست کمک کرده و از پیری زودرس پوست جلوگیری کند. همچنین، اسانس پسته باعث حفظ رطوبت پوست و جلوگیری از خشکی آن می‌شود. این اسانس برای پوست‌های خشک و حساس بسیار مفید است و می‌تواند به نرم و لطیف شدن پوست کمک کند.
      </p>
      <h2>نحوه استفاده از اسانس پسته</h2>
      <p>
        اسانس پسته به‌راحتی در انواع محصولات غذایی، نوشیدنی‌ها و محصولات آرایشی و بهداشتی استفاده می‌شود. برای استفاده در آشپزی، می‌توان چند قطره از اسانس پسته را به سس‌ها، نوشیدنی‌ها یا دسرها اضافه کرد. در محصولات آرایشی، این اسانس می‌تواند به کرم‌ها، لوسیون‌ها یا شامپوها اضافه شود تا خواص مرطوب‌کنندگی و جوانسازی آن به پوست منتقل شود. همچنین، اسانس پسته به‌عنوان یک روغن ماساژ برای پوست‌های خشک و آسیب‌دیده می‌تواند مورد استفاده قرار گیرد.
      </p>
    </div>
  ),
}
,
{
  id: 29,
  img: p29,
  title: 'اسانس شیر کارامل',
  description: 'اسانس شیر کارامل با طعمی شیرین، کرمی و غنی، مناسب برای صنایع غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی است. طعمی ملایم و دلپذیر.',
  metaDescription:
    'اسانس شیر کارامل با طعمی شیرین و کرمی، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی. خرید اسانس شیر کارامل با طعم بی‌نظیر و کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس شیر کارامل یکی از اسانس‌های محبوب در صنایع مختلف است که طعمی شیرین، کرمی و غنی به محصولات می‌بخشد. این اسانس معمولاً از ترکیب شیر، شکر و طعم‌های کارامل استخراج می‌شود و در بسیاری از صنایع غذایی، نوشیدنی‌ها، دسرها و حتی محصولات آرایشی به‌کار می‌رود. طعم دلپذیر و ملایم این اسانس باعث شده که در بسیاری از محصولات محبوب باشد.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس شیر کارامل در صنایع غذایی به‌ویژه در تولید نوشیدنی‌ها، دسرها، کیک‌ها و شیرینی‌ها استفاده می‌شود. این اسانس با طعمی غنی و شیرین، به نوشیدنی‌ها طعمی ملایم و دلپذیر می‌بخشد. در تولید نوشیدنی‌های شیرین و دسرهای مختلف از جمله پودینگ‌ها، کرم‌ها، تارت‌ها و سایر دسرها، از این اسانس استفاده می‌شود. همچنین، اسانس شیر کارامل در تهیه کیک‌ها و شیرینی‌های مختلف، از جمله کیک کارامل و شیرینی‌های خامه‌ای کاربرد دارد.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس شیر کارامل در صنایع لبنی نیز کاربرد فراوانی دارد. این اسانس در تولید بستنی‌ها، ماست‌ها و نوشیدنی‌های لبنی استفاده می‌شود. در بستنی‌های کارامل و ماست‌های طعم‌دار کارامل، اسانس شیر کارامل به‌کار می‌رود تا طعمی شیرین و غنی به این محصولات ببخشد. همچنین، در تولید شیرهای طعم‌دار و نوشیدنی‌های سرد و گرم نیز از اسانس شیر کارامل برای افزودن طعمی دلپذیر استفاده می‌شود.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس شیر کارامل علاوه بر کاربرد در صنایع غذایی، در محصولات آرایشی و بهداشتی نیز مورد استفاده قرار می‌گیرد. این اسانس به دلیل خواص مرطوب‌کنندگی و رایحه دلپذیرش، در تولید کرم‌ها، لوسیون‌ها و محصولات مراقبت از پوست به‌کار می‌رود. شیر و کارامل هر دو خواص تغذیه‌ای دارند که به نرم شدن و جوانسازی پوست کمک می‌کنند. اسانس شیر کارامل به‌طور ویژه برای پوست‌های خشک و حساس مفید است و به پوست نرمی و لطافت می‌بخشد.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس شیر کارامل</h2>
      <p>
        اسانس شیر کارامل دارای ویژگی‌های منحصر به فردی است که علاوه بر طعم‌دهی خوشمزه، برای سلامت پوست نیز مفید است. این اسانس غنی از مواد مغذی است که می‌توانند به تقویت و نرم شدن پوست کمک کنند. همچنین، طعم ملایم و شیرین آن باعث محبوبیت این اسانس در صنایع مختلف غذایی و بهداشتی شده است. اسانس شیر کارامل به دلیل رایحه خوشایند خود، در ایجاد تجربه‌ای لذت‌بخش برای مصرف‌کنندگان در محصولات غذایی و آرایشی موثر است.
      </p>
      <h2>مزایای استفاده از اسانس شیر کارامل در صنعت</h2>
      <p>
        استفاده از اسانس شیر کارامل در محصولات مختلف علاوه بر طعم‌دهی خوشمزه، مزایای زیادی دارد. این اسانس می‌تواند به‌عنوان یک طعم‌دهنده طبیعی و سالم در محصولات غذایی استفاده شود و جایگزین مناسبی برای طعم‌دهنده‌های مصنوعی باشد. همچنین، اسانس شیر کارامل در تولید محصولات با خواص مرطوب‌کنندگی و تغذیه‌ای کمک کرده و تجربه‌ای لذت‌بخش و منحصر به فرد برای مصرف‌کنندگان فراهم می‌آورد.
      </p>
      <h2>مزایای استفاده از اسانس شیر کارامل در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس شیر کارامل در محصولات آرایشی و بهداشتی به دلیل خواص مرطوب‌کنندگی و تغذیه‌ای‌اش، در تولید کرم‌ها و لوسیون‌ها برای پوست‌های خشک و حساس استفاده می‌شود. این اسانس می‌تواند به حفظ رطوبت پوست کمک کرده و آن را نرم و لطیف نگه دارد. همچنین، استفاده از اسانس شیر کارامل در لوسیون‌ها و کرم‌ها، به پوست رایحه‌ای دلپذیر و خوشبو می‌بخشد که برای بسیاری از افراد جذاب است.
      </p>
      <h2>نحوه استفاده از اسانس شیر کارامل</h2>
      <p>
        اسانس شیر کارامل به‌راحتی در انواع محصولات غذایی و آرایشی مورد استفاده قرار می‌گیرد. در آشپزی، می‌توان چند قطره از اسانس شیر کارامل را به دسرها، نوشیدنی‌ها یا کیک‌ها اضافه کرد تا طعمی شیرین و کرمی به آن‌ها بدهد. در محصولات آرایشی و بهداشتی، این اسانس به‌عنوان یک ماده مرطوب‌کننده و تغذیه‌کننده برای پوست استفاده می‌شود. برای استفاده در کرم‌ها، لوسیون‌ها یا شامپوها، چند قطره از این اسانس می‌تواند به محصولات اضافه شود تا خواص مرطوب‌کنندگی و تغذیه‌ای آن را تقویت کند.
      </p>
    </div>
  ),
}
,
{
  id: 30,
  img: p30,
  title: 'اسانس استوایی',
  description: 'اسانس استوایی با طعمی شیرین و خوشمزه، مناسب برای صنایع غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی است. ترکیبی از میوه‌های استوایی با رایحه‌ای ملایم و دلچسب.',
  metaDescription:
    'اسانس استوایی با طعمی شیرین و خوشمزه، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی. خرید اسانس استوایی با طعم‌های طبیعی و خوشبو.',
  body: (
    <div class="description-box">
      <p>
        اسانس استوایی یکی از محبوب‌ترین اسانس‌ها در صنایع مختلف است. این اسانس معمولاً از ترکیب چندین میوه استوایی همچون انبه، نارگیل، آناناس، موز، پاپایا و غیره ساخته می‌شود و طعمی شیرین و خوشمزه به محصولات می‌بخشد. رایحه این اسانس، طعمی تازه و طبیعی را به دسرها، نوشیدنی‌ها، کیک‌ها و دیگر محصولات غذایی اضافه می‌کند. علاوه بر این، اسانس استوایی در محصولات آرایشی نیز به‌کار می‌رود و به پوست طراوت و شادابی می‌بخشد.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس استوایی در صنایع غذایی به‌ویژه در تولید نوشیدنی‌ها، دسرها، کیک‌ها و شیرینی‌ها استفاده می‌شود. طعم شیرین و خوشمزه این اسانس، به نوشیدنی‌ها طعمی تازه و میوه‌ای می‌دهد و به عنوان یک گزینه عالی برای تولید نوشیدنی‌های تابستانی به شمار می‌آید. در تولید انواع دسرها مانند پودینگ، کرم‌ها و ژله‌ها نیز از اسانس استوایی استفاده می‌شود. این اسانس می‌تواند طعمی طبیعی و میوه‌ای به این دسرها بدهد و تجربه‌ای دلپذیر برای مصرف‌کنندگان فراهم کند.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس استوایی در صنایع لبنی نیز به‌طور گسترده‌ای استفاده می‌شود. این اسانس در تولید بستنی‌های میوه‌ای، ماست‌ها و شیرهای طعم‌دار کاربرد دارد. در بستنی‌های استوایی مانند بستنی انبه، آناناس یا پاپایا، اسانس استوایی طعمی طبیعی و شیرین به این محصولات می‌بخشد. همچنین در تولید ماست‌های طعم‌دار مانند ماست انبه یا ماست نارگیل، از اسانس استوایی برای ایجاد طعمی جذاب و خوشمزه استفاده می‌شود.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس استوایی علاوه بر کاربردهای غذایی، در صنعت آرایشی و بهداشتی نیز محبوبیت زیادی دارد. این اسانس به دلیل خواص مرطوب‌کنندگی و تغذیه‌ای میوه‌های استوایی، در تولید لوسیون‌ها، کرم‌ها و روغن‌های بدن استفاده می‌شود. این اسانس می‌تواند به پوست طراوت و شادابی ببخشد و در مبارزه با خشکی پوست بسیار موثر باشد. رایحه شیرین و میوه‌ای آن باعث ایجاد حس تازگی و نشاط در مصرف‌کنندگان می‌شود و در بسیاری از محصولات مراقبتی پوست استفاده می‌شود.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس استوایی</h2>
      <p>
        اسانس استوایی علاوه بر طعم‌دهی خوشمزه و خوشبو، دارای خواص مفیدی نیز برای بدن و پوست است. این اسانس غنی از ویتامین‌ها و مواد مغذی موجود در میوه‌های استوایی است که برای سلامت بدن بسیار مفید است. میوه‌هایی مانند انبه، نارگیل و آناناس، غنی از آنتی‌اکسیدان‌ها و ویتامین C هستند که به تقویت سیستم ایمنی بدن کمک می‌کنند. علاوه بر این، اسانس استوایی خواص مرطوب‌کنندگی داشته و می‌تواند پوست را نرم و لطیف نگه دارد.
      </p>
      <p>
        یکی دیگر از ویژگی‌های اسانس استوایی، خواص ضدالتهابی آن است که به کاهش التهاب‌های پوستی و رفع قرمزی و خشکی پوست کمک می‌کند. این اسانس می‌تواند در درمان پوست‌های حساس و خشک موثر باشد و پوست را از رادیکال‌های آزاد محافظت کند. همچنین، اسانس استوایی باعث ایجاد حس راحتی و آرامش در مصرف‌کننده می‌شود و به‌ویژه در محصولات مراقبتی پوست برای ایجاد حس خوب و شادابی در بدن استفاده می‌شود.
      </p>
      <h2>مزایای استفاده از اسانس استوایی در صنعت</h2>
      <p>
        استفاده از اسانس استوایی در محصولات غذایی و آرایشی می‌تواند مزایای زیادی داشته باشد. این اسانس طعمی طبیعی و شیرین به محصولات می‌بخشد و جایگزین مناسبی برای طعم‌دهنده‌های مصنوعی است. همچنین، رایحه استوایی این اسانس باعث ایجاد حس تازگی و نشاط می‌شود و مصرف‌کنندگان را جذب می‌کند. در محصولات آرایشی، اسانس استوایی به دلیل خواص مرطوب‌کنندگی و تغذیه‌ای‌اش، می‌تواند به نرم شدن پوست و حفظ رطوبت آن کمک کند.
      </p>
      <h2>نحوه استفاده از اسانس استوایی</h2>
      <p>
        اسانس استوایی در محصولات مختلف به‌راحتی قابل استفاده است. در آشپزی، چند قطره از این اسانس می‌تواند به نوشیدنی‌ها، دسرها، کیک‌ها یا بستنی‌ها اضافه شود تا طعمی دلپذیر و میوه‌ای به آن‌ها ببخشد. در صنعت آرایشی، اسانس استوایی به‌عنوان یک ماده اصلی در تولید کرم‌ها و لوسیون‌ها استفاده می‌شود. می‌توانید این اسانس را به محصولات مراقبتی پوست یا مو اضافه کنید تا خواص مرطوب‌کنندگی و تغذیه‌ای آن را تقویت کنید.
      </p>
      <h2>کاربردهای دیگر اسانس استوایی</h2>
      <p>
        علاوه بر صنایع غذایی و آرایشی، اسانس استوایی در سایر صنایع نیز کاربرد دارد. این اسانس در صنعت شمع‌سازی برای ایجاد رایحه‌ای خوشبو و ماندگار استفاده می‌شود. در تولید عطرها و اسپری‌های بدن نیز اسانس استوایی می‌تواند رایحه‌ای ملایم و دلپذیر به محصولات بدهد و تجربه‌ای خوشایند و شاداب را برای مصرف‌کننده فراهم کند. این اسانس همچنین در تولید صابون‌ها و شامپوها نیز کاربرد دارد.
      </p>
    </div>
  ),
}
,
{
  id: 31,
  img: p31,
  title: 'اسانس نارنج',
  description: 'اسانس نارنج با طعم و رایحه‌ای تازه و خوشمزه، مناسب برای محصولات غذایی، نوشیدنی‌ها، لوازم آرایشی و بهداشتی است. این اسانس علاوه بر طعم‌دهی، خواص درمانی نیز دارد.',
  metaDescription:
    'اسانس نارنج با طعمی خوشمزه و رایحه‌ای تازه، مناسب برای محصولات غذایی، دسرها، نوشیدنی‌ها، لوازم آرایشی و بهداشتی. خرید اسانس نارنج با خواص درمانی و خوشبو.',
  body: (
    <div class="description-box">
      <p>
        اسانس نارنج، یکی از اسانس‌های محبوب در دنیای رایحه‌هاست که به‌ویژه به دلیل طعم و رایحه‌ی تازه، میوه‌ای و خوشمزه‌اش در صنایع غذایی و آرایشی بسیار مورد استفاده قرار می‌گیرد. این اسانس از پوست نارنج استخراج می‌شود و خواص بسیاری دارد که آن را به یک گزینه عالی برای استفاده در محصولات مختلف تبدیل می‌کند. رایحه این اسانس می‌تواند حس طراوت و نشاط را به مصرف‌کننده منتقل کند و باعث ایجاد تجربه‌ای دلپذیر در هنگام استفاده از محصولات حاوی این اسانس شود.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس نارنج در صنایع غذایی یکی از محبوب‌ترین طعم‌دهنده‌هاست. این اسانس به دلیل طعم شیرین و تازه‌اش، در تولید انواع نوشیدنی‌ها، دسرها، کیک‌ها، پودینگ‌ها و حتی شکلات‌ها کاربرد دارد. در تولید نوشیدنی‌های مرکباتی، از جمله آبمیوه‌ها، لیمونادها و اسموتی‌ها، اسانس نارنج به‌عنوان طعم‌دهنده اصلی استفاده می‌شود. این اسانس علاوه بر طعم‌دهی، به این نوشیدنی‌ها رایحه‌ای خوشبو و تازه می‌بخشد که باعث جذب بیشتر مصرف‌کنندگان می‌شود.
      </p>
      <p>
        در تولید دسرها و شیرینی‌ها نیز اسانس نارنج به‌عنوان یکی از ترکیبات اصلی به‌کار می‌رود. این اسانس می‌تواند طعم طبیعی و خوشمزه‌ای به پودینگ‌ها، ژله‌ها و حتی کیک‌ها و شیرینی‌ها بدهد. اسانس نارنج همچنین در تولید مرباها و ژله‌های مرکباتی نیز استفاده می‌شود و به این محصولات طعمی شیرین و خوشبو می‌بخشد.
      </p>
      <h2>کاربرد در نوشیدنی‌ها</h2>
      <p>
        اسانس نارنج به‌ویژه در تولید نوشیدنی‌های میوه‌ای مانند آبمیوه‌ها، لیمونادها و اسموتی‌ها محبوب است. طعم شیرین و تازه این اسانس به‌راحتی می‌تواند طعم‌هایی مانند پرتقال و لیمو را تکمیل کند و تجربه‌ای بی‌نظیر برای مصرف‌کننده ایجاد نماید. این اسانس همچنین در تولید نوشیدنی‌های گازدار، نوشابه‌های انرژی‌زا و حتی در طعم‌دهی به آب‌معدنی‌های طعم‌دار نیز استفاده می‌شود.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس نارنج به دلیل خواص طبیعی و مفیدی که دارد، در صنعت آرایشی و بهداشتی نیز مورد استفاده قرار می‌گیرد. این اسانس در تولید محصولات مراقبتی پوست مانند کرم‌ها، لوسیون‌ها، صابون‌ها و ژل‌های شستشو استفاده می‌شود. اسانس نارنج به دلیل خاصیت آنتی‌اکسیدانی که دارد، می‌تواند به حفظ جوانی و شادابی پوست کمک کند و از پیری زودرس جلوگیری کند.
      </p>
      <p>
        علاوه بر این، اسانس نارنج به‌عنوان یک مرطوب‌کننده طبیعی برای پوست‌های خشک و حساس عمل می‌کند. این اسانس می‌تواند پوست را نرم و لطیف کند و به طراوت و شادابی آن کمک نماید. در بسیاری از محصولات آرایشی، اسانس نارنج برای ایجاد حس تازگی و نشاط در مصرف‌کننده مورد استفاده قرار می‌گیرد و در عطرها و اسپری‌های بدن نیز به‌عنوان یک ترکیب رایحه‌ای استفاده می‌شود.
      </p>
      <h2>خواص درمانی اسانس نارنج</h2>
      <p>
        اسانس نارنج علاوه بر اینکه یک طعم‌دهنده خوشمزه و خوشبو است، دارای خواص درمانی متعددی نیز می‌باشد. این اسانس به‌طور طبیعی دارای خواص ضدالتهابی و آنتی‌اکسیدانی است که می‌تواند در درمان بسیاری از مشکلات پوستی و جسمی مؤثر باشد. یکی از اصلی‌ترین خواص اسانس نارنج، خاصیت آرام‌بخشی آن است. این اسانس به‌عنوان یک درمان طبیعی برای کاهش استرس و اضطراب شناخته می‌شود و می‌تواند به آرامش ذهن و بدن کمک کند.
      </p>
      <p>
        اسانس نارنج همچنین به‌عنوان یک ضدباکتری و ضدقارچ عمل می‌کند و می‌تواند در مقابله با عفونت‌های پوستی و مشکلات تنفسی مفید باشد. این اسانس به‌ویژه در درمان مشکلات گوارشی نیز کاربرد دارد و می‌تواند به تسکین دردهای معده و مشکلات هضم کمک کند. علاوه بر این، اسانس نارنج به‌عنوان یک محرک طبیعی برای تقویت سیستم ایمنی بدن شناخته می‌شود و می‌تواند به پیشگیری از بیماری‌ها کمک کند.
      </p>
      <h2>نحوه استفاده از اسانس نارنج</h2>
      <p>
        اسانس نارنج به‌طور معمول به‌صورت چند قطره در محصولات مختلف استفاده می‌شود. در صنایع غذایی، این اسانس می‌تواند به نوشیدنی‌ها، دسرها، مرباها و کیک‌ها اضافه شود. در صنایع آرایشی و بهداشتی، اسانس نارنج معمولاً به‌عنوان یکی از ترکیبات در تولید کرم‌ها، لوسیون‌ها، صابون‌ها و سایر محصولات مراقبتی پوست و مو استفاده می‌شود.
      </p>
      <p>
        در درمان‌های خانگی، می‌توانید از اسانس نارنج برای ماساژ پوست استفاده کنید. چند قطره از اسانس را با یک روغن پایه مانند روغن بادام یا روغن زیتون مخلوط کرده و آن را به‌طور ملایم روی پوست خود ماساژ دهید. این کار می‌تواند به آرامش شما کمک کرده و پوست شما را نرم و مرطوب نگه دارد. همچنین می‌توانید از این اسانس در دستگاه‌های بخور یا به‌عنوان اسپری هوا استفاده کنید تا به فضا رایحه‌ای دلپذیر و نشاط‌آور ببخشید.
      </p>
      <h2>مزایای استفاده از اسانس نارنج</h2>
      <p>
        استفاده از اسانس نارنج می‌تواند مزایای زیادی به همراه داشته باشد. از جمله مزایای این اسانس می‌توان به خاصیت آرام‌بخشی، ضدباکتری، ضدقارچ، مرطوب‌کنندگی و خاصیت آنتی‌اکسیدانی آن اشاره کرد. این اسانس به پوست کمک می‌کند تا از آسیب‌های محیطی محافظت شود و باعث نرم و لطیف شدن آن می‌شود. همچنین، استفاده از اسانس نارنج در دسرها و نوشیدنی‌ها می‌تواند طعمی تازه و خوشمزه به آن‌ها بدهد و مصرف‌کنندگان را جذب کند.
      </p>
    </div>
  ),
}

,
{
  id: 32,
  img: p32,
  title: 'اسانس زنجبیل',
  description: 'اسانس زنجبیل با طعم تند و کمی تلخ خود، مناسب برای محصولات غذایی، نوشیدنی‌ها، لوازم آرایشی و بهداشتی است. این اسانس علاوه بر طعم‌دهی، خواص درمانی و سلامتی نیز دارد.',
  metaDescription:
    'اسانس زنجبیل با طعم تند و کمی تلخ، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها، لوازم آرایشی و بهداشتی. خرید اسانس زنجبیل با خواص درمانی و خوشبو.',
  body: (
    <div class="description-box">
      <p>
        اسانس زنجبیل یکی از اسانس‌های طبیعی است که از ریشه زنجبیل استخراج می‌شود. این اسانس به دلیل طعم خاص و تند خود، در صنایع غذایی، نوشیدنی‌ها، و حتی محصولات آرایشی و بهداشتی کاربرد فراوانی دارد. زنجبیل از زمان‌های بسیار قدیم به‌عنوان یک داروی طبیعی شناخته شده است و استفاده از آن در طب سنتی به‌طور گسترده‌ای در درمان مشکلات گوارشی، دردهای عضلانی و تسکین تهوع استفاده می‌شود. اسانس زنجبیل، نه‌تنها طعم و رایحه‌ای دلپذیر به محصولات مختلف می‌دهد، بلکه خواص درمانی متعددی نیز دارد که در اینجا به بررسی آن‌ها خواهیم پرداخت.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس زنجبیل در صنایع غذایی به‌عنوان طعم‌دهنده و افزودنی استفاده می‌شود. این اسانس می‌تواند طعمی خاص و تند به انواع غذاها و نوشیدنی‌ها بدهد. در تولید نوشیدنی‌ها مانند چای زنجبیل، اسموتی‌ها و آبمیوه‌های مرکباتی، اسانس زنجبیل یکی از اجزای مهم است. این اسانس علاوه بر اینکه طعمی خوشمزه به نوشیدنی‌ها می‌بخشد، خاصیت گرم‌کنندگی نیز دارد که در فصول سرد سال بسیار مورد توجه است.
      </p>
      <p>
        در دسرها، شیرینی‌ها و کیک‌ها نیز اسانس زنجبیل می‌تواند به‌عنوان طعم‌دهنده اصلی یا فرعی استفاده شود. این اسانس در ترکیب با دارچین، میخک و جوز هندی طعم‌های عالی و دلپذیری ایجاد می‌کند که در محصولات شیرینی‌پزی بسیار پرطرفدار است. علاوه بر این، اسانس زنجبیل در طعم‌دهی به مرباها، ژله‌ها و حتی شکلات‌ها نیز کاربرد دارد.
      </p>
      <h2>کاربرد در نوشیدنی‌ها</h2>
      <p>
        یکی از کاربردهای رایج اسانس زنجبیل در تولید نوشیدنی‌های مختلف است. از جمله نوشیدنی‌هایی که می‌توانند از طعم زنجبیل بهره‌مند شوند، چای زنجبیل و نوشیدنی‌های گازدار هستند. در برخی از نوشیدنی‌ها، اسانس زنجبیل به‌عنوان یک طعم‌دهنده طبیعی و سالم استفاده می‌شود که می‌تواند در کنار سایر طعم‌دهنده‌ها مانند لیمو، نعناع و پرتقال یک طعم بی‌نظیر ایجاد کند.
      </p>
      <p>
        همچنین اسانس زنجبیل در نوشیدنی‌های انرژی‌زا و دارویی نیز استفاده می‌شود. چای زنجبیل به‌طور خاص به‌عنوان یک نوشیدنی طبیعی و درمانی شناخته شده است که به بهبود فرآیند هضم، کاهش التهاب‌ها و تسکین دردهای گوارشی کمک می‌کند.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس زنجبیل به‌دلیل خواص ضدباکتری و آنتی‌اکسیدانی که دارد، در صنعت آرایشی و بهداشتی نیز استفاده می‌شود. این اسانس در تولید محصولات مراقبت از پوست مانند کرم‌ها، لوسیون‌ها، شامپوها و صابون‌ها کاربرد دارد. زنجبیل به‌عنوان یک ضدالتهاب طبیعی شناخته می‌شود و می‌تواند در کاهش قرمزی و التهاب‌های پوستی مؤثر باشد. علاوه بر این، اسانس زنجبیل به دلیل خاصیت گرم‌کنندگی و تحریک‌کنندگی خود، می‌تواند به بهبود گردش خون در پوست و تسکین عضلات کمک کند.
      </p>
      <p>
        از این اسانس در بسیاری از محصولات ضدآکنه و ضدباکتری نیز استفاده می‌شود. خاصیت ضدباکتری و ضدقارچ زنجبیل کمک می‌کند تا این اسانس در بهبود مشکلات پوستی مانند آکنه و جوش‌های صورت مؤثر باشد. همچنین، اسانس زنجبیل در محصولات ضدریزش مو نیز به‌کار می‌رود، زیرا می‌تواند به تحریک رشد مو و تقویت فولیکول‌های مو کمک کند.
      </p>
      <h2>خواص درمانی اسانس زنجبیل</h2>
      <p>
        اسانس زنجبیل یکی از اسانس‌های محبوب در طب سنتی است و خواص درمانی متعددی دارد. این اسانس برای کاهش تهوع، دردهای عضلانی، و مشکلات گوارشی بسیار مفید است. زنجبیل به‌عنوان یک ضد التهاب طبیعی شناخته می‌شود و می‌تواند در درمان دردهای مفصلی و عضلانی کمک کند. این اسانس به‌ویژه برای افرادی که از آرتروز یا دردهای مزمن مفصلی رنج می‌برند، مفید است.
      </p>
      <p>
        یکی از خواص برجسته اسانس زنجبیل، خاصیت ضدتهوع آن است. این اسانس می‌تواند در درمان تهوع‌های صبحگاهی، تهوع ناشی از حرکت، و تهوع بعد از عمل جراحی مؤثر باشد. علاوه بر این، زنجبیل به‌عنوان یک داروی طبیعی برای بهبود فرآیند هضم شناخته می‌شود. این اسانس می‌تواند به تسکین دردهای معده، نفخ، سوءهاضمه و دیگر مشکلات گوارشی کمک کند.
      </p>
      <p>
        زنجبیل به دلیل خواص آنتی‌اکسیدانی خود می‌تواند در تقویت سیستم ایمنی بدن نیز مؤثر باشد. مصرف منظم اسانس زنجبیل به تقویت دفاع بدن در برابر عفونت‌ها کمک می‌کند و می‌تواند در جلوگیری از سرماخوردگی و بیماری‌های مشابه مؤثر باشد.
      </p>
      <h2>نحوه استفاده از اسانس زنجبیل</h2>
      <p>
        اسانس زنجبیل معمولاً به‌صورت چند قطره در محصولات مختلف غذایی و آرایشی استفاده می‌شود. در محصولات غذایی، این اسانس به‌عنوان طعم‌دهنده به نوشیدنی‌ها، دسرها، کیک‌ها و شیرینی‌ها اضافه می‌شود. در صنعت آرایشی و بهداشتی، اسانس زنجبیل به‌عنوان یکی از ترکیبات در تولید کرم‌ها، لوسیون‌ها، شامپوها و صابون‌ها استفاده می‌شود.
      </p>
      <p>
        برای استفاده خانگی، می‌توانید چند قطره اسانس زنجبیل را با یک روغن پایه مانند روغن بادام یا روغن زیتون مخلوط کرده و آن را روی پوست یا عضلات خود ماساژ دهید. این کار می‌تواند به تسکین دردهای عضلانی و کاهش التهاب کمک کند. همچنین می‌توانید از این اسانس برای تهیه چای زنجبیل خانگی استفاده کنید.
      </p>
      <h2>مزایای استفاده از اسانس زنجبیل</h2>
      <p>
        استفاده از اسانس زنجبیل مزایای زیادی به همراه دارد. این اسانس دارای خواص ضدالتهابی، ضدباکتری، ضدقارچ و آنتی‌اکسیدانی است که می‌تواند در بهبود مشکلات گوارشی، کاهش دردهای عضلانی، درمان تهوع، و تقویت سیستم ایمنی مؤثر باشد. همچنین، اسانس زنجبیل در صنعت آرایشی نیز کاربرد دارد و می‌تواند به بهبود وضعیت پوست و مو کمک کند.
      </p>
    </div>
  ),
}
,
{
  id: 33,
  img: p33,
  title: 'اسانس گل محمدی',
  description: 'اسانس گل محمدی با رایحه گلی، ملایم و دلپذیر، مناسب برای استفاده در صنایع آرایشی، بهداشتی، دارویی و عطرسازی است. این اسانس به‌خاطر خواص درمانی و طبیعی‌اش در بسیاری از محصولات به‌کار می‌رود.',
  metaDescription: 
    'اسانس گل محمدی با رایحه گلی و خوشبو، مناسب برای محصولات آرایشی، بهداشتی، دارویی و عطرسازی. خرید اسانس گل محمدی با کیفیت بالا برای استفاده در محصولات طبیعی.',
  body: (
    <div class="description-box">
      <p>
        اسانس گل محمدی از گل‌های محمدی با رایحه‌ای گلی و شیرین استخراج می‌شود. این اسانس با داشتن خواص بی‌نظیر و کاربردهای فراوان در صنایع مختلف، به‌ویژه در عطرسازی، آرایشی و بهداشتی، یکی از گران‌بها‌ترین و محبوب‌ترین اسانس‌ها محسوب می‌شود. گل محمدی به‌دلیل خواص آرام‌بخش، ضد التهابی و مرطوب‌کننده‌اش، در درمان بسیاری از مشکلات جسمی و روحی مؤثر است.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس گل محمدی به‌طور گسترده‌ای در تولید انواع کرم‌ها، لوسیون‌ها، شامپوها و سایر محصولات مراقبتی پوست و مو مورد استفاده قرار می‌گیرد. این اسانس به دلیل خواص نرم‌کننده و مرطوب‌کننده‌اش، برای درمان خشکی پوست و بهبود وضعیت موهای آسیب‌دیده بسیار مؤثر است. علاوه بر این، اسانس گل محمدی به‌دلیل خاصیت ضد التهابی و ضد عفونی‌کننده‌اش در درمان جوش و آکنه نیز کاربرد دارد.
      </p>
      <h2>کاربرد در صنعت عطرسازی</h2>
      <p>
        رایحه گلی و شیرین اسانس گل محمدی یکی از مهم‌ترین نت‌ها در صنعت عطرسازی است. این اسانس در بسیاری از عطرها و ادکلن‌های لوکس به‌عنوان یکی از اجزای اصلی و پایه‌ای رایحه‌ها مورد استفاده قرار می‌گیرد. گل محمدی با رایحه‌ای ملایم و دلپذیر باعث ایجاد حس تازگی، آرامش و طراوت در عطرها می‌شود. این اسانس علاوه بر اینکه در ترکیب‌های گل‌فام کاربرد دارد، می‌تواند در ترکیب با سایر رایحه‌ها نظیر چوبی، مرکبات یا شرقی، پیچیدگی‌های جذابی ایجاد کند.
      </p>
      <h2>کاربرد در داروسازی</h2>
      <p>
        اسانس گل محمدی به‌عنوان یک درمان طبیعی در طب سنتی شناخته می‌شود و خواص زیادی برای سلامت بدن دارد. این اسانس به دلیل خواص ضد التهابی و ضد درد، در درمان بسیاری از بیماری‌ها مانند دردهای عضلانی، سردردها و همچنین مشکلات گوارشی مورد استفاده قرار می‌گیرد. علاوه بر این، اسانس گل محمدی به‌عنوان یک آرام‌بخش طبیعی برای کاهش استرس و اضطراب شناخته شده است و می‌تواند در درمان بی‌خوابی و افسردگی مفید باشد.
      </p>
      <h2>خواص درمانی اسانس گل محمدی</h2>
      <p>
        اسانس گل محمدی دارای خواص ضد التهابی و آنتی‌اکسیدانی است که آن را به یک درمان طبیعی برای بسیاری از مشکلات پوستی و روحی تبدیل کرده است. این اسانس می‌تواند به تسکین و درمان پوست‌های حساس و ملتهب کمک کند و به‌عنوان یک مرطوب‌کننده طبیعی برای پوست‌های خشک و آسیب‌دیده عمل کند. همچنین، اسانس گل محمدی با خاصیت ضدعفونی‌کننده‌اش در درمان جوش‌ها و آکنه مؤثر است.
      </p>
      <h2>مزایای استفاده از اسانس گل محمدی در محصولات</h2>
      <p>
        اسانس گل محمدی علاوه بر رایحه دلپذیر و طبیعی‌اش، دارای خواص درمانی و مراقبتی برای پوست و مو است. این اسانس می‌تواند به‌عنوان یک مرطوب‌کننده قوی برای پوست‌های خشک و آسیب‌دیده عمل کند، در حالی که با خاصیت ضد التهابی خود به تسکین و کاهش التهاب‌های پوستی کمک می‌کند. علاوه بر این، اسانس گل محمدی با خواص آنتی‌اکسیدانی خود به محافظت از پوست در برابر آسیب‌های محیطی و پیر شدن زودرس کمک می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس گل محمدی در محصولات آرایشی</h2>
      <p>
        استفاده از اسانس گل محمدی در محصولات آرایشی و بهداشتی به‌ویژه در کرم‌ها و لوسیون‌ها باعث ایجاد حس آرامش و طراوت می‌شود. این اسانس با توانایی مرطوب‌کنندگی و آبرسانی پوست، به جوانسازی پوست کمک کرده و از خشکی و آسیب‌های محیطی جلوگیری می‌کند. اسانس گل محمدی به‌عنوان یک ترکیب طبیعی، انتخاب مناسبی برای استفاده در محصولات بدون مواد شیمیایی و آسیب‌رسان است.
      </p>
      <h2>استفاده از اسانس گل محمدی در محصولات عطرسازی</h2>
      <p>
        اسانس گل محمدی علاوه بر ویژگی‌های درمانی، در صنعت عطرسازی نیز کاربرد بسیاری دارد. رایحه گلی و ملایم آن در ترکیب با سایر نت‌های عطر مانند مرکبات یا چوبی، عطرهایی لوکس و ماندگار ایجاد می‌کند. این اسانس به‌ویژه در عطرهای زنانه به‌عنوان یک نت اصلی و غالب حضور دارد. ترکیب رایحه گل محمدی با نت‌های دیگر، تجربه‌ای دلپذیر و آرامش‌بخش برای مصرف‌کننده فراهم می‌آورد.
      </p>
    </div>
  ),
}

,
{
  id: 34,
  img: p34,
  title: 'اسانس آویشن',
  description: 'اسانس آویشن با رایحه‌ای قوی، تند و گیاهی، یکی از پرکاربردترین اسانس‌ها در صنایع غذایی، دارویی و آرایشی است. این اسانس به دلیل خواص آنتی‌باکتریال، آنتی‌اکسیدانی و ضدالتهابی خود شناخته می‌شود.',
  metaDescription: 
    'اسانس آویشن با خواص آنتی‌باکتریال و آنتی‌اکسیدانی، مناسب برای محصولات غذایی، دارویی و آرایشی. خرید اسانس آویشن با کیفیت بالا برای استفاده در محصولات طبیعی و درمانی.',
  body: (
    <div class="description-box">
      <p>
        اسانس آویشن از برگ‌ها و گل‌های گیاه آویشن به روش تقطیر بخار استخراج می‌شود. این اسانس دارای ترکیبات طبیعی قدرتمندی مانند تیمول و کارواکرول است که آن را به یک ماده کاربردی در صنایع مختلف تبدیل کرده است. آویشن از گیاهان دارویی محبوب است که قرن‌ها در طب سنتی برای درمان بیماری‌های مختلف مورد استفاده قرار گرفته است.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس آویشن به‌طور گسترده در صنایع غذایی به‌عنوان یک طعم‌دهنده طبیعی مورد استفاده قرار می‌گیرد. رایحه تند و قوی این اسانس می‌تواند به بهبود طعم و عطر غذاها کمک کند. همچنین، اسانس آویشن به دلیل خواص آنتی‌باکتریال و ضد قارچ خود می‌تواند به افزایش ماندگاری محصولات غذایی کمک کند. این اسانس در تهیه سس‌ها، سوپ‌ها، محصولات گوشتی و حتی نوشیدنی‌ها مورد استفاده قرار می‌گیرد.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس آویشن به دلیل خواص ضدالتهابی و ضدعفونی‌کننده، در محصولات دارویی برای درمان مشکلات تنفسی، عفونت‌ها و التهابات به کار می‌رود. این اسانس به‌ویژه در شربت‌های ضد سرفه، قرص‌های گلودرد و محصولات بخور تنفسی مورد استفاده قرار می‌گیرد. ترکیبات فعال موجود در اسانس آویشن می‌توانند به تسکین علائم سرماخوردگی، گلودرد و حتی برونشیت کمک کنند.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس آویشن به‌عنوان یک ماده طبیعی با خواص آنتی‌باکتریال و ضدقارچ، در تولید محصولات آرایشی و بهداشتی کاربرد دارد. این اسانس می‌تواند به بهبود سلامت پوست و مو کمک کند و در درمان مشکلاتی مانند آکنه، شوره سر و التهابات پوستی مؤثر باشد. علاوه بر این، اسانس آویشن در تولید صابون‌ها، شامپوها و کرم‌های مراقبتی به کار می‌رود.
      </p>
      <h2>خواص درمانی اسانس آویشن</h2>
      <p>
        اسانس آویشن به دلیل داشتن ترکیبات آنتی‌باکتریال و آنتی‌اکسیدانی، می‌تواند به تقویت سیستم ایمنی بدن و محافظت از آن در برابر بیماری‌ها کمک کند. این اسانس دارای خاصیت ضد التهاب بوده و می‌تواند در درمان دردهای عضلانی و مفصلی مؤثر باشد. همچنین، اسانس آویشن به‌عنوان یک ماده طبیعی برای بهبود عملکرد دستگاه گوارش و کاهش نفخ و سوءهاضمه شناخته شده است.
      </p>
      <h2>مزایای استفاده از اسانس آویشن در محصولات</h2>
      <p>
        استفاده از اسانس آویشن در محصولات غذایی و بهداشتی به دلیل خواص طبیعی و قدرتمند آن، می‌تواند باعث افزایش کیفیت و ماندگاری محصولات شود. این اسانس با داشتن خاصیت آنتی‌باکتریال و ضد قارچ، به کنترل رشد میکروارگانیسم‌ها در محصولات کمک کرده و ماندگاری آن‌ها را افزایش می‌دهد. همچنین، اسانس آویشن به‌عنوان یک طعم‌دهنده طبیعی، انتخابی سالم برای غذاها و نوشیدنی‌ها است.
      </p>
      <h2>مزایای استفاده از اسانس آویشن در محصولات آرایشی</h2>
      <p>
        اسانس آویشن در محصولات آرایشی و بهداشتی به‌عنوان یک ترکیب ضدعفونی‌کننده و تقویت‌کننده پوست و مو عمل می‌کند. این اسانس می‌تواند به بهبود سلامت پوست‌های چرب و مستعد آکنه کمک کند و به کاهش التهابات پوستی کمک نماید. همچنین، اسانس آویشن در ترکیب با سایر مواد طبیعی می‌تواند به عنوان یک تقویت‌کننده طبیعی برای محصولات مراقبتی مورد استفاده قرار گیرد.
      </p>
      <h2>استفاده از اسانس آویشن در محصولات درمانی</h2>
      <p>
        اسانس آویشن در ترکیب با روغن‌های دیگر برای درمان دردهای عضلانی و تسکین استرس به‌کار می‌رود. این اسانس به دلیل خواص ضدعفونی‌کننده و ضدالتهابی، در درمان بیماری‌های عفونی و همچنین در محصولات بخور و اسپری‌های تنفسی مورد استفاده قرار می‌گیرد. علاوه بر این، اسانس آویشن به‌عنوان یک درمان طبیعی برای تقویت دستگاه ایمنی بدن بسیار مؤثر است.
      </p>
    </div>
  ),
}
,
{
  id: 35,
  img: p35,
  title: 'اسانس میخک',
  description: 'اسانس میخک با عطر گرم، تند و ادویه‌ای، یکی از اسانس‌های پرطرفدار در صنایع غذایی، دارویی و آرایشی است. این اسانس به دلیل خواص آنتی‌باکتریال، ضدالتهابی و آنتی‌اکسیدانی خود مورد توجه قرار گرفته است.',
  metaDescription:
    'اسانس میخک با خواص آنتی‌باکتریال و طعم تند و گرم، مناسب برای صنایع غذایی، دارویی و آرایشی. خرید اسانس میخک با کیفیت بالا برای استفاده در محصولات درمانی و طبیعی.',
  body: (
    <div class="description-box">
      <p>
        اسانس میخک از جوانه‌های خشک‌شده گل میخک استخراج می‌شود و به دلیل رایحه قوی، تند و گرم خود شهرت دارد. این اسانس حاوی ترکیبات فعالی نظیر اوژنول است که خواص دارویی و درمانی قدرتمندی را به آن بخشیده است. اسانس میخک به‌طور گسترده در صنایع مختلف از جمله غذایی، دارویی و آرایشی استفاده می‌شود.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس میخک به‌عنوان یک طعم‌دهنده طبیعی در صنایع غذایی استفاده می‌شود. این اسانس به دلیل طعم تند و گرم خود، در تولید سس‌ها، کیک‌ها، شیرینی‌ها، نوشیدنی‌ها و محصولات گوشتی مورد استفاده قرار می‌گیرد. اسانس میخک همچنین می‌تواند به‌عنوان یک نگهدارنده طبیعی عمل کرده و ماندگاری محصولات غذایی را افزایش دهد.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس میخک در صنعت داروسازی به دلیل خواص ضدالتهابی و ضدعفونی‌کننده خود کاربردهای متعددی دارد. این اسانس به‌ویژه در درمان دردهای دندان و لثه به‌کار می‌رود و در تولید خمیردندان‌ها و دهان‌شویه‌ها مورد استفاده قرار می‌گیرد. همچنین، اسانس میخک می‌تواند در محصولات درمانی برای کاهش التهابات عضلانی و دردهای مفصلی استفاده شود.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس میخک به دلیل خواص آنتی‌باکتریال و آنتی‌اکسیدانی، در تولید محصولات آرایشی و بهداشتی کاربرد دارد. این اسانس می‌تواند به بهبود سلامت پوست کمک کند و در تولید صابون‌ها، کرم‌های مراقبتی و شامپوها به کار رود. همچنین، اسانس میخک به دلیل خاصیت ضدعفونی‌کننده خود، در محصولات ضدجوش و درمان مشکلات پوستی مانند آکنه استفاده می‌شود.
      </p>
      <h2>خواص درمانی اسانس میخک</h2>
      <p>
        اسانس میخک به دلیل داشتن ترکیبات ضدالتهابی و ضدعفونی‌کننده، به‌عنوان یک درمان طبیعی برای بسیاری از مشکلات بدن شناخته شده است. این اسانس می‌تواند به کاهش دردهای دندان و لثه کمک کند و به‌عنوان یک مسکن طبیعی عمل کند. همچنین، اسانس میخک برای بهبود عملکرد سیستم گوارشی و کاهش مشکلاتی مانند نفخ و سوءهاضمه مفید است.
      </p>
      <h2>مزایای استفاده از اسانس میخک در محصولات</h2>
      <p>
        استفاده از اسانس میخک در محصولات مختلف علاوه بر ایجاد طعم و عطر بی‌نظیر، مزایای متعددی دارد. این اسانس می‌تواند به حفظ کیفیت و ماندگاری محصولات غذایی کمک کرده و به‌عنوان یک نگهدارنده طبیعی عمل کند. در محصولات دارویی، اسانس میخک می‌تواند به کاهش التهابات و بهبود علائم بیماری‌ها کمک کند و در محصولات آرایشی نیز به بهبود سلامت پوست و مو کمک می‌کند.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس میخک به دلیل خواص درمانی خود، در محصولات بخور، روغن‌های ماساژ و پمادهای موضعی برای کاهش درد و التهابات مورد استفاده قرار می‌گیرد. این اسانس می‌تواند به تسکین دردهای عضلانی و مفصلی کمک کند و به‌عنوان یک ماده طبیعی برای آرامش‌بخشی و کاهش استرس نیز مؤثر باشد.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس میخک</h2>
      <p>
        اسانس میخک با داشتن ترکیبات طبیعی قدرتمند نظیر اوژنول، به‌عنوان یک آنتی‌اکسیدان قوی عمل کرده و به محافظت از سلول‌های بدن در برابر آسیب‌های اکسیداتیو کمک می‌کند. این اسانس همچنین دارای خاصیت ضدباکتریایی است که می‌تواند در کنترل عفونت‌ها و پیشگیری از بیماری‌های مختلف مؤثر باشد.
      </p>
      <p>
        استفاده از اسانس میخک در محصولات مختلف به دلیل خواص بی‌نظیر آن، می‌تواند تجربه‌ای طبیعی، سالم و لذت‌بخش برای مصرف‌کنندگان فراهم کند.
      </p>
    </div>
  ),
}
,{
  id: 36,
  img: p36,
  title: 'اسانس بادام',
  description: 'اسانس بادام با رایحه‌ای شیرین و ملایم، یکی از پرکاربردترین اسانس‌ها در صنایع غذایی، آرایشی و بهداشتی است. این اسانس با خواص طعم‌دهندگی و آنتی‌اکسیدانی خود، انتخابی محبوب برای تولید محصولات با کیفیت بالا می‌باشد.',
  metaDescription:
    'اسانس بادام با عطر و طعم ملایم، مناسب برای صنایع غذایی، آرایشی و دارویی. خرید اسانس بادام با کیفیت بالا برای تولید محصولات طبیعی و خوش‌عطر.',
  body: (
    <div class="description-box">
      <p>
        اسانس بادام از دانه‌های بادام شیرین یا تلخ استخراج می‌شود و به دلیل رایحه ملایم و خاص خود در طیف وسیعی از محصولات استفاده می‌شود. این اسانس علاوه بر کاربرد در صنایع غذایی، به دلیل خواص نرم‌کنندگی و آبرسانی، در محصولات آرایشی و بهداشتی نیز نقش مهمی ایفا می‌کند. اسانس بادام همچنین حاوی ترکیبات مفیدی است که به بهبود سلامت پوست و بدن کمک می‌کنند.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس بادام به‌عنوان یکی از طعم‌دهنده‌های طبیعی در صنایع غذایی بسیار محبوب است. این اسانس به دلیل عطر و طعم شیرین و ملایم خود، در تولید کیک‌ها، شیرینی‌ها، بستنی‌ها و دسرها استفاده می‌شود. همچنین، اسانس بادام در تولید نوشیدنی‌های گرم و سرد مانند قهوه‌های طعم‌دار، شربت‌ها و اسموتی‌ها به کار می‌رود.
      </p>
      <p>
        در محصولات نانوایی و شیرینی‌پزی، اسانس بادام می‌تواند به‌عنوان جایگزینی طبیعی برای طعم‌دهنده‌های مصنوعی استفاده شود و طعمی غنی و خاص به محصولات ببخشد. همچنین، این اسانس در ترکیب با سایر طعم‌دهنده‌ها، طعم‌های متنوع و بی‌نظیری ایجاد می‌کند.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس بادام به دلیل خواص مرطوب‌کنندگی و نرم‌کنندگی خود، در تولید محصولات مراقبتی پوست و مو استفاده می‌شود. این اسانس می‌تواند به تغذیه عمیق پوست کمک کرده و از خشکی و التهاب جلوگیری کند. در محصولات مراقبتی مو، اسانس بادام به تقویت و درخشش موها کمک می‌کند.
      </p>
      <p>
        اسانس بادام همچنین در تولید صابون‌ها، لوسیون‌ها و کرم‌های دست و صورت به‌کار می‌رود. این محصولات با رایحه ملایم بادام، حس آرامش و تازگی را به مصرف‌کنندگان القا می‌کنند و برای پوست‌های حساس بسیار مناسب هستند.
      </p>
      <h2>خواص درمانی اسانس بادام</h2>
      <p>
        اسانس بادام به دلیل داشتن آنتی‌اکسیدان‌های قوی، به محافظت از پوست در برابر آسیب‌های محیطی و رادیکال‌های آزاد کمک می‌کند. این اسانس می‌تواند به کاهش التهابات پوستی کمک کرده و پوست را نرم و لطیف نگه دارد. همچنین، اسانس بادام به تسکین دردهای عضلانی و مفصلی کمک می‌کند و در ماساژ درمانی به‌کار می‌رود.
      </p>
      <p>
        استفاده از اسانس بادام در محصولات طبیعی و درمانی می‌تواند به بهبود کیفیت زندگی و حفظ سلامت کلی بدن کمک کند. این اسانس علاوه بر خواص آنتی‌اکسیدانی، دارای اثرات ضدباکتریایی و ضدالتهابی نیز می‌باشد که برای پوست‌های آسیب‌دیده و حساس بسیار مفید است.
      </p>
      <h2>مزایای استفاده از اسانس بادام در محصولات</h2>
      <p>
        استفاده از اسانس بادام در محصولات غذایی، آرایشی و بهداشتی مزایای بی‌شماری دارد. این اسانس با ایجاد طعمی طبیعی و دلپذیر در محصولات غذایی، تجربه‌ای لذت‌بخش برای مصرف‌کنندگان فراهم می‌کند. در محصولات آرایشی و بهداشتی نیز، اسانس بادام به حفظ رطوبت و سلامت پوست و مو کمک کرده و حس نرمی و لطافت را به ارمغان می‌آورد.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس بادام در ترکیب با روغن‌های طبیعی دیگر، در تولید روغن‌های ماساژ و محصولات مراقبتی پوست به‌کار می‌رود. این محصولات می‌توانند به کاهش استرس و افزایش آرامش کمک کرده و تجربه‌ای بی‌نظیر از مراقبت و درمان ارائه دهند. همچنین، اسانس بادام در تولید محصولات ضدچروک و جوان‌سازی پوست مورد استفاده قرار می‌گیرد.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس بادام</h2>
      <p>
        اسانس بادام با داشتن رایحه‌ای ملایم و خواص تغذیه‌کننده، یکی از محبوب‌ترین اسانس‌ها در صنایع مختلف است. این اسانس به‌عنوان یک ماده طبیعی و سالم، جایگزینی مناسب برای ترکیبات شیمیایی و مصنوعی در تولید محصولات مختلف می‌باشد. اسانس بادام با ارائه تجربه‌ای لوکس و آرامش‌بخش، برای مصرف‌کنندگان حرفه‌ای و عادی انتخابی بی‌نظیر است.
      </p>
    </div>
  ),
}
,
{ 
  id: 37, 
  img: p37, 
  title: 'اسانس توت سفید', 
  description: 'اسانس توت با طعمی شیرین و دلچسب، رایحه‌ای دلپذیر دارد که می‌تواند به محصولات شما طعم و تازگی طبیعی بخشیده و حس میوه‌های تازه و تابستانی را به ارمغان بیاورد. این اسانس، با ویژگی‌های منحصر به فرد خود، تجربه‌ای دلپذیر را برای هر مصرف‌کننده ایجاد می‌کند.',
  metaDescription:
    'اسانس توت با طعم طبیعی و رایحه دلپذیر، مناسب برای استفاده در محصولات غذایی، دسرها و نوشیدنی‌ها. خرید اسانس توت برای افزودن طعم خوشمزه و تازه به محصولات.',
  body: (
    <div class="description-box">
      <p>
        اسانس توت از میوه‌های تازه و آبدار توت استخراج می‌شود و به دلیل طعم طبیعی و رایحه‌ای خوشایند، طرفداران زیادی دارد. این اسانس به طور خاص برای طعم‌دهی به دسرها، نوشیدنی‌ها و حتی برخی محصولات پختنی استفاده می‌شود.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس توت در صنعت غذایی به‌عنوان طعم‌دهنده طبیعی کاربرد دارد. این اسانس با طعم شیرین و ترش خود، به دسرها، مرباها، نوشیدنی‌ها و کیک‌ها طعمی تازه و منحصر به فرد می‌بخشد. علاوه بر این، اسانس توت می‌تواند به محصولات غذایی یک طعم خاص و تابستانی اضافه کند.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس توت در داروسازی برای خواص آنتی‌اکسیدانی خود مورد استفاده قرار می‌گیرد. این اسانس به عنوان یک ماده طبیعی در محصولات درمانی مختلف به کار می‌رود و می‌تواند به تقویت سیستم ایمنی و کاهش التهابات کمک کند.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس توت به دلیل خواص ضدآسیب و مرطوب‌کنندگی خود، در صنعت آرایشی و بهداشتی مورد استفاده قرار می‌گیرد. این اسانس می‌تواند به حفظ رطوبت پوست کمک کند و در محصولات مراقبتی پوستی مانند کرم‌ها و لوسیون‌ها به کار رود.
      </p>
      <h2>خواص درمانی اسانس توت</h2>
      <p>
        اسانس توت به دلیل داشتن ترکیبات آنتی‌اکسیدانی، می‌تواند به بهبود سلامت عمومی کمک کند. این اسانس می‌تواند به مبارزه با التهابات و محافظت از پوست در برابر آسیب‌های محیطی مؤثر باشد.
      </p>
      <h2>مزایای استفاده از اسانس توت در محصولات</h2>
      <p>
        استفاده از اسانس توت در محصولات علاوه بر طعم‌دهی طبیعی، می‌تواند به تقویت سلامت پوست و سیستم ایمنی کمک کند. این اسانس در طول زمان به‌عنوان یک نگهدارنده طبیعی نیز شناخته شده است.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس توت در محصولات درمانی مانند روغن‌های ماساژ و پمادهای موضعی می‌تواند برای کاهش التهاب‌ها و تسکین دردهای عضلانی استفاده شود. این اسانس به‌عنوان یک ماده طبیعی برای آرامش‌بخشی و بهبود گردش خون نیز مؤثر است.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس توت</h2>
      <p>
        اسانس توت با خواص آنتی‌اکسیدانی و ضدالتهابی خود به محافظت از سلول‌ها در برابر آسیب‌های محیطی کمک می‌کند. این اسانس علاوه بر طعم خوشمزه و طبیعی خود، می‌تواند به تقویت سیستم ایمنی بدن نیز کمک کند.
      </p>
      <p>
        استفاده از اسانس توت در محصولات مختلف تجربه‌ای سالم و طبیعی را برای مصرف‌کنندگان فراهم می‌آورد.
      </p>
    </div>
  ),
}

,
{ 
  id: 38, 
  img: p38, 
  title: 'اسانس زعفران', 
  description: 'اسانس زعفران با رایحه‌ای معطر و طعمی گرم و دلپذیر، یکی از مهم‌ترین اسانس‌ها در صنایع مختلف از جمله غذایی و آرایشی است. این اسانس به دلیل خواص منحصر به فرد خود، نه تنها طعم و رنگ زیبا به محصولات می‌دهد بلکه به عنوان یک آنتی‌اکسیدان طبیعی شناخته می‌شود.',
  metaDescription:
    'اسانس زعفران با طعم و رایحه معطر و خواص آنتی‌اکسیدانی، مناسب برای صنایع غذایی و آرایشی. خرید اسانس زعفران برای افزودن طعم و رنگ طبیعی به محصولات مختلف.',
  body: (
    <div class="description-box">
      <p>
        اسانس زعفران از بهترین گل‌های زعفران استخراج می‌شود و به دلیل رایحه منحصر به فرد و طعم دلپذیر خود، یکی از محبوب‌ترین اسانس‌ها در صنعت غذایی و دارویی است. این اسانس علاوه بر کاربردهای غذایی، در صنایع آرایشی نیز به‌عنوان یک ترکیب مفید برای تقویت پوست و حفظ سلامت آن به کار می‌رود.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس زعفران به‌عنوان یک طعم‌دهنده طبیعی و رنگ‌دهنده در صنایع غذایی استفاده می‌شود. این اسانس به‌ویژه در تولید دسرها، نوشیدنی‌ها، کیک‌ها و غذاهای سنتی ایرانی کاربرد دارد. اسانس زعفران می‌تواند طعمی خاص و رنگی زیبا به محصولات غذایی شما اضافه کند.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس زعفران در داروسازی به دلیل خواص آنتی‌اکسیدانی و ضدالتهابی خود مورد توجه قرار گرفته است. این اسانس می‌تواند در تولید داروهایی که به تقویت سیستم ایمنی، بهبود وضعیت روانی و درمان اضطراب کمک می‌کنند، به کار رود.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس زعفران در صنعت آرایشی و بهداشتی به‌عنوان یک ماده مرطوب‌کننده و تقویت‌کننده پوست شناخته می‌شود. این اسانس می‌تواند به رفع چین و چروک‌های پوست کمک کرده و به سلامت و شادابی آن بیفزاید. همچنین، خاصیت ضدالتهابی آن به کاهش مشکلات پوستی مانند آکنه کمک می‌کند.
      </p>
      <h2>خواص درمانی اسانس زعفران</h2>
      <p>
        اسانس زعفران به دلیل داشتن ترکیبات موثر مانند کروسین و سافرانال، به‌عنوان یک آنتی‌اکسیدان قوی شناخته می‌شود. این اسانس می‌تواند به بهبود عملکرد مغز، کاهش استرس و اضطراب، و تقویت خلق‌وخو کمک کند.
      </p>
      <h2>مزایای استفاده از اسانس زعفران در محصولات</h2>
      <p>
        استفاده از اسانس زعفران در محصولات مختلف، علاوه بر افزودن طعم و رنگ طبیعی، مزایای متعددی دارد. این اسانس به عنوان یک ماده طبیعی در محصولات دارویی و آرایشی می‌تواند به سلامت پوست و بدن کمک کند.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس زعفران در محصولات درمانی مانند روغن‌های ماساژ و پمادهای موضعی برای تسکین دردهای عضلانی و مفصلی و همچنین در محصولات آرامش‌بخش مورد استفاده قرار می‌گیرد.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس زعفران</h2>
      <p>
        اسانس زعفران با خواص ضدالتهابی، آنتی‌اکسیدانی و تقویت‌کننده پوست خود، به‌عنوان یک ترکیب منحصر به فرد در صنایع مختلف شناخته می‌شود. این اسانس می‌تواند علاوه بر افزودن طعم و رنگ زیبا، به سلامت جسم و روان کمک کند.
      </p>
      <p>
        استفاده از اسانس زعفران در محصولات مختلف تجربه‌ای سالم و منحصر به فرد برای مصرف‌کنندگان فراهم می‌آورد.
      </p>
    </div>
  ),
}
,
{ 
  id: 39, 
  img: p39, 
  title: 'اسانس هلو', 
  description: 'اسانس هلو با رایحه‌ای شیرین و ملایم، طعمی تازه و طبیعی دارد که به محصولات شما حسی تازه و خوشایند می‌بخشد. این اسانس به‌طور گسترده در صنایع غذایی و آرایشی برای ایجاد طعم دلپذیر و عطر معطر استفاده می‌شود.',
  metaDescription:
    'اسانس هلو با طعمی شیرین و خوشبو، مناسب برای صنایع غذایی و آرایشی. خرید اسانس هلو برای استفاده در محصولات دسر، نوشیدنی و محصولات زیبایی.',
  body: (
    <div class="description-box">
      <p>
        اسانس هلو از میوه‌های تازه و رسیده هلو استخراج می‌شود و به دلیل طعم دلپذیر و رایحه طبیعی خود یکی از اسانس‌های محبوب در صنعت غذایی و آرایشی است. این اسانس به‌ویژه در تولید دسرها، نوشیدنی‌ها و محصولات زیبایی کاربرد دارد.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس هلو به‌عنوان یک طعم‌دهنده طبیعی در صنایع غذایی استفاده می‌شود. این اسانس می‌تواند در تولید نوشیدنی‌ها، دسرها، بستنی‌ها و شیرینی‌ها طعمی شیرین و تازه به ارمغان آورد. همچنین در سس‌ها و غذاهای مختلف نیز استفاده می‌شود.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس هلو به‌عنوان یک ماده آرامش‌بخش و تسکین‌دهنده در محصولات دارویی نیز کاربرد دارد. این اسانس می‌تواند به بهبود کیفیت خواب، کاهش استرس و تقویت سیستم ایمنی بدن کمک کند.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس هلو در صنعت آرایشی و بهداشتی به‌عنوان یک ماده مرطوب‌کننده و تقویت‌کننده پوست شناخته می‌شود. این اسانس می‌تواند به شادابی و نرمی پوست کمک کند و در تولید لوسیون‌ها، کرم‌ها و شامپوها به‌کار رود.
      </p>
      <h2>خواص درمانی اسانس هلو</h2>
      <p>
        اسانس هلو به دلیل داشتن ترکیبات طبیعی مفید، می‌تواند به تقویت سلامت بدن و پوست کمک کند. این اسانس با خاصیت ضدالتهابی و آرامش‌بخش خود برای کاهش استرس و بهبود وضعیت پوست مفید است.
      </p>
      <h2>مزایای استفاده از اسانس هلو در محصولات</h2>
      <p>
        استفاده از اسانس هلو در محصولات مختلف، علاوه بر افزودن طعم و عطر بی‌نظیر، مزایای درمانی و بهداشتی متعددی دارد. این اسانس می‌تواند به ایجاد محصولات با ویژگی‌های طبیعی و سلامت‌بخش کمک کند.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس هلو در محصولات درمانی مانند روغن‌های ماساژ و لوسیون‌های مرطوب‌کننده برای تسکین درد و التهابات استفاده می‌شود. این اسانس همچنین در محصولات آرامش‌بخش و ضد استرس کاربرد دارد.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس هلو</h2>
      <p>
        اسانس هلو با خواص مرطوب‌کننده، تسکین‌دهنده و ضدالتهابی خود، به‌عنوان یک ترکیب منحصر به فرد در محصولات آرایشی و دارویی شناخته می‌شود. این اسانس می‌تواند به سلامت پوست و بدن کمک کند و تجربه‌ای طبیعی و خوشایند برای مصرف‌کنندگان فراهم کند.
      </p>
      <p>
        استفاده از اسانس هلو در محصولات مختلف، به دلیل خواص منحصر به فرد و طعم دلپذیر آن، می‌تواند تجربه‌ای لذت‌بخش و سالم برای مصرف‌کنندگان ایجاد کند.
      </p>
    </div>
  ),
}
,
{ 
  id: 40, 
  img: p40, 
  title: 'اسانس سیب سبز', 
  description: 'اسانس سیب سبز با طعمی ترش و تازه، عطر خنک و انرژی‌بخش دارد که به محصولات شما حسی شاداب و طبیعی می‌بخشد. این اسانس در صنایع غذایی، نوشیدنی و آرایشی برای ایجاد طعمی منحصر به فرد و خوشبو مورد استفاده قرار می‌گیرد.',
  metaDescription:
    'اسانس سیب سبز با طعمی ترش و عطر خوشبو، مناسب برای صنایع غذایی، نوشیدنی و آرایشی. خرید اسانس سیب سبز برای استفاده در دسرها، نوشیدنی‌ها و محصولات زیبایی.',
  body: (
    <div class="description-box">
      <p>
        اسانس سیب سبز از میوه‌های تازه و رسیده سیب سبز استخراج می‌شود و به دلیل طعم ترش و رایحه خنک و طبیعی خود، در صنایع مختلف مورد استفاده قرار می‌گیرد. این اسانس به‌ویژه در تولید نوشیدنی‌ها، دسرها و محصولات آرایشی کاربرد دارد.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس سیب سبز به‌عنوان یک طعم‌دهنده طبیعی در صنایع غذایی استفاده می‌شود. این اسانس در تولید نوشیدنی‌ها، دسرها، کیک‌ها و بستنی‌ها به‌طور گسترده به کار می‌رود و طعمی تازه و انرژی‌بخش به محصولات می‌بخشد.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس سیب سبز به‌عنوان یک ماده آرامش‌بخش و تقویت‌کننده در محصولات دارویی کاربرد دارد. این اسانس می‌تواند به بهبود خلق و خو، کاهش استرس و تقویت سیستم ایمنی بدن کمک کند.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس سیب سبز در صنعت آرایشی به‌عنوان یک ماده مرطوب‌کننده و تقویت‌کننده پوست استفاده می‌شود. این اسانس می‌تواند به نرم و شاداب نگه داشتن پوست کمک کند و در تولید کرم‌ها، لوسیون‌ها و شامپوها به کار می‌رود.
      </p>
      <h2>خواص درمانی اسانس سیب سبز</h2>
      <p>
        اسانس سیب سبز به دلیل خواص طبیعی خود، می‌تواند به تقویت سلامت بدن کمک کند. این اسانس دارای خاصیت آرامش‌بخش است که به کاهش استرس و بهبود خلق و خو کمک می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس سیب سبز در محصولات</h2>
      <p>
        استفاده از اسانس سیب سبز در محصولات مختلف، علاوه بر افزودن طعم و عطر خوشایند، مزایای متعددی دارد. این اسانس می‌تواند به بهبود وضعیت پوست و افزایش طراوت و شادابی آن کمک کند و همچنین در ایجاد محصولات با ویژگی‌های طبیعی و سالم مؤثر باشد.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس سیب سبز به‌عنوان یک ماده آرامش‌بخش در محصولات درمانی مانند روغن‌های ماساژ و لوسیون‌های مرطوب‌کننده استفاده می‌شود. این اسانس می‌تواند به کاهش استرس و افزایش انرژی و شادابی کمک کند.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس سیب سبز</h2>
      <p>
        اسانس سیب سبز با خواص خنک‌کننده و انرژی‌بخش خود، به‌عنوان یک ترکیب منحصر به فرد در محصولات مختلف شناخته می‌شود. این اسانس به حفظ طراوت پوست و سلامت بدن کمک کرده و تجربه‌ای طبیعی و خوشایند برای مصرف‌کنندگان فراهم می‌کند.
      </p>
      <p>
        استفاده از اسانس سیب سبز در محصولات مختلف، می‌تواند تجربه‌ای لذت‌بخش و سالم برای مصرف‌کنندگان ایجاد کند که در کنار مزایای درمانی، به طعم و عطر بی‌نظیر محصولات نیز افزوده می‌شود.
      </p>
    </div>
  ),
}
,
{ 
  id: 41, 
  img: p41, 
  title: 'اسانس گلابی', 
  description: 'اسانس گلابی با طعمی شیرین، تازه و ملایم، یک رایحه دلنشین و طبیعی دارد که می‌تواند به محصولات شما طعمی متفاوت و خوشایند ببخشد. این اسانس در صنایع غذایی، نوشیدنی و آرایشی کاربرد دارد.',
  metaDescription:
    'اسانس گلابی با طعمی شیرین و طبیعی، مناسب برای صنایع غذایی، نوشیدنی و آرایشی. خرید اسانس گلابی برای استفاده در دسرها، نوشیدنی‌ها و محصولات زیبایی.',
  body: (
    <div class="description-box">
      <p>
        اسانس گلابی از میوه‌های تازه و رسیده گلابی استخراج می‌شود و با طعمی شیرین و ملایم، در صنایع مختلف به‌طور گسترده به‌کار می‌رود. این اسانس طعمی طبیعی و خوشایند به محصولات می‌بخشد و در صنایع غذایی و آرایشی استفاده فراوانی دارد.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس گلابی به‌عنوان یک طعم‌دهنده طبیعی در محصولات غذایی استفاده می‌شود. این اسانس در تولید نوشیدنی‌ها، دسرها، کیک‌ها و بستنی‌ها به‌طور وسیع به‌کار می‌رود و طعمی تازه و خوشایند به محصولات می‌دهد.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس گلابی به‌عنوان یک ماده طبیعی آرامش‌بخش و تقویت‌کننده در صنعت داروسازی مورد استفاده قرار می‌گیرد. این اسانس می‌تواند به بهبود سیستم گوارش و تقویت سیستم ایمنی بدن کمک کند.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس گلابی در صنعت آرایشی به‌عنوان یک مرطوب‌کننده طبیعی برای پوست استفاده می‌شود. این اسانس می‌تواند به شادابی و طراوت پوست کمک کرده و در تولید کرم‌ها، لوسیون‌ها و شامپوها به‌کار رود.
      </p>
      <h2>خواص درمانی اسانس گلابی</h2>
      <p>
        اسانس گلابی با خواص آرامش‌بخش و ضد التهابی خود، می‌تواند به کاهش استرس و بهبود خلق و خو کمک کند. همچنین، این اسانس به‌عنوان یک تقویت‌کننده سیستم ایمنی بدن و بهبود سیستم گوارش شناخته می‌شود.
      </p>
      <h2>مزایای استفاده از اسانس گلابی در محصولات</h2>
      <p>
        استفاده از اسانس گلابی در محصولات مختلف علاوه بر ایجاد طعم و عطر دلنشین، مزایای زیادی دارد. این اسانس می‌تواند به بهبود وضعیت پوست و افزایش شادابی و طراوت آن کمک کند و همچنین در تولید محصولات طبیعی و سالم مؤثر باشد.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس گلابی در محصولات درمانی مانند روغن‌های ماساژ، لوسیون‌ها و پمادها برای بهبود عملکرد سیستم گوارش و آرامش‌بخشی استفاده می‌شود. این اسانس می‌تواند به تسکین استرس و بهبود وضعیت عمومی بدن کمک کند.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس گلابی</h2>
      <p>
        اسانس گلابی با طعمی شیرین و طبیعی و خواص آرامش‌بخش و تقویت‌کننده خود، به‌عنوان یک ترکیب بی‌نظیر در محصولات مختلف شناخته می‌شود. این اسانس می‌تواند به حفظ طراوت پوست و تقویت سیستم ایمنی بدن کمک کند.
      </p>
      <p>
        استفاده از اسانس گلابی در محصولات مختلف، می‌تواند تجربه‌ای طبیعی و خوشایند برای مصرف‌کنندگان فراهم کند که علاوه بر مزایای درمانی، به طعم و عطر دلپذیر محصولات افزوده می‌شود.
      </p>
    </div>
  ),
}

,{ 
  id: 42, 
  img: p42, 
  title: 'اسانس دارچین', 
  description: 'اسانس دارچین با عطر گرم و تند خود، یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی و دارویی است. این اسانس به دلیل خواص ضدباکتریال و تقویت‌کننده سیستم ایمنی بدن، در تولید محصولات مختلف کاربرد دارد.',
  metaDescription:
    'اسانس دارچین با طعمی گرم و تند و خواص ضدباکتریال و تقویت‌کننده سیستم ایمنی، مناسب برای صنایع غذایی و دارویی. خرید اسانس دارچین با کیفیت بالا برای استفاده در محصولات طبیعی.',
  body: (
    <div class="description-box">
      <p>
        اسانس دارچین از پوست درخت دارچین استخراج می‌شود و دارای عطر و طعم گرم و تندی است که به‌طور گسترده در صنایع مختلف به‌کار می‌رود. این اسانس با خواص دارویی و درمانی خود، یکی از انتخاب‌های محبوب برای تقویت سیستم ایمنی و مبارزه با عفونت‌ها می‌باشد.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس دارچین به‌عنوان یک طعم‌دهنده طبیعی و قوی در محصولات غذایی مختلف استفاده می‌شود. این اسانس در تولید دسرها، نوشیدنی‌ها، مرباها و حتی کیک‌ها و شیرینی‌ها بسیار محبوب است. طعم تند و گرمی که دارچین به محصولات می‌دهد، حس راحتی و گرما را در مصرف‌کننده ایجاد می‌کند.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس دارچین به‌دلیل خواص ضدباکتریال و ضد التهابی خود، در درمان بسیاری از مشکلات گوارشی و تقویت سیستم ایمنی بدن استفاده می‌شود. این اسانس در تولید داروهای ضدعفونی‌کننده و مکمل‌های تقویتی برای درمان بیماری‌ها و تقویت بدن به‌کار می‌رود.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس دارچین به دلیل خواص ضدباکتریایی خود، در تولید محصولات آرایشی مانند صابون‌ها، لوسیون‌ها و کرم‌ها برای مبارزه با مشکلات پوستی کاربرد دارد. این اسانس به‌طور خاص در محصولات ضدجوش و درمان آکنه مؤثر است.
      </p>
      <h2>خواص درمانی اسانس دارچین</h2>
      <p>
        اسانس دارچین دارای خواص ضد التهابی، ضدباکتریایی و آنتی‌اکسیدانی است. این اسانس می‌تواند به بهبود گردش خون، کاهش دردهای عضلانی و مفصلی، و تقویت سیستم ایمنی بدن کمک کند. همچنین، اسانس دارچین برای بهبود مشکلات گوارشی مانند نفخ و سوءهاضمه نیز مفید است.
      </p>
      <h2>مزایای استفاده از اسانس دارچین در محصولات</h2>
      <p>
        استفاده از اسانس دارچین در محصولات مختلف نه تنها به‌عنوان طعم‌دهنده طبیعی عمل می‌کند، بلکه خواص درمانی و تقویت‌کننده آن نیز به کیفیت محصولات می‌افزاید. این اسانس می‌تواند به تقویت سیستم ایمنی بدن و بهبود وضعیت گوارش کمک کند.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس دارچین در روغن‌های ماساژ و پمادهای موضعی برای تسکین درد و التهاب و همچنین در مکمل‌های غذایی برای تقویت بدن به‌کار می‌رود. این اسانس با خواص تسکین‌دهنده خود می‌تواند به‌عنوان یک درمان طبیعی برای بسیاری از مشکلات جسمی مؤثر باشد.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس دارچین</h2>
      <p>
        اسانس دارچین با ترکیبات فعال خود، مانند سینامالدئید، به‌عنوان یک آنتی‌اکسیدان قوی و ضدباکتری شناخته می‌شود. این اسانس به‌طور طبیعی به محافظت از سلول‌های بدن در برابر آسیب‌های اکسیداتیو و عفونت‌ها کمک می‌کند.
      </p>
      <p>
        استفاده از اسانس دارچین در محصولات مختلف علاوه بر ایجاد طعم و عطر بی‌نظیر، خواص درمانی و تقویت‌کننده زیادی را به همراه دارد که می‌تواند تأثیر زیادی در سلامت مصرف‌کنندگان داشته باشد.
      </p>
    </div>
  ),
}
,
{ 
  id: 43, 
  img: p43, 
  title: 'اسانس گیلاس', 
  description: 'اسانس گیلاس با طعمی شیرین و ملایم و رایحه‌ای دلپذیر، حس تازه و خوشایندی به محصولات شما می‌بخشد. این اسانس در صنایع غذایی، نوشیدنی و آرایشی به‌عنوان یک ترکیب طبیعی و خوشبو استفاده می‌شود.',
  metaDescription:
    'اسانس گیلاس با طعمی شیرین و رایحه دلپذیر، مناسب برای صنایع غذایی، نوشیدنی و آرایشی. خرید اسانس گیلاس برای استفاده در دسرها، نوشیدنی‌ها و محصولات زیبایی.',
  body: (
    <div class="description-box">
      <p>
        اسانس گیلاس از میوه‌های تازه و رسیده گیلاس استخراج می‌شود و با طعمی شیرین و رایحه‌ای دلپذیر، در صنایع مختلف به‌طور گسترده استفاده می‌شود. این اسانس به‌ویژه در تولید نوشیدنی‌ها، دسرها و محصولات آرایشی کاربرد دارد.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس گیلاس به‌عنوان یک طعم‌دهنده طبیعی در صنایع غذایی استفاده می‌شود. این اسانس در تولید نوشیدنی‌ها، دسرها، کیک‌ها و بستنی‌ها به‌طور گسترده به کار می‌رود و طعمی دلپذیر و شیرین به محصولات می‌بخشد.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس گیلاس به‌عنوان یک ماده تقویت‌کننده و آرامش‌بخش در محصولات دارویی کاربرد دارد. این اسانس می‌تواند به تقویت سیستم ایمنی بدن، بهبود خواب و کاهش استرس کمک کند.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس گیلاس در صنعت آرایشی به‌عنوان یک ماده مرطوب‌کننده و نرم‌کننده پوست استفاده می‌شود. این اسانس می‌تواند به شادابی و طراوت پوست کمک کرده و در تولید کرم‌ها، لوسیون‌ها و شامپوها به کار رود.
      </p>
      <h2>خواص درمانی اسانس گیلاس</h2>
      <p>
        اسانس گیلاس به دلیل خواص آرامش‌بخش و تقویت‌کننده خود، می‌تواند به بهبود خلق و خو و کاهش استرس کمک کند. همچنین، این اسانس برای تقویت سیستم ایمنی بدن و بهبود کیفیت خواب مفید است.
      </p>
      <h2>مزایای استفاده از اسانس گیلاس در محصولات</h2>
      <p>
        استفاده از اسانس گیلاس در محصولات مختلف علاوه بر ایجاد طعم و عطر خوشایند، مزایای متعددی دارد. این اسانس می‌تواند به بهبود وضعیت پوست و افزایش شادابی و طراوت آن کمک کند و همچنین در ایجاد محصولات با ویژگی‌های طبیعی و سالم مؤثر باشد.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس گیلاس به‌عنوان یک ماده طبیعی در محصولات درمانی مانند روغن‌های ماساژ و لوسیون‌های مرطوب‌کننده استفاده می‌شود. این اسانس می‌تواند به کاهش استرس، تقویت سیستم ایمنی بدن و افزایش انرژی کمک کند.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس گیلاس</h2>
      <p>
        اسانس گیلاس با طعمی شیرین و خوشبو و خواص تقویت‌کننده خود، به‌عنوان یک ترکیب منحصر به فرد در محصولات مختلف شناخته می‌شود. این اسانس به حفظ طراوت پوست و سلامت بدن کمک کرده و تجربه‌ای طبیعی و خوشایند برای مصرف‌کنندگان فراهم می‌کند.
      </p>
      <p>
        استفاده از اسانس گیلاس در محصولات مختلف، می‌تواند تجربه‌ای لذت‌بخش و سالم برای مصرف‌کنندگان ایجاد کند که در کنار مزایای درمانی، به طعم و عطر بی‌نظیر محصولات نیز افزوده می‌شود.
      </p>
    </div>
  ),
}
,
{
  id: 44,
  img: p44,
  title: 'اسانس شکلات تلخ',
  description: 'اسانس شکلات تلخ با طعم غنی و تلخ خود، یکی از اسانس‌های مورد علاقه در صنایع غذایی، دسرها و نوشیدنی‌ها است. این اسانس به دلیل ترکیبات خاص و طعم خاص خود، برای ایجاد طعم‌های عمیق و ماندگار استفاده می‌شود.',
  metaDescription:
    'اسانس شکلات تلخ با طعم غنی و تلخ، مناسب برای محصولات دسر، شکلات و نوشیدنی. خرید اسانس شکلات تلخ با کیفیت بالا برای استفاده در محصولات طبیعی و لذیذ.',
  body: (
    <div class="description-box">
      <p>
        اسانس شکلات تلخ از شکلات‌های با کیفیت بالا استخراج می‌شود و دارای طعمی عمیق، تلخ و غنی است. این اسانس به‌طور گسترده در تولید انواع شکلات‌ها، دسرها، نوشیدنی‌ها و حتی برخی از محصولات آرایشی کاربرد دارد.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس شکلات تلخ در صنایع غذایی برای تولید شکلات‌ها، کیک‌ها، شیرینی‌ها، دسرها و نوشیدنی‌ها استفاده می‌شود. طعم تلخ و غنی این اسانس باعث ایجاد طعم‌های پیچیده و لذیذ در محصولات می‌شود و مصرف‌کنندگان را به تجربه یک لذت ناب از شکلات دعوت می‌کند.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس شکلات تلخ علاوه بر طعم خوشمزه‌ای که دارد، دارای خواص آنتی‌اکسیدانی است. این اسانس می‌تواند در تولید داروهای طبیعی و مکمل‌های غذایی برای بهبود سلامت قلب و عروق و کاهش استرس و اضطراب به‌کار رود.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس شکلات تلخ در صنعت آرایشی و بهداشتی به‌دلیل خواص ضدپیری و آنتی‌اکسیدانی خود استفاده می‌شود. این اسانس می‌تواند در تولید محصولات مراقبتی پوست و مو مانند کرم‌ها و لوسیون‌ها برای جلوگیری از چین و چروک و حفظ سلامت پوست مفید باشد.
      </p>
      <h2>خواص درمانی اسانس شکلات تلخ</h2>
      <p>
        اسانس شکلات تلخ علاوه بر طعم و عطر لذیذ خود، خواص درمانی متعددی دارد. این اسانس به‌دلیل غنی بودن از آنتی‌اکسیدان‌ها می‌تواند به کاهش استرس، بهبود خلق و خو و حتی تقویت حافظه کمک کند. همچنین مصرف شکلات تلخ به‌عنوان یک منبع طبیعی انرژی و تقویت‌کننده قلب و عروق شناخته می‌شود.
      </p>
      <h2>مزایای استفاده از اسانس شکلات تلخ در محصولات</h2>
      <p>
        استفاده از اسانس شکلات تلخ در محصولات مختلف نه تنها طعم و عطر دلپذیر شکلات را به ارمغان می‌آورد، بلکه خواص درمانی و تقویت‌کننده آن نیز به افزایش کیفیت محصولات کمک می‌کند. این اسانس به‌ویژه در صنایع شکلات‌سازی و تولید دسرهای پرطرفدار کاربرد زیادی دارد.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس شکلات تلخ به‌دلیل خواص آنتی‌اکسیدانی و انرژی‌بخش خود، در برخی محصولات درمانی برای افزایش سطح انرژی، کاهش خستگی و بهبود خلق و خو به‌کار می‌رود. این اسانس در تولید روغن‌های ماساژ و پمادهای مراقبتی نیز می‌تواند مؤثر باشد.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس شکلات تلخ</h2>
      <p>
        اسانس شکلات تلخ با ترکیبات فعال خود مانند فلاونوئیدها، به‌عنوان یک آنتی‌اکسیدان طبیعی شناخته می‌شود که به محافظت از سلول‌ها در برابر آسیب‌های اکسیداتیو کمک می‌کند. این اسانس همچنین به بهبود گردش خون و کاهش التهاب‌ها نیز کمک می‌کند.
      </p>
      <p>
        استفاده از اسانس شکلات تلخ در محصولات مختلف نه تنها تجربه‌ای لذت‌بخش از طعم شکلات را به ارمغان می‌آورد، بلکه خواص درمانی و طبیعی آن باعث بهبود سلامت و تندرستی مصرف‌کنندگان نیز می‌شود.
      </p>
    </div>
  ),
}
,
{
  id: 45,
  img: p45,
  title: 'اسانس سبزیجات',
  description: 'اسانس سبزیجات با طعم و بوی طبیعی و تازه، برای افزودن عمق و طعم به غذاها و محصولات مختلف استفاده می‌شود. این اسانس با ترکیبات گیاهی و طبیعی خود، طعمی تند و تازه به محصولات می‌بخشد.',
  metaDescription:
    'اسانس سبزیجات با طعم و بوی طبیعی، مناسب برای افزودن به غذاها و محصولات طبیعی. خرید اسانس سبزیجات با کیفیت بالا برای استفاده در انواع غذاها و محصولات بهداشتی.',
  body: (
    <div class="description-box">
      <p>
        اسانس سبزیجات از گیاهان تازه و طبیعی استخراج می‌شود و دارای طعم و بویی تند و تازه است که به‌طور گسترده در صنایع مختلف مانند غذایی، دارویی و آرایشی مورد استفاده قرار می‌گیرد.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس سبزیجات به‌عنوان طعم‌دهنده در غذاها، سس‌ها، سوپ‌ها و خوراک‌ها استفاده می‌شود. طعم تازه و طبیعی این اسانس می‌تواند به تقویت طعم و مزه انواع غذاهای گیاهی، خوراک‌های پروتئینی و حتی نوشیدنی‌ها کمک کند. این اسانس به‌ویژه در محصولات فاقد مواد نگهدارنده و افزودنی‌های شیمیایی بسیار محبوب است.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس سبزیجات علاوه بر طعم‌دهی، به دلیل خواص ضدباکتریال و آنتی‌اکسیدانی خود در صنعت داروسازی نیز کاربرد دارد. این اسانس می‌تواند در تولید محصولات طبیعی برای درمان التهابات و تقویت سیستم ایمنی بدن به‌کار رود.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس سبزیجات به دلیل خواص ضدعفونی‌کننده و ترمیم‌کننده در محصولات آرایشی و بهداشتی کاربرد دارد. این اسانس می‌تواند در تولید کرم‌ها، لوسیون‌ها، صابون‌ها و شامپوها به‌عنوان یک عنصر طبیعی برای حفظ سلامت پوست و مو استفاده شود.
      </p>
      <h2>خواص درمانی اسانس سبزیجات</h2>
      <p>
        اسانس سبزیجات به‌عنوان یک درمان طبیعی برای بسیاری از مشکلات گوارشی و التهابات شناخته شده است. این اسانس می‌تواند به بهبود سیستم گوارشی، کاهش نفخ و تسکین مشکلات معده کمک کند. همچنین، اسانس سبزیجات به‌دلیل خواص آنتی‌اکسیدانی خود می‌تواند در تقویت سیستم ایمنی بدن مؤثر باشد.
      </p>
      <h2>مزایای استفاده از اسانس سبزیجات در محصولات</h2>
      <p>
        استفاده از اسانس سبزیجات در محصولات مختلف علاوه بر ایجاد طعم و بوی طبیعی، به‌عنوان یک عنصر مفید در ارتقای سلامت نیز عمل می‌کند. این اسانس می‌تواند به‌عنوان یک افزودنی طبیعی در غذاها و همچنین در محصولات مراقبتی پوست و مو برای ایجاد خواص درمانی و مراقبتی استفاده شود.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس سبزیجات به دلیل خواص آرامش‌بخش و ضدالتهابی خود در محصولات درمانی مختلف مانند روغن‌های ماساژ، پمادها و بخورها کاربرد دارد. این اسانس می‌تواند به کاهش التهابات پوستی و عضلانی کمک کند و در بهبود کیفیت خواب و آرامش ذهنی مؤثر باشد.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس سبزیجات</h2>
      <p>
        اسانس سبزیجات با داشتن ترکیبات گیاهی و طبیعی قدرتمند، به‌عنوان یک آنتی‌اکسیدان قوی عمل کرده و به محافظت از سلول‌ها در برابر آسیب‌های محیطی کمک می‌کند. این اسانس همچنین دارای خواص ضدباکتریایی و ضدویروسی است که می‌تواند در پیشگیری از بیماری‌ها و تقویت سیستم ایمنی بدن مؤثر باشد.
      </p>
      <p>
        استفاده از اسانس سبزیجات در محصولات مختلف می‌تواند تجربه‌ای طبیعی و سالم برای مصرف‌کنندگان فراهم کند و به‌عنوان یک عنصر مفید در حفظ سلامت و تندرستی به‌کار رود.
      </p>
    </div>
  ),
}
,
{
  id: 46,
  img: p46,
  title: 'اسانس زرشک',
  description: 'اسانس زرشک با طعم و بوی ترش و تازه، به‌عنوان یک طعم‌دهنده طبیعی در صنایع غذایی، دارویی و آرایشی استفاده می‌شود. این اسانس به دلیل خواص ضدالتهابی و آنتی‌اکسیدانی، در بسیاری از محصولات درمانی و بهداشتی کاربرد دارد.',
  metaDescription:
    'اسانس زرشک با طعم ترش و خواص آنتی‌اکسیدانی، مناسب برای استفاده در صنایع غذایی، دارویی و آرایشی. خرید اسانس زرشک برای طعم‌دهی به محصولات طبیعی و درمانی.',
  body: (
    <div class="description-box">
      <p>
        اسانس زرشک از میوه‌های زرشک استخراج می‌شود و به دلیل طعم ترش و بوی خاص خود شناخته شده است. این اسانس در صنایع مختلف مانند غذایی، دارویی و آرایشی به‌طور گسترده مورد استفاده قرار می‌گیرد و به عنوان یک افزودنی طبیعی و درمانی شناخته می‌شود.
      </p>
      <h2>کاربرد در صنعت غذایی</h2>
      <p>
        اسانس زرشک به‌عنوان یک طعم‌دهنده طبیعی در غذاهای مختلف استفاده می‌شود. این اسانس با طعم ترش و تازه‌ای که دارد، در تولید انواع سس‌ها، نوشیدنی‌ها، دسرها، کیک‌ها و مرباها کاربرد دارد. همچنین می‌تواند به‌عنوان یک افزودنی طبیعی در محصولات گوشتی و ماهی استفاده شود.
      </p>
      <h2>کاربرد در صنعت داروسازی</h2>
      <p>
        اسانس زرشک به دلیل خواص ضدالتهابی و آنتی‌اکسیدانی، در صنعت داروسازی نیز کاربردهای فراوانی دارد. این اسانس می‌تواند در درمان بیماری‌های گوارشی و التهابات پوست و بدن مفید باشد و در تولید داروها و مکمل‌های طبیعی مورد استفاده قرار گیرد.
      </p>
      <h2>کاربرد در صنعت آرایشی و بهداشتی</h2>
      <p>
        اسانس زرشک به دلیل خواص ضدباکتریایی و آنتی‌اکسیدانی خود، در محصولات آرایشی و بهداشتی کاربرد دارد. این اسانس می‌تواند در تولید کرم‌ها، لوسیون‌ها، صابون‌ها و شامپوها برای بهبود سلامت پوست و مو استفاده شود. همچنین، اسانس زرشک به درمان مشکلات پوستی مانند آکنه و التهاب‌های پوستی کمک می‌کند.
      </p>
      <h2>خواص درمانی اسانس زرشک</h2>
      <p>
        اسانس زرشک به‌عنوان یک درمان طبیعی برای مشکلات گوارشی و التهابات شناخته شده است. این اسانس می‌تواند به بهبود عملکرد کبد و تسکین بیماری‌های مرتبط با آن کمک کند. همچنین، اسانس زرشک به‌عنوان یک آنتی‌اکسیدان قوی، می‌تواند به محافظت از بدن در برابر آسیب‌های ناشی از رادیکال‌های آزاد کمک کند.
      </p>
      <h2>مزایای استفاده از اسانس زرشک در محصولات</h2>
      <p>
        استفاده از اسانس زرشک در محصولات مختلف علاوه بر ایجاد طعم و بوی منحصر به فرد، می‌تواند به تقویت خواص درمانی و مراقبتی محصولات کمک کند. این اسانس در محصولات غذایی می‌تواند به‌عنوان یک افزودنی طبیعی برای طعم‌دهی و در محصولات دارویی و آرایشی برای خواص درمانی و بهبود پوست و مو مفید باشد.
      </p>
      <h2>کاربرد در محصولات درمانی</h2>
      <p>
        اسانس زرشک به دلیل خواص ضدالتهابی و آنتی‌اکسیدانی خود در محصولات درمانی مانند روغن‌های ماساژ، پمادها و بخورها استفاده می‌شود. این اسانس می‌تواند به تسکین دردهای عضلانی، کاهش التهاب‌ها و بهبود کیفیت خواب کمک کند.
      </p>
      <h2>ویژگی‌های منحصر به فرد اسانس زرشک</h2>
      <p>
        اسانس زرشک با ترکیبات طبیعی و قدرتمند خود، به‌عنوان یک آنتی‌اکسیدان قوی عمل کرده و به محافظت از بدن در برابر آسیب‌های محیطی کمک می‌کند. این اسانس همچنین دارای خواص ضدباکتریایی و ضدویروسی است که می‌تواند در پیشگیری از بیماری‌ها مؤثر باشد.
      </p>
      <p>
        استفاده از اسانس زرشک در محصولات مختلف می‌تواند تجربه‌ای طبیعی و سالم برای مصرف‌کنندگان فراهم کند و به‌عنوان یک عنصر مفید در حفظ سلامت و تندرستی به‌کار رود.
      </p>
    </div>
  ),
}

  

];

export function Mahsulat() {
  const { isDarkMode } = useTheme();
  const { producttitle } = useParams(); // دریافت عنوان از URL
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }, [isDarkMode]);

  // حذف فاصله‌ها از عنوان و مقایسه با encodeURIComponent
  const product = products.find(
    (p) => encodeURIComponent(p.title.toLowerCase()) === encodeURIComponent(producttitle.toLowerCase())
  );

  if (!product) {
    return <h2>محصول یافت نشد</h2>;
  }

  return (
    <div className="product-detail">
      
      <Helmet>
        <title>{product.title}</title>
        <meta
          name="description"
          content={product.metaDescription || product.description}
        />
        <meta
          name="keywords"
          content="اسانس کره، طعم‌دهنده کره، اسانس شیر کندانسه، صنایع غذایی، خرید اسانس"
        />
      </Helmet>
      <img src={product.img} alt={product.title} />
      <h1>{product.title}</h1>
      <div>{product.body}</div>
    </div>
  );
}

export default Mahsulat;
