import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, useTheme } from './Components/ThemeContext/ThemeContext';
import Navebar from './Components/Navebar/Navebar';
import Hero from './Components/Hero/Hero1';
import Program from './Components/program/Program';
import About from './Components/About/About';
import Products from './Components/Products/Products';
import Contact from './Components/Contact/Contact';
import Mahsulat from './Components/Mahsulat/Mahsulat';
import { MarqueeDemo } from './Components/Animations/MarqueeDemo';
import SocialMediaSection from './Components/Footer/Footer';
import Slider from './Components/Slider/Slider';
import English from './Components/Englishpage/English';
import SocialMediaSectionen from './Components/Footer-en/Footer-en';
import Snowfall from './Components/Snow/FakeSnow';
import './index.css';

// صفحه خانگی
const HomePage = () => {
  return (
    <>
    {/* <Snowfall/> */}
      <Slider />
      <Program />
      <About />
      <MarqueeDemo />
    </>
  );
};

// برای تنظیم نوار ناوبری و اضافه کردن فوتر
const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isEnglishPage = location.pathname === '/english';
  const isContactPage = location.pathname === '/contact';

  const { theme } = useTheme();

  // ذخیره مسیر فعلی در history.state
  useEffect(() => {
    window.history.replaceState({}, document.title, location.pathname);
  }, [location.pathname]);

  // برگرداندن به صفحه قبلی پس از رفرش
  useEffect(() => {
    const savedPage = window.history.state ? window.history.state.page : null;
    if (savedPage) {
      navigate(savedPage);
    }
  }, [navigate]);

  // اعمال تم به کل صفحه از طریق body
  useEffect(() => {
    document.body.className = theme; // اضافه کردن کلاس تم به body
  }, [theme]);

  return (
    <div>
      <Navebar />
      <main>{children}</main>
      {!isEnglishPage && !isContactPage && <Contact />}
      {isEnglishPage ? <SocialMediaSectionen /> : <SocialMediaSection />}
    </div>
  );
};

// کامپوننت اصلی اپلیکیشن
const App = () => {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="/services"
            element={
              <Layout>
                <Program />
              </Layout>
            }
          />
          <Route
            path="/mahsulat"
            element={
              <Layout>
                <Products />
              </Layout>
            }
          />
          <Route
            path="/mahsulat/:producttitle"
            element={
              <Layout>
                <Mahsulat />
              </Layout>
            }
          />
          <Route
            path="/english"
            element={
              <Layout>
                <English />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
