import React ,{useState,useEffect} from 'react';
import { useTheme } from '../ThemeContext/ThemeContext';
import { Link } from 'react-router-dom';

import p1 from "../../assets/porteghal.jpg";
import p2 from "../../assets/hendune.png";
import p3 from "../../assets/albaloo.png";
import p4 from "../../assets/talebi.png";
import p5 from "../../assets/ananas.png";
import p6 from "../../assets/but.png";
import p8 from "../../assets/cofee.png";
import p9 from "../../assets/choco.png";
import p10 from "../../assets/vanil.png";
import p11 from "../../assets/coco.png";
import p12 from "../../assets/stra.png";
import p13 from "../../assets/shah.png";
import p14 from "../../assets/but.png";
import p15 from "../../assets/mango.png";
import p16 from "../../assets/fan.png";
import p17 from "../../assets/shir3.png";
import p18 from "../../assets/greentea.png";
import p19 from "../../assets/alovera.png";
import p20 from "../../assets/olive.png";
import p21 from "../../assets/benanamilk.png";
import p22 from "../../assets/lemon.png";
import p23 from "../../assets/zardalu.jpg";
import p24 from "../../assets/khorma.png";
import p25 from "../../assets/reyhan.png";
import p27 from "../../assets/naena.png";
import p28 from "../../assets/peste.png";
import p29 from "../../assets/milkcar.png";
import p30 from "../../assets/tropical.png";
import p31 from "../../assets/narenj.png";
import p32 from "../../assets/zanjebil.png";
import p33 from "../../assets/rose.png";
import p34 from "../../assets/avish.png";
import p35 from "../../assets/mikhak.png";

import p36 from "../../assets/badam.png";
import p37 from "../../assets/tut.png";
import p38 from "../../assets/zaferan.png";
import p39 from "../../assets/holo.png";
import p40 from "../../assets/greenapple.png";
import p41 from "../../assets/golabi.png";
import p42 from "../../assets/drachin.png";
import p43 from "../../assets/gilas.png";
import p44 from "../../assets/shokolat.png";
import p45 from "../../assets/veg.png";
import p46 from "../../assets/zer.png";

import './Products.css';


const products = [
  { 
    id: 1, 
    img: p1, 
    title: 'اسانس پرتقال', 
    description: 'اسانس پرتقال طبیعی یکی از اسانس‌های محبوب در صنایع غذایی است که برای طعم‌دهی به محصولات متنوع مانند کیک، بیسکویت، تافی، آبنبات و نوشیدنی‌ها استفاده می‌شود. این اسانس با رایحه تازه و دلپذیر خود، علاوه بر طعم دهی، خواص طبیعی پرتقال را نیز به ارمغان می‌آورد. اسانس پرتقال برای استفاده در صنایع لبنی و تنباکو نیز مناسب است. انواع مختلفی از این اسانس شامل پرتقال والنسیا، پرتقال تلخ و شیرین، هرکدام ویژگی‌های خاص خود را دارند.' 
  },
  { 
    id: 2, 
    img: p2, 
    title: 'اسانس هندوانه', 
    description: 'اسانس هندوانه یکی از محبوب‌ترین اسانس‌ها در صنایع نوشیدنی و غذایی است. با طعم و عطر طبیعی هندوانه، این اسانس در تولید نوشیدنی‌ها، دسرها و کیک‌ها به کار می‌رود. علاوه بر طعم‌دهی، اسانس هندوانه خواص ضد التهابی و آنتی‌اکسیدانی دارد که به سلامت بدن کمک می‌کند. این اسانس به‌ویژه در تولید محصولات تابستانی و خنک‌کننده بسیار محبوب است.' 
  },
  { 
    id: 3, 
    img: p3, 
    title: 'اسانس آلبالو', 
    description: 'اسانس آلبالو طبیعی با طعم ترش و شیرین خود یکی از محبوب‌ترین طعم‌دهنده‌ها در صنعت دسر و نوشیدنی است. این اسانس به‌ویژه در تولید انواع ژله‌ها، مرباها، نوشیدنی‌های گازدار و شکلات‌ها کاربرد دارد. اسانس آلبالو نه تنها طعم و عطر عالی به محصولات می‌دهد، بلکه خواص ضد میکروبی و ضد التهابی نیز دارد. این اسانس در صنعت آرایشی و بهداشتی نیز برای تولید محصولات مراقبتی پوست کاربرد دارد.' 
  },
  { 
    id: 4, 
    img: p4, 
    title: 'اسانس طالبی', 
    description: 'اسانس طالبی با رایحه ملایم و خوشبو خود، یک گزینه عالی برای استفاده در صنایع غذایی و بهداشتی است. این اسانس در تولید نوشیدنی‌ها، بستنی‌ها، کیک‌ها و انواع دسرها کاربرد دارد. اسانس طالبی با طعم دلپذیر خود، به محصولات غذایی طعم طبیعی و جذاب می‌بخشد. علاوه بر این، اسانس طالبی در صنعت آرایشی نیز برای تولید کرم‌ها و لوسیون‌ها به‌کار می‌رود.' 
  },
  { 
    id: 5, 
    img: p5, 
    title: 'اسانس اناناس', 
    description: 'اسانس اناناس طبیعی به دلیل طعم گرمسیری و منحصر به‌فرد خود، در صنایع غذایی، به ویژه در تولید نوشیدنی‌ها و دسرها، بسیار پرکاربرد است. این اسانس علاوه بر استفاده در کیک‌ها و شیرینی‌جات، در تولید محصولات آرایشی بهداشتی نیز به کار می‌رود. اسانس اناناس دارای خواص آنتی‌اکسیدانی است که به تقویت سیستم ایمنی بدن کمک می‌کند و برای استفاده در محصولات طبیعی مناسب است.' 
  },
  { 
    id: 6, 
    img: p6, 
    title: 'اسانس کره', 
    description: 'اسانس کره یکی از اسانس‌های پرکاربرد در صنایع غذایی است. این اسانس در صنایع لبنی، به ویژه برای طعم‌دهی به کره‌ها، بستنی‌ها و پنیر پیتزا استفاده می‌شود. اسانس کره در تولید محصولات کیک و شیرینی، به‌ویژه تافی‌ها و انواع شکلات، نیز کاربرد دارد. این اسانس دارای رایحه گرم و شیرین است و در دسرها و نوشیدنی‌ها نیز طعم فوق‌العاده‌ای به ارمغان می‌آورد. اسانس کره با مقاومت حرارتی بالا، گزینه‌ای مناسب برای تولید محصولات با ماندگاری طولانی است.' 
  },
  {
  id: 16,
  img: p16,
  title: 'اسانس فندق',
  description: 'اسانس فندق با طعم غنی و خوشمزه‌اش، یکی از اسانس‌های پرطرفدار در صنعت غذایی است. این اسانس در تولید انواع کیک‌ها، شکلات‌ها، شیرینی‌ها، قهوه و نوشیدنی‌ها کاربرد دارد. اسانس فندق به‌دلیل عطر دلپذیر و طعم بی‌نظیر خود، در دسرها و محصولات شیرینی‌پزی به‌ویژه شکلات‌ها و تافی‌ها بسیار مورد استفاده قرار می‌گیرد. علاوه بر طعم‌دهی، اسانس فندق خواص آنتی‌اکسیدانی دارد و در صنعت آرایشی برای ایجاد رایحه‌های خاص نیز به‌کار می‌رود.'
}
 , { 
    id: 17, 
    img: p17, 
    title: 'اسانس شیر کندانسه', 
    description: 'اسانس شیر کندانسه یکی از اسانس‌های محبوب در صنعت شیرینی‌سازی و تولید محصولات غذایی است. این اسانس طعم شیرین و ملایم شیر کندانسه را به دسرها، کیک‌ها و شکلات‌ها می‌بخشد. علاوه بر استفاده در صنعت غذایی، اسانس شیر کندانسه در تولید محصولات آرایشی نیز کاربرد دارد و رایحه‌ای خوشایند به لوازم بهداشتی می‌بخشد.' 
  },
  { 
    id: 8, 
    img: p8, 
    title: 'اسانس قهوه', 
    description: 'اسانس قهوه طبیعی با طعم تلخ و دلپذیر خود، یکی از محبوب‌ترین اسانس‌ها در صنعت نوشیدنی و دسر است. این اسانس در تولید قهوه، کیک‌ها، شیرینی‌جات و شکلات‌ها به کار می‌رود و طعمی فوق‌العاده به محصولات می‌دهد. اسانس قهوه همچنین در محصولات آرایشی و بهداشتی نیز استفاده می‌شود و عطر خوشبویی به لوسیون‌ها و کرم‌ها می‌بخشد.' 
  },
  { 
    id: 9, 
    img: p9, 
    title: 'اسانس شکلات', 
    description: 'اسانس شکلات یکی از اسانس‌های پرطرفدار در صنایع غذایی است که در تولید انواع کیک‌ها، شکلات‌ها، شیرینی‌جات و دسرها کاربرد دارد. این اسانس با طعم غنی و دلپذیر خود، به محصولات طعم و عطر عالی می‌دهد. اسانس شکلات در صنایع آرایشی و بهداشتی نیز برای تولید لوازم بهداشتی با رایحه شیرین و جذاب مورد استفاده قرار می‌گیرد.' 
  },
  { 
    id: 10, 
    img: p10, 
    title: 'اسانس وانیل', 
    description: 'اسانس وانیل یکی از پرکاربردترین اسانس‌ها در صنایع غذایی و آرایشی است. این اسانس در تولید انواع دسرها، کیک‌ها، نوشیدنی‌ها و شکلات‌ها استفاده می‌شود. اسانس وانیل به‌ویژه در تولید محصولات آرایشی مانند عطرها و کرم‌ها نیز کاربرد دارد. با رایحه خوشبوی و شیرین خود، اسانس وانیل طعم و عطر طبیعی و دلپذیری به محصولات می‌دهد.' 
  },
   {
  id: 11,
  img: p11,
  title: 'اسانس نارگیل',
  description: 'اسانس نارگیل با طعم و عطر گرم و شیرین خود، یکی از محبوب‌ترین اسانس‌ها در صنعت غذایی و نوشیدنی است. این اسانس به‌طور گسترده در تولید انواع کیک‌ها، شیرینی‌ها، دسرها و نوشیدنی‌ها استفاده می‌شود. علاوه بر طعم‌دهی فوق‌العاده، اسانس نارگیل خواص مرطوب‌کننده و ضدالتهابی دارد که در محصولات آرایشی و بهداشتی نیز کاربرد دارد. این اسانس برای کسانی که به دنبال طعمی طبیعی و استوایی در محصولات خود هستند، گزینه‌ای عالی محسوب می‌شود.'
}
 , { 
    id: 12, 
    img: p12, 
    title: 'اسانس توت فرنگی', 
    description: 'اسانس توت فرنگی با طعم شیرین و عطر دلپذیر خود، یکی از پرطرفدارترین اسانس‌ها در صنعت شیرینی‌سازی و دسرها است. این اسانس در تولید کیک‌ها، مرباها، نوشیدنی‌ها و بستنی‌ها کاربرد دارد. علاوه بر این، اسانس توت فرنگی دارای خواص ضد التهابی است و در محصولات آرایشی بهداشتی نیز به کار می‌رود.' 
  },
  { 
    id: 13, 
    img: p13, 
    title: 'اسانس شاه توت', 
    description: 'اسانس شاه توت با طعم منحصر به‌فرد و عطر دلپذیر خود، در تولید انواع نوشیدنی‌ها، دسرها، کیک‌ها و مرباها استفاده می‌شود. این اسانس علاوه بر طعم‌دهی، خواص آنتی‌اکسیدانی و ضد میکروبی دارد و در تولید محصولات آرایشی نیز به‌کار می‌رود.' 
  },
  { 
    id: 15, 
    img: p15, 
    title: 'اسانس انبه', 
    description: 'اسانس انبه طبیعی با طعم شیرین و آبدار خود، در تولید انواع نوشیدنی‌ها، دسرها، بستنی‌ها و کیک‌ها کاربرد دارد. این اسانس طعمی شگفت‌انگیز و رایحه‌ای گرمسیری به محصولات می‌دهد. اسانس انبه علاوه بر صنعت غذایی، در تولید محصولات آرایشی و بهداشتی نیز به‌کار می‌رود.' 
  }
  ,{
  id: 18,
  img: p18,
  title: 'اسانس چای سبز',
  description: 'اسانس چای سبز با رایحه طبیعی و ملایم خود، یکی از اسانس‌های محبوب در صنعت نوشیدنی و دسر است. این اسانس علاوه بر افزودن طعم دلپذیر به چای، دمنوش‌ها، کیک‌ها و شکلات‌ها، خواص ضدoxidant و آرامش‌بخش دارد. اسانس چای سبز به‌دلیل ویژگی‌های سلامتی خود، در محصولات آرایشی نیز کاربرد دارد و در کرم‌ها و لوسیون‌ها به‌عنوان یک ترکیب تسکین‌دهنده و ضد التهاب استفاده می‌شود. این اسانس به تولید محصولاتی با خواص آرامش‌بخش و انرژی‌زا در صنایع مختلف کمک می‌کند.'
}
,{
  id: 19,
  img: p19,
  title: 'اسانس آلوئه ورا',
  description: 'اسانس آلوئه ورا با خواص درمانی و آرامش‌بخش خود، یکی از محبوب‌ترین اسانس‌ها در صنعت محصولات بهداشتی و آرایشی است. این اسانس به دلیل ویژگی‌های مرطوب‌کنندگی و ضد التهابی، در تولید کرم‌ها، لوسیون‌ها و ژل‌های شستشو به کار می‌رود. علاوه بر کاربردهای بهداشتی، اسانس آلوئه ورا در صنایع غذایی نیز استفاده می‌شود، به‌ویژه در نوشیدنی‌ها و دسرها به دلیل طعم طبیعی و ملایم آن. این اسانس کمک به تسکین پوست و تقویت سیستم ایمنی بدن می‌کند.'
}
,{
  id: 20,
  img: p20,
  title: 'اسانس زیتون',
  description: 'اسانس زیتون با عطر و طعم طبیعی و خواص بی‌نظیر خود، در صنایع غذایی و آرایشی کاربرد گسترده‌ای دارد. در صنایع غذایی، این اسانس برای طعم‌دهی به انواع روغن‌ها، نان‌ها، سس‌ها و محصولات مدیترانه‌ای استفاده می‌شود. همچنین، به دلیل خواص آنتی‌اکسیدانی و نرم‌کنندگی، در تولید محصولات مراقبتی پوست و مو، مانند کرم‌ها و شامپوها، بسیار مورد توجه است. اسانس زیتون با رایحه‌ای ملایم و دلنشین، کیفیت محصولات را بهبود می‌بخشد و به‌عنوان یک گزینه طبیعی و سالم در تولیدات مختلف شناخته می‌شود.'
}
,{
  id: 21,
  img: p21,
  title: 'اسانس شیر موز',
  description: 'اسانس شیر موز با ترکیبی از طعم لطیف موز و بافت کرمی شیر، یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی است. این اسانس برای تولید انواع نوشیدنی‌های آماده، بستنی‌ها، دسرها و کیک‌ها به‌کار می‌رود. رایحه گرم و طبیعی شیر موز تجربه‌ای خوشایند برای مصرف‌کننده ایجاد می‌کند و طعمی جذاب به محصولات می‌بخشد. اسانس شیر موز علاوه بر طعم‌دهی، به ایجاد حس تازه و انرژی‌بخش در محصولات کمک می‌کند و گزینه‌ای ایده‌آل برای تولیدات خلاقانه محسوب می‌شود.'
}
,{
  id: 22,
  img: p22,
  title: 'اسانس لیمو ترش',
  description: 'اسانس لیمو ترش با عطر تازه و طعم تند و دلپذیر، یکی از پرکاربردترین اسانس‌ها در صنایع غذایی و آرایشی است. این اسانس در تولید نوشیدنی‌های گازدار و بدون گاز، کیک‌ها، دسرها و آب‌نبات‌ها به‌کار می‌رود. خاصیت طراوت‌بخشی و خنک‌کنندگی لیمو ترش، محصولات را متمایز می‌سازد و حس تازگی و شادابی به مصرف‌کننده القا می‌کند. اسانس لیمو ترش همچنین به دلیل دارا بودن خواص آنتی‌اکسیدانی و ضد باکتریایی، در محصولات آرایشی و بهداشتی نیز استفاده می‌شود.'
}
,
{ 
  id: 23, 
  img: p23, 
  title: 'اسانس زردآلو', 
  description: 'اسانس زردآلو با طعمی شیرین و خوشمزه، یکی از اسانس‌های محبوب برای استفاده در محصولات مختلف مانند کیک‌ها، بستنی‌ها، نوشیدنی‌ها و دسرها است. این اسانس با رایحه طبیعی و لذت‌بخش خود، طعمی دلپذیر به محصولات می‌بخشد و در صنایع غذایی و نوشیدنی‌ها کاربرد دارد.'
}
,{ 
  id: 24, 
  img: p24, 
  title: 'اسانس خرما', 
  description: 'اسانس خرما با طعمی شیرین و غنی، یکی از اسانس‌های محبوب برای استفاده در محصولات مختلف مانند کیک‌ها، دسرها، نوشیدنی‌ها و شیرینی‌ها است.'
}
,{ 
  id: 25, 
  img: p25, 
  title: 'اسانس ریحان', 
  description: 'اسانس ریحان با عطر تازه و طعمی دلپذیر، مناسب برای استفاده در محصولات غذایی مانند سس‌ها، سوپ‌ها، و محصولات لبنی است. همچنین در صنایع آرایشی و بهداشتی برای رایحه‌درمانی کاربرد دارد.'
}
,{ 
  id: 27, 
  img: p27, 
  title: 'اسانس نعناع', 
  description: 'اسانس نعناع با عطر خنک و طعم تند و تازه، مناسب برای محصولات غذایی مانند آدامس‌ها، شکلات‌ها، نوشیدنی‌ها و دسرها است. همچنین در محصولات بهداشتی و آرایشی مانند خمیردندان‌ها و خوشبوکننده‌ها کاربرد دارد.'
}
,{ 
  id: 28, 
  img: p28, 
  title: 'اسانس پسته', 
  description: 'اسانس پسته با عطر و طعم غنی و خاص، مناسب برای استفاده در محصولات قنادی مانند کیک‌ها، بستنی‌ها، شیرینی‌ها و دسرها است. همچنین در صنایع لبنی و محصولات غذایی لوکس کاربرد دارد.'
},
{ 
  id: 29, 
  img: p29, 
  title: 'اسانس شیر کارامل', 
  description: 'اسانس شیر کارامل با عطر و طعمی شیرین و جذاب، مناسب برای استفاده در محصولات قنادی مانند کیک‌ها، دسرها، نوشیدنی‌های گرم و سرد، و بستنی‌ها است. این اسانس حس لطیف و خاصی به محصولات غذایی اضافه می‌کند.'
}

,
{ 
  id: 30, 
  img: p30, 
  title: 'اسانس استوایی', 
  description: 'اسانس استوایی با ترکیبی از عطر و طعم میوه‌های گرمسیری مانند انبه، آناناس و نارگیل، مناسب برای استفاده در نوشیدنی‌ها، دسرها، بستنی‌ها و محصولات قنادی است. این اسانس حس تازگی و طراوت مناطق استوایی را به محصولات اضافه می‌کند.'
}

,
{ 
  id: 31, 
  img: p31, 
  title: 'اسانس نارنج', 
  description: 'اسانس نارنج با رایحه‌ای شاداب و طعمی ترش و ملایم، مناسب برای استفاده در محصولات غذایی مانند مرباها، نوشیدنی‌ها، دسرها و شیرینی‌ها است. همچنین در صنایع آرایشی و بهداشتی برای تولید عطرها و کرم‌های معطر کاربرد دارد.'
}
,
{ 
  id: 32, 
  img: p32, 
  title: 'اسانس زنجبیل', 
  description: 'اسانس زنجبیل با طعمی تند و گرم و رایحه‌ای خاص، مناسب برای استفاده در محصولات غذایی مانند کیک‌ها، نوشیدنی‌های گرم، سوپ‌ها و سس‌ها است. همچنین در صنایع دارویی و آرایشی به دلیل خواص آنتی‌اکسیدانی و ضدالتهابی آن کاربرد دارد.'
}
,
{ 
  id: 33, 
  img: p33, 
  title: 'اسانس گل محمدی', 
  description: 'اسانس گل محمدی با رایحه‌ای ملایم و دلپذیر، مناسب برای استفاده در محصولات غذایی مانند شربت‌ها، دسرها، و شیرینی‌ها است. همچنین در صنایع آرایشی و بهداشتی برای تولید عطرها، کرم‌ها و محصولات مراقبت از پوست کاربرد فراوان دارد.'
}
,
{ 
  id: 34, 
  img: p34, 
  title: 'اسانس آویشن', 
  description: 'اسانس آویشن با رایحه‌ای تند و طعمی عمیق و گیاهی، مناسب برای استفاده در محصولات غذایی مانند سس‌ها، سوپ‌ها و گوشت‌ها است. همچنین در صنایع دارویی و آرایشی برای خواص ضدباکتریایی و ضدالتهابی آن کاربرد دارد.'
}
,
{ 
  id: 35, 
  img: p35, 
  title: 'اسانس میخک', 
  description: 'اسانس میخک با عطر گرم و طعمی تند و شیرین، مناسب برای استفاده در محصولات غذایی مانند دسرها، نوشیدنی‌ها، و کیک‌ها است. این اسانس همچنین در صنایع آرایشی و بهداشتی برای تولید عطرها و محصولات ضدعفونی‌کننده کاربرد دارد.'
}
,
  {
    id: 36,
    img: p36,
    title: 'اسانس بادام',
    description: 'اسانس بادام با طعمی ملایم و شیرین و رایحه‌ای خوشایند، مناسب برای استفاده در محصولات قنادی مانند کیک‌ها، دسرها، شیرینی‌ها و بستنی‌ها است. همچنین در صنایع آرایشی و بهداشتی برای تولید محصولات مراقبت از پوست و مو کاربرد دارد.'
  }
,
{ 
  id: 37, 
  img: p37, 
  title: 'اسانس توت سفید', 
  description: 'اسانس توت سفید با طعمی لطیف و رایحه‌ای دلپذیر، مناسب برای استفاده در محصولات قنادی مانند کیک‌ها، دسرها، شیرینی‌ها و بستنی‌ها است. همچنین در صنایع آرایشی و بهداشتی برای تولید محصولات مراقبت از پوست و مو کاربرد دارد.' 
}
,
{ 
  id: 38, 
  img: p38, 
  title: 'اسانس زعفران', 
  description: 'اسانس زعفران با رایحه‌ای غنی و طعمی متمایز، مناسب برای ایجاد عطر و طعمی خاص در کیک‌ها، دسرها و نوشیدنی‌ها است. این اسانس با کیفیت بالا همچنین در تولید محصولات لوکس آرایشی و بهداشتی استفاده می‌شود و تجربه‌ای منحصر به فرد ارائه می‌دهد.' 
}
,{ 
  id: 39, 
  img: p39, 
  title: 'اسانس هلو', 
  description: 'اسانس هلو با رایحه‌ای شیرین و طعمی خوشایند، ایده‌آل برای استفاده در کیک‌ها، دسرها، نوشیدنی‌ها و بستنی‌ها است. این اسانس همچنین در تولید محصولات آرایشی و بهداشتی برای ایجاد حس طراوت و شادابی به‌کار می‌رود و تجربه‌ای لذت‌بخش ارائه می‌دهد.' 
}
,
{ 
  id: 40, 
  img: p40, 
  title: 'اسانس سیب سبز', 
  description: 'اسانس سیب سبز با رایحه‌ای شاداب و طعمی ترش و شیرین، مناسب برای استفاده در نوشیدنی‌ها، دسرها، کیک‌ها و آب‌نبات‌ها است. این اسانس همچنین در محصولات آرایشی و بهداشتی به‌منظور ایجاد حس تازگی و طراوت کاربرد دارد.' 
}
,{ 
  id: 41, 
  img: p41, 
  title: 'اسانس گلابی', 
  description: 'اسانس گلابی با طعمی ملایم و رایحه‌ای شیرین، ترکیب بی‌نظیری از لطافت و طراوت را به دسرها، نوشیدنی‌ها و کیک‌ها می‌بخشد. این اسانس حس تازگی و طبیعی را در هر محصولی که به آن افزوده می‌شود، ایجاد می‌کند و طعمی متمایز و خوشایند را برای شما به ارمغان می‌آورد.' 
}
,
{ 
  id: 42, 
  img: p42, 
  title: 'اسانس دارچین', 
  description: 'اسانس دارچین با طعمی تند و عطر گرم و دلپذیر، مناسب برای استفاده در انواع شیرینی‌ها، دسرها و نوشیدنی‌های گرم است. این اسانس حس آرامش‌بخش و دلگرم‌کننده‌ای دارد که می‌تواند هر محصولی را با رایحه‌ای بی‌نظیر و خاص غنی کند.' 
}
,{ 
  id: 43, 
  img: p43, 
  title: 'اسانس گیلاس', 
  description: 'اسانس گیلاس با طعمی ترش و شیرین، رایحه‌ای فرح‌بخش و دلنشین دارد که می‌تواند هر محصولی را با طعمی خوشمزه و متفاوت غنی کند. این اسانس با جذابیت خاص خود، به ایجاد تجربه‌ای منحصر به فرد در هر ترکیب غذایی کمک می‌کند.' 
}
,
{ 
  id: 44, 
  img: p44, 
  title:  'اسانس شکلات تلخ',
  description: 'اسانس شکلات با طعمی غنی و عطر دلپذیر، حس لوکسی و شیرینی طبیعی را به هر ترکیب غذایی اضافه می‌کند. این اسانس با رایحه‌ای دلنشین، به محصولات شما طعمی شکلاتی و پر از لذت می‌بخشد که هر کسی را مجذوب خود می‌کند.' 
}
,
{ 
  id: 45, 
  img: p45, 
  title: 'اسانس سبزیجات', 
  description: 'اسانس سبزیجات با طعمی طبیعی و رایحه‌ای تازه، حس پاکیزگی و طراوت را به هر محصولی اضافه می‌کند. این اسانس با ترکیب عطر گیاهان تازه، انتخابی عالی برای ایجاد طعم‌های منحصر به فرد و سالم است که به خوبی با طعم‌های دیگر ترکیب می‌شود.' 
}
,
{ 
  id: 46, 
  img: p46, 
  title: 'اسانس زرشک', 
  description: 'اسانس زرشک با طعمی ترش و ملس، رایحه‌ای تند و متمایز دارد که به محصولات شما طعمی خاص و جذاب می‌بخشد. این اسانس می‌تواند حس تازگی و طراوت را در هر ترکیب غذایی ایجاد کرده و به تجربه‌ای متفاوت تبدیل شود.' 
}


];



export function Products() {
  const { isDarkMode } = useTheme(); // استفاده از context برای حالت تیره و روشن
  const [searchQuery, setSearchQuery] = useState(''); // حالت برای جستجو

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }, [isDarkMode]);

  // فیلتر کردن محصولات بر اساس جستجو
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="products-container">
      <h2>محصولات ما</h2>
      <input
        type="text"
        placeholder="جستجوی محصول..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />

      <div className="products-grid">
        {filteredProducts.map((product) => (
          <div className="product-card" key={product.title}>
            <img src={product.img} alt={product.title} />
            <h3>{product.title}</h3>
            <p>{product.description}</p>
            <Link to={`/mahsulat/${product.title.toLowerCase()}`}>
              <button
                className="view-all-button"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                جزئیات محصول
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;

